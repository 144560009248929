import { useContext } from 'react';
import { FiltersContext } from '../../context/FiltersContext';

export function useFilter() {
    const filtersContext = useContext(FiltersContext);
    return {
        phraseList: filtersContext.filters?.phraseLists?.length > 0
            ? filtersContext.filters?.phraseLists
            : null,
        mediaList: filtersContext.filters?.mediaLists?.length > 0
            ? filtersContext.filters?.mediaLists
            : null,
        articles: filtersContext.filters?.articles?.length > 0
            ? filtersContext.filters?.articles
            : null,
    };
}