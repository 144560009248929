import React from 'react';
import * as api from '../api';

export interface IScreenshotProps {
    phrase: string;
    date: string;
}

export function Screenshot (props: IScreenshotProps) {
    return (<a
        style={{ color: 'red' }}
        href={`${api.API}phrase/${props.phrase}/scan/${props.date}/img.jpg`}
        target="_top">
            screenshot
            {/*<img*/}
            {/*    height={200}*/}
            {/*    alt="logo"*/}
            {/*    src={`${api.API}phrase/${*/}
            {/*        phrase.slug*/}
            {/*    }/scan/${scan.date}/img.jpg`}*/}
            {/*/>*/}
        </a>
    );
}