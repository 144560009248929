import React, { useState } from 'react';

export const DashboardContext = React.createContext({
    showArticles: false,
    setShowArticles: (value: boolean) => {

    }
});

export const DashboardContextProvider = (props: any) => {
    const [showArticles, setShowArticles] = useState(false);

    return <DashboardContext.Provider
        value={{
            showArticles,
            setShowArticles
        }}
    >
        {props.children}
    </DashboardContext.Provider>;
};