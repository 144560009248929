import { Credentials, login } from '../api';
import { IncorrectCredentials, SessionRepositoryInterface } from './index';

export default class SessionRepository implements SessionRepositoryInterface {
    async login(credentials: Credentials) {
        let response;
        try {
            response = await login(credentials);
        } catch (e) {
            console.log(e);
            throw e;
        }

        if (response) {
            return response;
        } else {
            throw new IncorrectCredentials(
                'username and password do not match',
            );
        }
    }
}
