import { Table } from 'antd';
import React from 'react';

export const TopDomainsTable = (props: { topLinks: any[]; limit: number; }) => {
    return <Table
        dataSource={props.topLinks.slice(0, props.limit)}
        columns={[{
            title: 'Domain',
            dataIndex: 'domain',
        }, {
            title: 'Publikacje',
            dataIndex: 'links',
            align: 'right',
        }, {
            title: 'Ruch',
            dataIndex: 'clicks',
            align: 'right',
        }]}
        pagination={false}
    />;
};