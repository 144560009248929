import { Select, Spin, Table, Tag } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CompanySlugProp, SessionProp } from '../api';
import { last, reverse, sortBy, uniq } from 'lodash';
import { ColumnProps } from 'antd/es/table';
import { PropagateUpdate } from '../helpers/PropagateUpdate';
import { getColumnSearchProps } from '../textFilterInTable';
import { EditableLink } from './EditableLink';
import {
    Link,
    LinkStatus,
    useAssignArticleToLinkMutation,
    useGetArticlesQuery,
    useGetSuggesterArticlesLazyQuery,
} from '../generated/graphql.d';
import { gql, useMutation, useQuery } from '@apollo/client';
import { LinkDetails } from './LinkDetails';
import { Key, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import { LinkStatusIcon } from './LinkStatusIcon';
import { dateToLocal } from '../helpers/time/dateToLocal';
import { CompanyContext } from '../context/CompanyContext';
import { ColumnFilterItem } from 'antd/es/table/interface';

export const GET_THESES = gql`
    query GetThesis($companySlug: String!) {
        Company(slug: $companySlug) {
            theses {
                text
                slug
                companySlug
            }
        }
    }
`;

const COLORS = [
    'magenta',
    'blue',
    'lime',
    'purple',
    'red',
    'volcano',
    'gold',
    'orange',
    'green',
    'cyan',
    'geekblue',
];

export const MUTATE_LINK = gql`
    mutation UpdatePhrase(
        $immReference: String!
        $params: LinkUpdateParams!
    ) {
        Link(immReference: $immReference) {
            update(params: $params) {
                success
            }
        }
    }
`;

export interface ISEOTags {
    meta: {
        robots?: string;
    },
    canonical?: string;
    title?: string;
}

export interface UrlInfo {
    url: string;
    finalUrl: string;
    seo?: ISEOTags;
    requests: any[];
}

export interface LinksTableProps<RecordType> {
    onChange?: (pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<RecordType> | SorterResult<RecordType>[], extra: TableCurrentDataSource<RecordType>) => void;
}

export function SelectRelatedArticle(props: { link: Link }) {
    const [opt, setOptions] = useState<any[]>(props.link.article ? [props.link.article] : []);
    const companyContext = useContext(CompanyContext);

    const [getSuggestedArticles, { data, loading }] = useGetSuggesterArticlesLazyQuery({
        variables: {
            linkId: props.link.id,
            slug: companyContext.companySlug,
        },
    });

    const { data: articlesList } = useGetArticlesQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });
    const [assignArticle] = useAssignArticleToLinkMutation();

    // useEffect(() => {
    //     setOptions(data?.Company?.suggestedArticles.map(item => ({ id: item.id, title: item.title })) || []);
    // }, [data?.Company?.suggestedArticles]);

    useEffect(() => {
        setOptions(articlesList?.Company?.articles.map(item => ({ id: item.id, title: item.title })) || []);
    }, [articlesList?.Company?.articles]);

    const handleSearchArticle = (value: string) => {
        if (value) {
            setOptions(articlesList?.Company?.articles.filter(item => {
                return item.title.toLowerCase().includes(value.toLowerCase());
            }).map(item => ({ id: item.id, title: item.title })) || []);
        } else {
            // setOptions(data?.Company?.suggestedArticles.map(item => ({ id: item.id, title: item.title })) || []);
            setOptions(articlesList?.Company?.articles.map(item => ({ id: item.id, title: item.title })) || []);
        }
    };

    const handleChangeArticle = async (articleId: string) => {
        assignArticle({
            variables: {
                link: props.link.immReference,
                linkId: props.link.id,
                article: articleId || null,
            },
        });
    };

    const options = opt.map(option => {
        return <Select.Option value={option.id} key={option.id}>{option.title}</Select.Option>;
    });

    return <Select placeholder='Wybierz artykuł'
                   style={{ width: 150 }}
                   value={props.link.article ? props.link.article.id : undefined}
                   onSearch={handleSearchArticle}
                   onChange={handleChangeArticle}
        // onDropdownVisibleChange={() => getSuggestedArticles()}
                   showSearch
                   loading={loading}
                   filterOption={false}
                   allowClear={true}
    >
        {options}
    </Select>;
}

export default function LinksTable(
    props: { links: Link[] } & PropagateUpdate &
        SessionProp & CompanySlugProp & LinksTableProps<Link>,
) {
    const linkSearchProps = useState();
    const [syncDetails, setSyncDetails] = useState<{ [key: string]: any }>({});
    const { loading, error, data } = useQuery(GET_THESES, {
        variables: {
            companySlug: props.companySlug,
        },
    });
    const [updateLink] = useMutation(MUTATE_LINK);
    const ssd = (info: UrlInfo) => {
        setSyncDetails({
            ...syncDetails,
            [info.url]: info,
        });
    };

    const { data: articlesList } = useGetArticlesQuery({
        variables: {
            slug: props.companySlug,
        },
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

    const filtersByArticle = useMemo(() => {
        const filtersByArticle: ColumnFilterItem[] = [{
            text: 'Artykuł nie przypisany',
            value: false,
        }];
        articlesList?.Company?.articles.forEach(item => filtersByArticle.push({
            text: item.title,
            value: item.id,
        }));

        return filtersByArticle;
    }, [articlesList?.Company?.articles]);

    if (error) return <div>error :. (</div>;

    const thesesSum: any = {};
    const sources: any = {};

    const links = props.links.map(link => {
        sortBy(uniq(link.sources)).forEach((source: string) => {
            if (source in sources) {
                sources[source]++;
            } else {
                sources[source] = 1;
            }
        });

        link.theses.forEach(thesis => {
            if (thesis in thesesSum) {
                thesesSum[thesis]++;
            } else {
                thesesSum[thesis] = 1;
            }
        });

        const theses = sortBy(uniq(link.theses));

        return {
            ...link,
            theses,
            details: syncDetails.hasOwnProperty(link.immReference) ? syncDetails[link.immReference] : undefined,
        };
    });

    const sortedSources = sortBy(Object.keys(sources));

    const sourceColors: any = {};
    sortedSources.forEach(
        (s, c) => (sourceColors[s] = COLORS[c % COLORS.length]),
    );

    const extractReachValue = ({ reach }: Link): number => {
        if (reach && reach.length > 0) {
            return last(reach)?.value || 0;
        }
        return 0;
    };

    const extractAveValue = ({ ave }: Link): number => {
        if (ave && ave.length > 0) {
            return last(ave)?.value || 0;
        }
        return 0;
    };

    const columns: ColumnProps<Link & { details: UrlInfo }>[] = [
        {
            title: 'Dodane',
            dataIndex: 'date',
            key: 'date',
            render: (date: string) => (
                <div style={{ minWidth: '75px' }}>
                    {dateToLocal(date, 'yyyy-MM-dd')}
                </div>
            ),
            defaultSortOrder: 'descend',
            // @ts-ignore
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        },
        {
            title: 'Źródło',
            dataIndex: 'sources',
            key: 'sources',
            render: sources => (
                <div>
                    {sources.map((source: string) => (
                        <Tag key={source} color={sourceColors[source]}>
                            {source}
                        </Tag>
                    ))}
                </div>
            ),
            filters: Object.entries(sources).map(([k, v]) => ({
                text: `${k} (${v})`,
                value: k,
            })),
            onFilter: (value, link) =>
                link.sources.indexOf(value.toString()) > -1,
        },
        {
            title: 'Artykuł źródłowy',
            dataIndex: 'article',
            key: 'article',
            render: (article: string, link: Link) => (
                <SelectRelatedArticle link={link} />
            ),
            filters: filtersByArticle,
            onFilter: (value, link) => {
                if (value === false) {
                    return link.article === null;
                }
                return link.article?.id === value;
            },
        },
        {
            title: 'AVE',
            dataIndex: 'ave',
            key: 'ave',
            render: (_, link) => (
                <span>{link.ave?.length && link.ave.length > 0 ? `${link.ave[link.ave.length - 1]?.value}` : ''}</span>),
            sorter: (a, b) => (extractAveValue(a) - extractAveValue(b)),
        },
        {
            title: 'Dotarcie',
            dataIndex: 'reach',
            key: 'reach',
            render: (_, link) => (
                <span>{link.reach?.length && link.reach.length > 0 ? `${link.reach[link.reach.length - 1]?.value}` : ''}</span>),
            sorter: (a, b) => (extractReachValue(a) - extractReachValue(b)),
        },
        {
            title: 'Stan',
            key: 'status',
            dataIndex: 'status',
            render: (_, link) => (
                <LinkStatusIcon status={link.status || null} />
            ),
            filters: [
                {
                    text: 'Poprawny',
                    value: LinkStatus.Valid,
                },
                {
                    text: 'Link wymaga uwagi',
                    value: LinkStatus.Invalid,
                },
                {
                    text: 'Oczekuje na sprawdzenie',
                    value: LinkStatus.Pending,
                },
            ],
            onFilter: (value: string | number | boolean, link: Link & { details: UrlInfo }) => {
                if (value === LinkStatus.Pending) {
                    return link.status === LinkStatus.Pending || link.status === null;
                }
                return link.status === value;
            },
        },
        {
            title: 'URL',
            dataIndex: 'editable',
            key: 'editable',
            render: (ignored, full) => (
                <EditableLink
                    link={full}
                    companySlug={props.companySlug}
                    session={props.session}
                    propagateUpdate={props.propagateUpdate}
                    sync={ssd}
                />
            ),
            sorter: (a, b) => ('' + a.link).localeCompare(b.link),
            ...getColumnSearchProps('link', ...linkSearchProps),
            onFilter: (value, record) => {
                const v = value.toString().toLowerCase();
                return record.link.toLowerCase().indexOf(v) >= 0 || record.editable.toLowerCase().indexOf(v) >= 0;
            },
        },
    ];

    const onChange = (_: any, __: any, ___: any, extra: TableCurrentDataSource<Link>) => {
        props.onChange?.(_, __, ___, extra);
    };

    return (<Spin spinning={loading}>
        <Table
            dataSource={reverse(
                sortBy(
                    links,
                    'date',
                ),
            )}
            columns={columns}
            rowKey='link'
            expandable={{
                expandedRowRender: ({ details, link, editable, status }) => <LinkDetails details={details} url={link}
                                                                                         edit={editable}
                                                                                         propagateUpdate={props.propagateUpdate}
                                                                                         status={status || null}
                                                                                         companySlug={props.companySlug} />,
                rowExpandable: record => record.status === 'valid' || record.status === 'invalid',
                defaultExpandAllRows: false,
            }}
            pagination={{
                // @ts-ignore
                position: 'both',
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '25', '50', '100'],
            }}
            onChange={onChange}
            rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys: (string | number)[], selectedRows) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                },
            }}
        />
    </Spin>);
}
