import {AddLinkModal} from '../../links/AddLink';
import React, { useEffect, useState } from 'react';
import { CompanyProp, Link, SessionProp } from '../../api';
import { PropagateUpdate } from '../../helpers/PropagateUpdate';
import { LinksCSVDownload } from '../../links/LinksCSVDownload';
import LinksTable from '../../links/LinksTable';
import { MaybeDateRangeProp } from '../../DatePanel';
import ContentMass from '../../markers/ContentMass';
import { Space, Spin } from 'antd';
import {TableCurrentDataSource} from "antd/lib/table/interface";
import { Company, LinksDocument, useGetLinksQuery } from '../../generated/graphql.d';

export interface ILinksTabProps extends PropagateUpdate, SessionProp, MaybeDateRangeProp {
    company: Pick<Company, 'name' | 'slug'>;
}

export function LinksTab(props: ILinksTabProps) {
    const {data, loading, error, subscribeToMore} = useGetLinksQuery({
        variables: {
            slug: props.company.slug
        }
    });

    useEffect(() => {
        const unsubscribe = subscribeToMore({
            document: LinksDocument,
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) {
                    return prev
                }
                // @ts-ignore
                const updatedLink = subscriptionData.data.linkUpdated;
                const links = prev.Company?.links ? [...prev.Company.links] : [];
                const index = links.findIndex(item => item.id === updatedLink.id);
                if (index >= 0) {
                    links[index] = {
                        ...links[index],
                        ...updatedLink,
                    };
                }

                return {
                    ...prev,
                    links
                };
                // const newFeedItem = subscriptionData.data.commentAdded;
                // return Object.assign({}, prev, {post: {comments: [newFeedItem, ...prev.post.comments]}});
            }
        });
        return () => unsubscribe();
    });

    const links: any = data?.Company?.links;
    const [filtered, setFiltered] = useState(links);

    const propagateUpdate = () => {
        console.log('propagateUpdate');
    };

    const onChange = (_: any, __: any, ___: any, extra: TableCurrentDataSource<Link>) => {
        setFiltered(extra.currentDataSource);
    };

    return <Spin spinning={loading}>
        <Space direction={"vertical"}>
            <Space>
                <AddLinkModal
                    companySlug={props.company.slug}
                    propagateUpdate={propagateUpdate}
                />

                <LinksCSVDownload
                    links={filtered}
                    companySlug={props.company.slug}
                />
            </Space>

            <LinksTable
                links={links || []}
                companySlug={props.company.slug}
                propagateUpdate={propagateUpdate}
                session={props.session}
                onChange={onChange}
            />

            {/*<ContentMass*/}
            {/*    company={{ ...props.company, links }}*/}
            {/*    dateRange={props.dateRange}*/}
            {/*/>*/}
        </Space>
    </Spin>;
}
