import { ApolloClient, ApolloProvider, InMemoryCache, split, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import React from 'react';
import { loadSession } from './SessionContext';
import { getMainDefinition } from '@apollo/client/utilities';

export const API =
    window.location.port === '3001'
        ? 'http://localhost:3000/'
        : 'https://api.experimental.lightscape.pro/';

const API_WS = window.location.port === '3001'
        ? 'ws://localhost:3000/'
        : 'wss://api.experimental.lightscape.pro/';

const session = loadSession();
const token = session?.token;
const wsLink = new WebSocketLink({
    uri: `${API_WS}subscriptions`,
    options: {
        reconnect: true,
        connectionParams: {      
            authorization: token,    
        },
    }
});

const httpLink = new HttpLink({
    uri: `${API}graphql`
});


const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const session = loadSession();
    // return the headers to the context so httpLink can read them

    const token = session.token;

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

const client = new ApolloClient({
    link: splitLink,
    cache,
});

export function ApolloConfig(props: { children: JSX.Element }) {
    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
