import React, { useContext } from 'react';
import { useGetArticleEstimatedReadsQuery, useGetEstimatedReadsQuery } from '../../generated/graphql.d';
import { DateRangeContext } from '../../context/DateRange';
import { useArticleId } from '../useArticleId';
import { useArticleBaseOptions } from './useArticleBaseOptions';
import { CompareCard } from '../../company/Cards/CompareCard';

export const ArticleTrafficCard = () => {
    const context = useContext(DateRangeContext);
    const articleId = useArticleId();

    const baseOptions = useArticleBaseOptions(articleId);
    const periodOptions = useArticleBaseOptions(articleId, context.dateRange.from, context.dateRange.to);

    const stats = useGetArticleEstimatedReadsQuery(baseOptions);
    const compare = useGetArticleEstimatedReadsQuery(periodOptions);

    return <CompareCard value={stats?.data?.Company?.article.reads || 0}
                        value2={context.compare ? compare?.data?.Company?.article.reads || 0 : null}
                        title={'Łączny uzyskany ruch organiczny'}
                        loading={stats.loading || compare.loading} />;
};
