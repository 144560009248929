import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { useCallback, useContext } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import { useAssignPhraseMutation, useGetCompanyPhraseListsQuery } from '../generated/graphql.d';
import { TabListContext } from '../context/ListContext';

export function AssignPhraseList() {
    const companyContext = useContext(CompanyContext);
    const tab = useContext(TabListContext);

    const { data } = useGetCompanyPhraseListsQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const [assign] = useAssignPhraseMutation();

    const onClick = useCallback(async (payload) => {
        await assign({
            variables: {
                companySlug: companyContext.companySlug,
                listSlug: payload.key,
                phraseSlug: tab.selectedRowKeys,
            },
            refetchQueries: ['GetPhrasesAndThesis'],
        });
        tab.setSelectedRowKeys([]);
    }, [companyContext.companySlug, tab.selectedRowKeys]);

    const menu = <Menu onClick={onClick}>
        {data?.Company?.phraseLists.map(phraseList => <Menu.Item key={phraseList!.slug}>{phraseList!.name}</Menu.Item>)}
    </Menu>;

    return tab.selectedRowKeys.length > 0
        ? <Dropdown overlay={menu}>
            <Button>
                Przypisz frazę do grupy <DownOutlined />
            </Button>
        </Dropdown>
        : null;
}