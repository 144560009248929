import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { reverse, sortBy, take } from 'lodash';
import { MaybeDateRangeProp } from '../DatePanel';
import { Phrase } from './PhrasesTable';
import { CompanySlugProp } from '../api';

export default function PhrasesTableReport({
                                               phrases,
                                               ...props
                                           }: { phrases: Phrase[] } & CompanySlugProp & MaybeDateRangeProp) {
    const columns: ColumnProps<Phrase>[] = [
        {
            title: 'Fraza',
            dataIndex: 'text',
            key: 'text',
            render: (ignore: any, phrase: Phrase) => (
                <Link
                    to={
                        '/company/' +
                        props.companySlug +
                        '/phrase/' +
                        phrase.slug
                    }
                >
                    {phrase.text}
                </Link>
            ),
        },
        {
            title: 'Potencjał frazy',
            dataIndex: 'potential',
            key: 'potential',
        },
        {
            title: 'Uzyskane pozycje',
            dataIndex: 'ranks',
            key: 'ranks',
        },
        {
            title: 'CTR',
            dataIndex: ['stats', 'ctr'],
            key: 'stats.ctr',
            render: (current: number) =>
                current ? `${(current * 100).toFixed(2)}%` : '-',
        },
        {
            title: 'Wypracowany ruch organiczny',
            dataIndex: ['stats', 'estimatedReads'],
            key: 'stats.estimatedReads',
            defaultSortOrder: 'descend',
            render: i => (i ? Math.round(i) : '-'),
        },
    ];

    const a = reverse(
        sortBy(
            phrases.filter(i => i.stats && i.stats.estimatedReads && i.potential),
            'stats.estimatedReads',
        ),
    );

    return (
        <Table
            columns={columns}
            dataSource={take(a, 50)}
            rowKey={'slug'}
            pagination={false}
        />
    );
}
