import { Card, Spin, Table, Typography } from 'antd';
import { TopArticle, useGetLastPublicationsQuery } from '../../generated/graphql.d';
import React, { useContext } from 'react';
import { DateRangeContext } from '../../context/DateRange';
import { CompanyContext } from '../../context/CompanyContext';
import { useFilter } from './useFilter';

const { Link } = Typography;

export const ArticleActivitiesCard = () => {
    const context = useContext(DateRangeContext);
    const companyContext = useContext(CompanyContext);
    const filter = useFilter();

    const { loading, data } = useGetLastPublicationsQuery({
        variables: {
            from: context.dateRange.from,
            slug: companyContext.companySlug,
            to: context.dateRange.to,
            filter,
        }
    });

    const activities = data?.Company?.stats.lastPublications.slice(0, 10) || [];
    activities.sort((a, b) => b!.publicationsNumber - a!.publicationsNumber);

    return <Card title='Ostatnia aktywność w artykułach'>
        <Spin spinning={loading}>
            <Table
                pagination={false}
                // @ts-ignore
                dataSource={activities}
                columns={[{
                    title: 'Tytuł',
                    dataIndex: 'title',
                    render: (title: string, article: TopArticle) => <Link
                        href={`/company/${companyContext.companySlug}/article/${article.articleId}`}>{title}</Link>,
                }, {
                    title: 'Nowe linki',
                    dataIndex: 'publicationsNumber',
                    align: 'right',
                }]} />
        </Spin>
    </Card>;
};