import React from 'react';
import ContentMassPlot from './ContentMassPlot';
import { CompanyProp, SessionProp } from '../api';
import { groupBy, sortBy } from 'lodash';
import { MaybeDateRangeProp } from '../DatePanel';
import { format, startOfMonth, subMonths } from 'date-fns';
import { Serie } from '@nivo/line';
import {
    useGetStatsQuery
} from '../generated/graphql.d';
import { Spin } from 'antd';

export function ActivePublicationsMock(props: CompanyProp & SessionProp) {
    let y = 0;

    function nextPoint() {
        y += Math.round(Math.random() * 45 - 5);
        return y;
    }

    const data = Array(12)
        .fill(1)
        .map((i, c) => ({
            y: nextPoint(),
            x: new Date(`2020-01-${10 + c}T23:00:52.000Z`),
        }));

    return (
        <div className="ActivePublications" style={{ height: '40vh' }}>
            <h1>Aktywne Publikacje</h1>
            <ContentMassPlot series={[{ id: 'publikacje', data }]}/>
        </div>
    );
}

export default function ContentMass(
    props: CompanyProp & MaybeDateRangeProp & { report?: true },
) {
    const baseOptions = {
        variables: {
            slug: props.company.slug,
            // @ts-ignore
            from: props.dateRange[0],
            // @ts-ignore
            to: props.dateRange[1],
        },
    };
    const stats = useGetStatsQuery(baseOptions);
    const details = stats?.data?.Company?.stats.contentMassDetails || [];
    let sum = 0;
    const dayCount = sortBy(details.map(item => {
        return {
            x: new Date(item!.date),
            y: item!.count
        };
    }), 'x').map(item => {
        sum += item.y || 0;
        return {
            x: item.x,
            y: sum
        };
    });

    let data: Serie[] = [];

    if (props.dateRange) {
        const from = props.dateRange[0];
        const to = props.dateRange[1];
        const selectedRange = dayCount.filter(i => i.x <= to && i.x >= from);
        const monthsAgo = subMonths(startOfMonth(to), 12);
        const previously = dayCount.filter(i => i.x <= from && i.x >= monthsAgo);
        if (selectedRange.length) {
            previously.push(selectedRange[0]);
        }
        data = [
            {
                id: 'zakres',
                data: selectedRange,
                color: '#301091',
            },
            {
                id: 'ostatnie 12 miesięcy',
                data: previously,
                color: '#00abe8',
            },
        ];
    } else {
        data = [
            {
                id: 'publikacje',
                data: dayCount,
//                color: 'url(#default)',
                color: '#00abe8',
            },
        ];
    }


    return (
        <Spin spinning={stats.loading}>
            <div className="ActivePublications" style={{ height: '40vh' }}>
                <h2>
                    Content Mass: <b>{stats.data?.Company?.stats.contentMass || 0}</b>
                </h2>
                <div>
                    Wykres monitorowanej masy kontentowej, składającej się z publikacji internetowych
                    zawierających nazwę marki {props.company.name}, nie będących reklamą.
                </div>
                <ContentMassPlot series={data}/>
            </div>
        </Spin>
    );
}
