import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { example_user, MaybeSession } from './api';

// for mocking logged in users
export const testSession: MaybeSession = {
    user: example_user,
    token: 'token',
};

export function loadSession() {
    const session = localStorage.getItem('session');
    if (session) {
        try {
            return JSON.parse(session);
        } catch {
            console.log('could not read session', session);
            return undefined;
        }
    } else {
        return undefined;
    }
}

function saveSession(session: MaybeSession) {
    if (session) {
        localStorage.setItem('session', JSON.stringify(session));
    } else {
        localStorage.removeItem('session');
    }
}

const localStorageSession = loadSession();

export const SessionContext = createContext([undefined as MaybeSession, undefined as any]);

export function SessionProvider({ children, sessionManager }: PropsWithChildren<any>) {
    const [session, setSession] = useState(localStorageSession);
    useEffect(() => {
        // TODO: use this to provide sessionManger

    });

    function updateSession(newSession: MaybeSession) {
        saveSession(newSession);
        setSession(newSession);
    }

    return (
        <SessionContext.Provider value={[session, updateSession]}>
            {children}
        </SessionContext.Provider>
    );
}

