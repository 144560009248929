import { Space } from 'antd';
import React from 'react';
import { ArticlesTable } from '../../article/ArticlesTable';
import { AddArticle } from '../../article/AddArticle';


export function ArticlesTab() {
  return (
      <Space direction={"vertical"}>
          <Space>
              <AddArticle />
          </Space>

          <ArticlesTable />
      </Space>
  );
}