import React from 'react';
import { Thesis } from '../../thesis/Thesis';
import { SessionProp, CompanySlugProp } from '../../api';
import { DateRangeProp } from '../../DatePanel';

export function ThesisTab(props: SessionProp & CompanySlugProp & DateRangeProp) {
    return <div>
        <Thesis {...props} />
    </div>;
}
