import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

export function Doing() {
    return (
        <Spin
            className="jms-icon-doing"
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}
        />
    );
}
