import { Card, Spin, Table, Typography } from 'antd';
import React, { useContext } from 'react';
import { DateRangeContext } from '../../context/DateRange';
import { useGetLinksForArticleQuery, useGetTopLinksQuery } from '../../generated/graphql.d';
import type { DashboardContextualCardProps } from '../../common';
// import { useFilter } from './useFilter';
import { CompanyContext } from '../../context/CompanyContext';
import { useArticleId } from '../useArticleId';

const { Link } = Typography;

export const TopLinksCard = () => {
    const companyContext = useContext(CompanyContext);
    // const context = useContext(DateRangeContext);
    // const filter = useFilter();

    const articleId = useArticleId();

    const { loading, data } = useGetLinksForArticleQuery({
        variables: {
            // from: context.dateRange.from,
            slug: companyContext.companySlug,
            // to: context.dateRange.to,
            articleId: articleId
        },
    });

    // const { loading, data } = { loading: false, data: { Company: { stats: { topLinks: [] } } } };

    // const topLinks: any[] = data?.Company?.article.links.slice(0, 10) || [];
    const topLinks: any[] = data?.Company?.article.links || [];

    return <Card title='Top Linki'>
        <Spin spinning={loading}>
            <Table
                pagination={false}
                dataSource={topLinks}
                columns={[{
                    title: 'Url',
                    dataIndex: 'url',
                    render: (url: string) => <Link href={url}>{url}</Link>,
                }, {
                    title: 'Ruch',
                    dataIndex: 'clicks',
                    align: 'right',
                }, {
                    title: 'Ave',
                    dataIndex: 'ave',
                    align: 'right',
                }]} />
        </Spin>
    </Card>;
};