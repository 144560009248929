import React, { useMemo, useState } from 'react';
import { endOfDay, endOfMonth, startOfMonth, subMonths, subQuarters, subYears } from 'date-fns';

interface IDateRange {
    from: Date;
    to: Date
}

const now = new Date();

export const DateRangeContext = React.createContext({
    dateRange: { from: startOfMonth(now), to: endOfDay(now) },
    previousDateRange: { from: subMonths(startOfMonth(now), 1), to: endOfMonth(subMonths(now, 1)) },
    setDateRange: (dates: IDateRange, type: 'MONTH' | 'QUARTER' | 'YEAR' | IDateRange) => {
    },
    // setPreviousDateRange: (dates: IDateRange) => {},
    compare: false,
    setCompare: (compare: boolean) => {
    },
    articleCompare: true,
    setArticleCompare: (change: boolean) => {
    }
});

export const DateRangeContextProvider = (props: any) => {
    const now = new Date();

    const from = useMemo(() => {
        return props.dateRange ? props.dateRange[0] : startOfMonth(now)
    }, [props.dateRange]);

    const to = useMemo(() => {
        return props.dateRange ? props.dateRange[1] : endOfDay(now);
    }, [props.dateRange]);

    const [dateRange, setDateRange] = useState({ from, to });

    const [previousDateRange, setPreviousDateRange] = useState({
        from: subMonths(from, 1),
        to: endOfMonth(subMonths(to, 1)),
    });

    const [showCompare, setShowCompare] = useState(false);
    const [showChange, setShowChange] = useState(true);

    const set = (params: IDateRange, type: 'MONTH' | 'QUARTER' | 'YEAR' | IDateRange) => {
        if (type === 'MONTH') {
            setPreviousDateRange({
                from: subMonths(params.from, 1),
                to: endOfMonth(subMonths(params.to, 1)),
            });
        } else if (type === 'QUARTER') {
            setPreviousDateRange({
                from: subQuarters(params.from, 1),
                to: endOfMonth(subQuarters(params.to, 1)),
            });
        } else if (type === 'YEAR') {
            setPreviousDateRange({
                from: subYears(params.from, 1),
                to: endOfMonth(subYears(params.to, 1)),
            });
        } else {
            if (type.from && type.to) {
                setPreviousDateRange({ from: type.from, to: type.to });
            }
        }
        setDateRange({ from: params.from, to: params.to });
    }

    return <DateRangeContext.Provider
        value={{
            dateRange,
            previousDateRange,
            setDateRange: set,
            compare: showCompare,
            setCompare: setShowCompare,
            articleCompare: showChange,
            setArticleCompare: setShowChange
        }}>
        {props.children}
    </DateRangeContext.Provider>;
}
