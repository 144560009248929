import React, {useState} from "react";
import {Button, Space, Steps} from "antd";
import {
    LinkDetails,
    useUpdateLinkMutation,
    LinkStatus,
} from "../generated/graphql.d";
import { InteractiveLink } from "./LinkDetails";
import {PropagateUpdate} from "../helpers/PropagateUpdate";
import { CompanySlugProp } from "../api";

const Step = Steps.Step;

export interface LinkDetailsWizardProps extends PropagateUpdate, CompanySlugProp {
    details: LinkDetails;
    url: string;
}

export function LinkDetailsWizard({details, propagateUpdate, url, companySlug}: LinkDetailsWizardProps) {
    const document = details?.document;
    return <Space direction={'vertical'} size={'middle'} style={{width: "100%"}}>
        <Space direction={'vertical'} size={'small'}>
            <h3>Sprawdź dostępność strony</h3>
            {document?.title && <span>Tytuł: {document.title}<br/></span>}
            {details?.finalStatus && <span>Status: {details.finalStatus}<br/></span>}
        </Space>

        <Space direction={'vertical'} size={'small'}>
            <h3>Sprawdź poprawność adresu</h3>
            <Space direction={'horizontal'}>
                <span>Adres docelowy:</span>
                <InteractiveLink url={details?.finalUrl || ""} id={url} propagateUpdate={propagateUpdate}/>
            </Space>

            {document?.canonical && <Space direction={'horizontal'} size={'small'}>
                <span>Canonical:</span>
                <InteractiveLink url={document.canonical} id={url} propagateUpdate={propagateUpdate} companySlug={companySlug}/>
            </Space>}
        </Space>

        <Space direction={'vertical'}>
            <h3>Sprawdź poprawność metatagów</h3>
            {document?.meta?.robots && <span>Tag "robots": {document.meta.robots}<br/></span>}
        </Space>
    </Space>

}
