import { Link, linkUpdate, SessionProp, CompanySlugProp } from '../api';
import { PropagateUpdate } from '../helpers/PropagateUpdate';
import React, { useState } from 'react';
import { Button, message, Space } from 'antd';
import { EditLink } from './EditLink';
import { useCheckLinkMutation } from '../generated/graphql.d';
import { LinkStatusIcon } from "./LinkStatusIcon";

function Original({ link }: { link: Link }) {
    if (link.editable !== link.link) {
        return (
            <div style={{ color: 'grey', fontSize: '10px' }}>
                Orginał: <a href={link.link}>{link.link}</a>
            </div>
        );
    } else {
        return <></>;
    }
}

export function EditableLink({
                                 link,
                                 sync,
                                 ...props
                             }: { link: Link } & { sync: (res: any) => any } & SessionProp & CompanySlugProp & PropagateUpdate) {
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkLink] = useCheckLinkMutation();

    const done = () => setEdit(false);

    async function save(companySlug: string, immReference: string, editable: string) {
        try {
            await linkUpdate(props.session, companySlug, {
                link: immReference,
                editable,
            });
            done();
        } catch (e) {
            console.error(e);
            message.error('błąd podczas aktualizacji linka');
        } finally {
            props.propagateUpdate(Date.now());
        }
    }

    const syncFn = async () => {
        try {
            setLoading(true);
            const response = await checkLink({variables: {url: link.immReference}});
            sync(response);
        } catch (e) {
            console.error(e);
        } finally {
            await props.propagateUpdate(Date.now());
            setLoading(false);
        }
    }

    return (
        <>
            {edit ? (
                <>
                    <Button onClick={done}>Anuluj</Button>{' '}
                    <EditLink
                        link={link}
                        session={props.session}
                        companySlug={props.companySlug}
                        save={save}
                        propagateUpdate={props.propagateUpdate}
                    />
                </>
            ) : (
                <Space>
                    {link.status === null && <Button onClick={syncFn} loading={loading}>Sprawdź link</Button>}
                    <Button onClick={() => setEdit(true)}>Edytuj</Button>
                    <a
                        style={{ overflowWrap: 'anywhere' }}
                        href={link.editable}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {link.editable}
                    </a>
                </Space>
            )}
            <Original link={link}/>
        </>
    );
}
