import React, { useState } from 'react';

export const TabListContext = React.createContext({
    activeList: '',
    setActiveList: (listName: string) => {
    },
    selectedRowKeys: [] as string[],
    setSelectedRowKeys: (keys: string[]) => {
    },
});

export const TabListProvider: React.FC = ({ children }) => {
    const [activeList, setActiveList] = useState('all');
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);


    return (
        <TabListContext.Provider value={{ activeList, setActiveList, selectedRowKeys, setSelectedRowKeys }}>
            {children}
        </TabListContext.Provider>
    );
};