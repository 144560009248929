import React, { useCallback, useContext } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { CheckOutlined, EllipsisOutlined } from '@ant-design/icons';
import { DateRangeContext } from '../context/DateRange';
import {
    endOfMonth,
    endOfQuarter,
    endOfYear,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    subMonths,
    subQuarters,
    subYears,
} from 'date-fns';
import { FiltersContext } from '../context/FiltersContext';
import {
    useActivateCompanyMutation,
    useDeactivateCompanyMutation,
    useGetCompanyDetailsQuery,
    useGetCompanyPhraseListsQuery,
} from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';
import { DashboardContext } from '../context/DashboardContext';
import { SessionContext } from '../context/SessionContext';

export const DropdownMenu = () => {
    const dateContext = useContext(DateRangeContext);
    const filterContext = useContext(FiltersContext);
    const companyContext = useContext(CompanyContext);
    const dashboardContext = useContext(DashboardContext);
    const sessionContext = useContext(SessionContext);
    const now = new Date();

    const { data } = useGetCompanyPhraseListsQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const { data: company } = useGetCompanyDetailsQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const [deactivate] = useDeactivateCompanyMutation({
        variables: {
            slug: companyContext.companySlug,
        },
        refetchQueries: ['GetCompanyDetails'],
    });

    const [activate] = useActivateCompanyMutation({
        variables: {
            slug: companyContext.companySlug,
        },
        refetchQueries: ['GetCompanyDetails'],
    });

    const activityToggle = useCallback(async () => {
        if (company?.Company?.status === 'INACTIVE') {
            await activate();
        } else {
            await deactivate();
        }
    }, [company?.Company?.status]);

    const previousMonth = () => {
        const date = subMonths(now, 1);
        dateContext.setDateRange({
            from: startOfMonth(date),
            to: endOfMonth(date),
        }, 'MONTH');
    };

    const currentMonth = () => {
        dateContext.setDateRange({
            from: startOfMonth(now),
            to: endOfMonth(now),
        }, 'MONTH');
    };

    const currentQuarter = () => {
        dateContext.setDateRange({
            from: startOfQuarter(now),
            to: endOfQuarter(now),
        }, 'QUARTER');
    };

    const previousQuarter = () => {
        const date = subQuarters(now, 1);
        dateContext.setDateRange({
            from: startOfQuarter(date),
            to: endOfQuarter(date),
        }, 'QUARTER');
    };

    const currentYear = () => {
        dateContext.setDateRange({
            from: startOfYear(now),
            to: endOfYear(now),
        }, 'YEAR');
    };

    const previousYear = () => {
        const date = subYears(now, 1);
        dateContext.setDateRange({
            from: startOfYear(date),
            to: endOfYear(date),
        }, 'YEAR');
    };

    const setCompare = () => {
        dateContext.setCompare(!dateContext.compare);
    };

    const setPhraseList = (listSlug: string) => {
        if (filterContext.filters.phraseLists.includes(listSlug)) {
            filterContext.setPhraseLists(
                filterContext.filters.phraseLists.filter(
                    (list) => list !== listSlug,
                ),
            );
        } else {
            filterContext.setPhraseLists([...filterContext.filters.phraseLists, listSlug]);
        }
    };

    const setMediaList = (listSlug: string) => {
        if (filterContext.filters.mediaLists.includes(listSlug)) {
            filterContext.setMediaLists(
                filterContext.filters.mediaLists.filter(
                    (list) => list !== listSlug,
                ),
            );
        } else {
            filterContext.setMediaLists([...filterContext.filters.mediaLists, listSlug]);
        }
    };

    const isActive = () => {
        return company?.Company?.status === 'ACTIVE';
    };

    const menu = (<Menu>
        <Menu.Item onClick={currentMonth}>
            Bieżący miesiąc
        </Menu.Item>
        <Menu.Item onClick={previousMonth}>
            Poprzedni miesiąc
        </Menu.Item>
        <Menu.Item onClick={currentQuarter}>
            Bieżacy kwartał
        </Menu.Item>
        <Menu.Item onClick={previousQuarter}>
            Poprzedni kwartał
        </Menu.Item>
        <Menu.Item onClick={currentYear}>
            Bieżacy rok
        </Menu.Item>
        <Menu.Item onClick={previousYear}>
            Poprzedni rok
        </Menu.Item>
        <Menu.Item icon={dateContext.compare ? <CheckOutlined /> : null} onClick={() => setCompare()}>
            Porównaj okresy
        </Menu.Item>
        <Menu.SubMenu title={'Lista fraz'}>
            {data?.Company?.phraseLists.map((list) => (
                <Menu.Item key={list!.id} onClick={() => setPhraseList(list!.slug)}>
                    {list!.name}
                </Menu.Item>
            ))}
        </Menu.SubMenu>
        <Menu.SubMenu title={'Lista mediów'}>
            {data?.Company?.mediaLists.map((list) => (
                <Menu.Item key={list!.id} onClick={() => setMediaList(list!.slug)}>
                    {list!.name}
                </Menu.Item>
            ))}
        </Menu.SubMenu>
        <Menu.SubMenu title={'Widok'}>
            <Menu.Item icon={dashboardContext.showArticles ? <CheckOutlined /> : null}
                       onClick={() => dashboardContext.setShowArticles(!dashboardContext.showArticles)}>
                Artykuły
            </Menu.Item>
        </Menu.SubMenu>
        {sessionContext.isAdmin() && <Menu.Divider />}
        {sessionContext.isAdmin() && <Menu.Item onClick={activityToggle} icon={isActive() ? <CheckOutlined /> : null}>
            {isActive() ? 'Monitoring aktywny' : 'Monitoring nieaktywny'}
        </Menu.Item>}
    </Menu>);

    return <Dropdown key="more" overlay={menu}>
        <Button
            style={{
                border: 'none',
                padding: 0,
            }}
        >
            <EllipsisOutlined
                style={{
                    fontSize: 20,
                    verticalAlign: 'top',
                }}
            />
        </Button>
    </Dropdown>
};
