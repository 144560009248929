import { CheckOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useContext, useState } from 'react';
import { Phrase } from './PhrasesTable';
import { Doing } from '../StatusIcon/Doing';
import { useUpdatePhrasePotentialMutation } from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';

export interface PhrasePotentialProps {
    phrase: Phrase;
    refresh: Function;
}

export function PhrasePotential(props: PhrasePotentialProps) {
    const company = useContext(CompanyContext);
    const [potential, setPotential] = useState(props.phrase.potential);
    const [updatePotential, { loading }] = useUpdatePhrasePotentialMutation();

    async function save() {
        await updatePotential({
            variables: {
                companySlug: company.companySlug,
                phraseSlug: props.phrase.slug,
                potential,
            },
        });

        props.refresh();
    }

    return (
        <Input.Group compact>
            <Input
                onClick={e => e.stopPropagation()}
                style={{ width: '100px' }}
                value={potential}
                onChange={e =>
                    setPotential(
                        parseInt(
                            e.target.value.replace(/[^\d]+/, '') || '0',
                            10,
                        ),
                    )
                }
                disabled={loading}
            />
            {potential !== props.phrase.potential ? (
                <Button type="primary" onClick={save} disabled={loading}>
                    {loading ? (
                        <Doing/>
                    ) : (
                        <CheckOutlined style={{ color: 'white' }}/>
                    )}
                </Button>
            ) : (
                ''
            )}
        </Input.Group>
    );
}
