import React, { useCallback, useContext } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import { useCreatePhraseListMutation, useGetCompanyPhraseListsQuery } from '../generated/graphql.d';
import { Button, Input, Select } from 'antd';
import { TabListContext } from '../context/ListContext';

export function AddNew() {
    const companyContext = useContext(CompanyContext);
    const [listName, setListName] = React.useState('');

    const [create] = useCreatePhraseListMutation({
        refetchQueries: ['GetCompanyPhraseLists'],
    });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setListName(event.target.value);
    };

    const onAdd = async () => {
        await create({
            variables: {
                slug: companyContext.companySlug,
                listName,
            },
        });
        setListName('');
    };

    return <div style={{ display: 'flex' }}>
        <Input onKeyDown={(event) => {
            if (event.key === 'Enter') {
                onAdd();
                event.preventDefault();
                event.stopPropagation();
            }
        }}
               placeholder='Nazwa listy'
               onChange={onChange}
               value={listName} />
        <Button type='primary' onClick={onAdd}>Dodaj</Button>
    </div>;
}

export function PhrasesListDropdown() {
    const companyContext = useContext(CompanyContext);
    const tabContext = useContext(TabListContext);

    const { data } = useGetCompanyPhraseListsQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const onSelect = useCallback((_) => {
        tabContext.setActiveList(_);
    }, []);

    return <>
        <Select
            onSelect={onSelect}
            value={tabContext.activeList}
            style={{ width: 200 }}
            dropdownRender={(menu) =>
                <>
                    {menu}
                    <AddNew />
                </>
            }
        >
            <Select.Option key={'all'} value={'all'}>Wszystkie frazy</Select.Option>
            {data?.Company?.phraseLists.map(
                list => <Select.Option key={list!.slug} value={list!.slug}>{list!.name}</Select.Option>)
            }
        </Select>
    </>;
}