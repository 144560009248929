import React, { useContext } from 'react';
import { FiltersContext } from '../context/FiltersContext';
import { Tag } from 'antd';
import { useGetCompanyPhraseListsQuery } from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';

export function DashboardPhraseListFilters() {
    const companyContext = useContext(CompanyContext);
    const filtersContext = useContext(FiltersContext);

    const { data } = useGetCompanyPhraseListsQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const setPhraseList = (listName: string) => {
        filtersContext.setPhraseLists(
            filtersContext.filters.phraseLists.filter(
                (list) => list !== listName,
            ),
        );
    };

    return <>
        {filtersContext.filters.phraseLists.map(phraseList => {
            const phrase = data?.Company?.phraseLists.find(
                (list) => list!.slug === phraseList,
            );
            return (
                phrase ? <Tag onClose={() => setPhraseList(phraseList)} closable>{phrase!.name}</Tag> : null
            );
        })}
    </>;
}