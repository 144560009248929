import { PhrasesCSVDownload } from '../CompanyPhrases';
import React, { useContext } from 'react';
import PhrasesTable, { Phrase } from '../../phrase/PhrasesTable';
import { SessionProp } from '../../api';
import { RefreshProp } from '../../RefreshProp';
import { MaybeDateRangeProp } from '../../DatePanel';
import { IThesisTableProps } from '../../thesis/ThesisTable';
import { Space, Spin } from 'antd';
import { AddPhraseModal } from '../../phrase/AddPhrase';
import { CompanyContext } from '../../context/CompanyContext';
import { PhrasesListDropdown } from '../../phrase/PhrasesListDropdown';
import { RemovePhraseList } from '../../phrase/RemovePhraseList';
import { AssignPhraseList } from '../../phrase/AssignPhraseList';

export interface IPhrasesTabProps extends IThesisTableProps, MaybeDateRangeProp, SessionProp, RefreshProp {
    // company: Pick<Company, 'name' | 'slug'>;
    data: Phrase[];
}

export function PhrasesTab(props: IPhrasesTabProps & { loading: boolean }) {
    const company = useContext(CompanyContext);
    return <Spin spinning={props.loading}>
        <Space direction={'vertical'} style={{ width: '100%' }}>
            <Space>
                <PhrasesListDropdown />
                <AddPhraseModal />
                <PhrasesCSVDownload
                    companySlug={company.companySlug}
                    phrases={props.data}
                />
                <RemovePhraseList />
                <AssignPhraseList />
            </Space>

            <PhrasesTable
                theses={props.theses}
                dateRange={props.dateRange}
                phrases={props.data}
                refresh={props.refresh}
            />

            {/*<TOP10PositionsElement*/}
            {/*    dateRange={props.dateRange}*/}
            {/*/>*/}
        </Space>
    </Spin>;
}
