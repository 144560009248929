import { Card, Statistic } from 'antd';
import React from 'react';
import { green, red } from '@ant-design/colors';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export interface ICompareCardProps {
    value: number;
    value2?: number | null;
    title: string;
    loading: boolean;
}

export const CompareCard = (props: ICompareCardProps) => {
    const diff = props.value - (props.value2 || 0);

    return (<Card loading={props.loading}>
        {typeof props.value2 === 'undefined' || props.value2 === null
            ? <Statistic title={props.title}
                         value={props.value} />
            : <Statistic title={props.title}
                         value={props.value}
                         valueStyle={{ color: diff > 0 ? green.primary : red.primary }}
                         prefix={diff > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                         suffix={diff > 0 ? `+${diff}` : `${diff}`} />}
    </Card>);
};
