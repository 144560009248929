import { TThesis } from './ThesisTable';
import React from 'react';
import { ResponsivePie } from '@nivo/pie';


export function ThesisMetricChart({ thesis }: { thesis: TThesis }) {
    const data = [
        {
            id: 'bezpośrednie',
            label: 'bezpośrednie',
            value: thesis.direct,
            color: '#e7a91d',
            color2: '#301091',
        },
        {
            id: 'kontekstowe',
            label: 'kontekstowe',
            value: thesis.indirect,
            color: '#301091',
            color2: '#e7a91d',
        },
    ];

    return (
        <div
            style={{ height: '300px', width: '400px', display: 'inline-block' }}
        >
            <ResponsivePie
                data={data}
                margin={{ top: 40, right: 110, bottom: 80, left: 110 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                // @ts-ignore
                colors={i => i.color}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                enableRadialLabels={false}
                // radialLabelsSkipAngle={10}
                // radialLabelsTextXOffset={6}
                // radialLabelsTextColor="#333333"
                // radialLabelsLinkOffset={0}
                // radialLabelsLinkDiagonalLength={16}
                // radialLabelsLinkHorizontalLength={24}
                // radialLabelsLinkStrokeWidth={1}
                // radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor={{ from: 'color2' }}
                slicesLabelsText
                // colors={}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        translateY: 56,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#000',
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000',
                                },
                            },
                        ],
                    },
                ]}
            />
            <h3 style={{ textAlign: 'center', marginTop: '-10px' }}>
                {thesis.text}<br/>
                <small>Całkowity ruch organiczny:</small> <b>{Math.round(data.reduce((acc, i) => i.value + acc, 0))}</b>
            </h3>
        </div>
    );
}

// <ResponsivePie
//     data={data}
//     margin={{ top: 40, right: 110, bottom: 80, left: 110 }}
//     innerRadius={0.5}
//     padAngle={0.7}
//     cornerRadius={3}
//     // @ts-ignore
//     colors={i => i.color}
//     borderWidth={1}
//     borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
//     enableRadialLabels={false}
//     // radialLabelsSkipAngle={10}
//     // radialLabelsTextXOffset={6}
//     // radialLabelsTextColor="#333333"
//     // radialLabelsLinkOffset={0}
//     // radialLabelsLinkDiagonalLength={16}
//     // radialLabelsLinkHorizontalLength={24}
//     // radialLabelsLinkStrokeWidth={1}
//     // radialLabelsLinkColor={{ from: 'color' }}
//     slicesLabelsSkipAngle={10}
//     slicesLabelsTextColor={{ from: 'color2' }}
//     slicesLabelsText
//     // colors={}
//     animate={true}
//     motionStiffness={90}
//     motionDamping={15}
//     legends={[
//         {
//             anchor: 'bottom',
//             direction: 'row',
//             translateY: 56,
//             itemWidth: 100,
//             itemHeight: 18,
//             itemTextColor: '#000',
//             symbolSize: 18,
//             symbolShape: 'circle',
//             effects: [
//                 {
//                     on: 'hover',
//                     style: {
//                         itemTextColor: '#000',
//                     },
//                 },
//             ],
//         },
//     ]}
// />
