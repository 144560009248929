import { company, Session } from '../api';
import { message } from 'antd';
import { timeout } from '../helpers/time/timeout';
import { useEffect, useState } from 'react';
import { Company } from '../generated/graphql.d';

export async function details(session: Session, slug: string, callback: Function) {

    while (true) {
        try {
            const maybeCompany = await company(session, slug);
            if (maybeCompany) {
                callback(maybeCompany);
                return;
            } else {
                throw new Error('empty response');
            }
        } catch (e) {
            console.log(e);
            message.warn('can\'t get company information');
        }
        await timeout(5000);
    }
}

export function useDetailsEffect(session: Session, refresh: number, slug: string, callback: Function) {
    useEffect(
        () => {
            details(session, slug, callback);
        },
        [slug, refresh, callback, session],
    );
}

export function useDetails(session: Session, refresh: number, slug: string) {

    const [company, setCompany] = useState<Company | undefined>();

    useDetailsEffect(session, refresh, slug, setCompany);

    return company;
}
