import React, { useState } from 'react';

export const MediaTabContext = React.createContext({
    activeList: '',
    setActiveList: (listName: string) => {
    },
});

export const MediaTabProvider: React.FC = ({ children }) => {
    const [activeList, setActiveList] = useState('media-a');

    return (
        <MediaTabContext.Provider value={{ activeList, setActiveList }}>
            {children}
        </MediaTabContext.Provider>
    );
};