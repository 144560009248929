import { Token, User } from '../api';

export interface SessionManager {
    login: (auth: Authentication) => Promise<void>;
    logout: (noSession: NoSession) => Promise<void>;
    registerObserver: (observer: SessionObserver) => UnregisterObserver;
}

export type MaybeSession = Session | NoSession;
export type SessionObserver = (session: MaybeSession) => void
export type UnregisterObserver = () => void;

export interface Authentication {
    user: User
    token: Token
}

export interface Session {
    user: User;
    token: Token;
}

export class NoSession {
    constructor(readonly reason: string) {
    }
}

export interface SessionStorage {
    save: (session: MaybeSession) => Promise<void>;
    load: () => Promise<MaybeSession>;
}
