import { Session } from './session';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

const { SubMenu } = Menu;

export function MyMenu(props: {
    key: string;
    session: Session;
    logOut: () => void;
    companies: any[];
}) {
    return (
        <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            defaultOpenKeys={[
                'user',
                'links',
                'data',
                'partner',
                'company',
                'markers',
            ]}
        >
            <SubMenu key="user" title={'User: ' + props.session.user.login}>
                <Menu.Item key="settings">
                    <Link to={`/user/setting`}>Ustawienia</Link>
                </Menu.Item>
                {props.session.user.type === 'admin' ? (
                    <Menu.Item key="/user">
                        <Link to={`/user`}>Użytkownicy</Link>
                    </Menu.Item>
                ) : (
                    ''
                )}

                <Menu.Item key="logout">
                    <Link
                        className="logout-button"
                        to=""
                        onClick={props.logOut}
                    >
                        Wyloguj
                    </Link>
                </Menu.Item>
            </SubMenu>

            <SubMenu key='company' title='Firmy'>
                {/*{props.session.user.type === 'admin' &&*/}
                {/*    <Menu.Item>*/}
                {/*        Dodaj firmę*/}
                {/*    </Menu.Item>*/}
                {/*}*/}

                {props.companies.map(({ slug, name }) => (
                    <Menu.Item key={'/company/' + slug}>
                        <Link to={`/company/${slug}/dashboard`}>{name}</Link>
                    </Menu.Item>
                ))}
            </SubMenu>

            <SubMenu key="partner" title="Partnerzy">
                <Menu.Item key="imm">
                    <Link to="/partner/imm">Imm</Link>
                </Menu.Item>
            </SubMenu>

            <Menu.Item key="/job">
                <Link to="/job">Zadania</Link>
            </Menu.Item>
        </Menu>
    );
}
