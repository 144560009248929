import React, { useCallback, useContext } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import { useCreateMediaListMutation, useGetCompanyPhraseListsQuery } from '../generated/graphql.d';
import { Button, Input, Select } from 'antd';
import { MediaTabContext } from './context';

export function AddNew() {
    const companyContext = useContext(CompanyContext);
    const [phraseListName, setPhraseListName] = React.useState('');

    const [create] = useCreateMediaListMutation({
        refetchQueries: ['GetCompanyPhraseLists'],
    });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhraseListName(event.target.value);
    };

    const onAdd = async () => {
        await create({
            variables: {
                slug: companyContext.companySlug,
                listName: phraseListName,
            },
        });
        setPhraseListName('');
    };

    return <div style={{ display: 'flex' }}>
        <Input onChange={onChange} value={phraseListName} /> <Button type='primary' onClick={onAdd}>Dodaj</Button>
    </div>;
}

export function MediaListDropdown() {
    const companyContext = useContext(CompanyContext);
    const tabContext = useContext(MediaTabContext);

    const { data } = useGetCompanyPhraseListsQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const onSelect = useCallback((_) => {
        tabContext.setActiveList(_);
    }, []);

    return <>
        <Select
            onSelect={onSelect}
            value={tabContext.activeList}
            style={{ width: 200 }}
            dropdownRender={(menu) =>
                <>
                    {menu}
                    <AddNew />
                </>
            }
        >
            {data?.Company?.mediaLists.map(
                list => <Select.Option key={list!.slug} value={list!.slug}>{list!.name}</Select.Option>)
            }
        </Select>
    </>;
}