import React, { useContext, useMemo } from 'react';
import { useGetKeywordsForArticleQuery } from '../../generated/graphql.d';
import { CompanyContext } from '../../context/CompanyContext';
import { useArticleId } from '../useArticleId';
import { Card, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useArticleBaseOptions } from './useArticleBaseOptions';

export const KeywordsTableCard = () => {
    const companyContext = useContext(CompanyContext);
    const articleId = useArticleId();

    const baseOptions = useArticleBaseOptions(articleId);
    const { data, loading } = useGetKeywordsForArticleQuery(baseOptions);

    const keywords = useMemo(() => {
        const d = data?.Company?.article.keywords.map(item => ({
            slug: item!.slug,
            text: item!.text,
            reads: item!.estimatedReads
        })).sort((a, b) => b.reads - a.reads);

        return d;
    }, [data?.Company?.article.keywords]);

    return <Card
        loading={loading}
        title="Najpopularniejsze słowa kluczowe">
        <Table
            dataSource={keywords}
            columns={[{
                title: 'Fraza',
                dataIndex: 'text',
                key: 'slug',
                render: (ignore: any, phrase) => (
                    <Link
                        to={
                            '/company/' +
                            companyContext.companySlug +
                            '/phrase/' +
                            phrase.slug
                        }
                    >
                        {phrase.text}
                    </Link>
                )
            }, {
                title: 'Reads',
                dataIndex: 'reads'
            }]}
            pagination={false}
        />
    </Card>;
};