import React, { useContext } from 'react';
import { FiltersContext } from '../context/FiltersContext';
import { Tag } from 'antd';
import { useGetCompanyPhraseListsQuery } from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';

export function DashboardMediaListFilters() {
    const companyContext = useContext(CompanyContext);
    const filtersContext = useContext(FiltersContext);

    const { data } = useGetCompanyPhraseListsQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const setMediaList = (listName: string) => {
        filtersContext.setMediaLists(
            filtersContext.filters.mediaLists.filter(
                (list) => list !== listName,
            ),
        );
    };

    return <>
        {filtersContext.filters.mediaLists.map(mediaList => {
            const media = data?.Company?.mediaLists.find(
                (list) => list!.slug === mediaList,
            );
            return (
                <Tag onClose={() => setMediaList(mediaList)} closable>{media!.name}</Tag>
            );
        })}
    </>;
}