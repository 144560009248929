import React from 'react';
import { SessionProp } from '../api';
import { MaybeDateRangeProp } from '../DatePanel';
import { useDetails } from './CompanyRepo';
import { useParams } from 'react-router';
import { CompanyParams } from './CompanyRouter';
import Loading from '../Active/Loading';
import { Report } from './Report';
import { Company } from '../generated/graphql.d';

export function ReportPage(props: SessionProp & MaybeDateRangeProp) {
    const params = useParams() as CompanyParams;
    const details = useDetails(
        props.session,
        1234,
        params.company,
    ) as Company;

    return (
        <Loading monitor={details}>
            <Report company={details} dateRange={props.dateRange} news={[]}/>
        </Loading>
    );
}
