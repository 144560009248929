import React, { useContext } from 'react';
import { Button, Col, Descriptions, PageHeader, Row, Spin } from 'antd';
import { ContentMassCard } from '../company/Cards/ContentMass';
import { TrafficCard } from '../company/Cards/TrafficCard';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../context/CompanyContext';
import { useGetArticleQuery, useGetCompanyDetailsQuery } from '../generated/graphql.d';
import local from '../helpers/time/local';
import { TopLinksCard } from './Cards/LinksCard';
import { KeywordsCard } from './Cards/KeywordsCard';
import { KeywordsTableCard } from './Cards/KeywordsTableCard';
import { useArticleId } from './useArticleId';
import { ArticleAveCard } from './Cards/ArticleAve';
import { ArticleTrafficCard } from './Cards/TrafficCard';
import { ArticleMenu } from './ArticleMenu';

export function CompanyArticleSubpage() {
    // const params = useParams() as CompanyParams;
    const { companySlug } = useContext(CompanyContext);
    const articleId = useArticleId();
    // const companySlug = params.company;
    // const context = useContext(DateRangeContext);
    const companyDetails = useGetCompanyDetailsQuery({
        variables: {
            slug: companySlug
        }
    });

    const { data, loading } = useGetArticleQuery({
        variables: {
            company: companySlug,
            id: articleId
        }
    });


    const article = data?.Company?.article;

    return (
        <Spin spinning={loading || companyDetails.loading}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        title={companyDetails.data?.Company?.name}
                        extra={[
                            <Button><Link to={`/company/${companySlug}/dashboard`}>Dashboard</Link></Button>,
                            <Button><Link to={`/company/${companySlug}/thesis`}>Cele</Link></Button>,
                            <Button><Link to={`/company/${companySlug}/phrases`}>Frazy</Link></Button>,
                            <Button><Link to={`/company/${companySlug}/links`}>Linki</Link></Button>,
                            <Button><Link to={`/company/${companySlug}/media`}>Media</Link></Button>,
                            <Button><Link to={`/company/${companySlug}/news`}>Newsy</Link></Button>,
                            <Button><Link to={`/company/${companySlug}/articles`}>Artykuły</Link></Button>,
                            <Button><Link to={`/company/${companySlug}/event_log`}>Zdarzenia</Link></Button>,
                            <ArticleMenu key='more' />,
                        ]}>
                        <Descriptions size='small' column={1} title={`Artykuł: ${article?.title}`}>
                            <Descriptions.Item label='Autor'>{article?.author}</Descriptions.Item>
                            <Descriptions.Item label='Data utworzenia'>{article?.date ? local(article?.date, 'yyyy-MM-dd') : '-'}{}</Descriptions.Item>
                        </Descriptions>
                    </PageHeader>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <ContentMassCard />
                </Col>

                <Col span={6}>
                    <ArticleTrafficCard />
                </Col>

                <Col span={6}>
                    <ArticleAveCard />
                </Col>

                <Col span={6}>
                    {/*<ReachCard />*/}
                    <KeywordsCard />
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <TopLinksCard />
                </Col>
                <Col span={12}>
                    <KeywordsTableCard />
                </Col>
            </Row>
        </Spin>
    );
}