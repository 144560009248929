import React, { useEffect } from 'react';
import { Button, message, Table, Spin } from 'antd';
import { JobStatusIcon } from './JobStatusIcon';
import { Link } from 'react-router-dom';
import { useCancelJobMutation, useGetJobsQuery, useTriggerJobMutation } from '../generated/graphql.d';
import { reverse, sortBy } from 'lodash';
import { gql } from '@apollo/client';
import { dateToLocal } from "../helpers/time/dateToLocal";

function columns(cancel: any) {
    return [
        {
            title: '',
            dataIndex: 'jobStatus',
            key: 'jobStatus',
            render: (text: any) => <JobStatusIcon status={text}/>,
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (value: string) => <span>{value ? dateToLocal(value) : value}</span>,
            // render: (text: any, record: { created: string }) =>
            //     record.created ? (
            //         <JobCreated time={parseInt(record.created, 10)} />
            //     ) : (
            //         <span>?</span>
            //     ),
        },
        {
            title: 'finished',
            dataIndex: 'finished',
            key: 'finished',
            render: (value: string) => <span>{value ? dateToLocal(value) : value}</span>,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            //render: (text: React.ReactNode) => <a>{text}</a>,
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type',
            //render: (text: React.ReactNode) => <a>{text}</a>,
        },
        {
            title: 'all',
            dataIndex: ['stats', 'all'],
            key: 'stats.all',
        },
        {
            title: 'waiting',
            dataIndex: ['stats', 'waiting'],
            key: 'stats.waiting',
        },
        {
            title: 'doing',
            dataIndex: ['stats', 'doing'],
            key: 'stats.doing',
        },
        {
            title: 'done',
            dataIndex: ['stats', 'done'],
            key: 'stats.done',
        },
        {
            title: '',
            dataIndex: 'state',
            key: 'state',
            render: (state: any, job: any) =>
                state === 'doing' || state === 'pending' ? (
                    <Button type={'primary'} onClick={() => cancel(job)}>
                        Cancel
                    </Button>
                ) : (
                    <div></div>
                ),
        },
        {
            title: 'extra',
            dataIndex: 'extra',
            key: 'extra',
            render: (text: any, record: any) => {
                if (record.jobType === 'scan') {
                    return (
                        <Link
                            to={'/phrase/' + text}
                            style={{
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '200px',
                            }}
                        >
                            {text}
                        </Link>
                    );
                } else {
                    return <div>{text}</div>;
                }
            },
        },
        {
            title: 'out',
            key: 'out',
            dataIndex: 'out',
        },
    ];
}

const COMMENTS_SUBSCRIPTION = gql`
    subscription Jobs {
        jobUpdated {
            id
            type
            state
            stats {
                all
                error
                done
                doing
                waiting
            }
        }
    }
`;

export function Jobs() {
    const { data, loading, error, subscribeToMore } = useGetJobsQuery();

    useEffect(() => {
        const unsubscribe = subscribeToMore({ document: COMMENTS_SUBSCRIPTION });
        return () => unsubscribe();
      });

    const [triggerJob] = useTriggerJobMutation();
    const [cancelJob] = useCancelJobMutation();

    if (error || !data || !data.Jobs) {
        return <div>Error...</div>;
    }

    async function scanPhrase() {
        try {
            const out = await triggerJob({
                variables: {
                    type: 'scan',
                },
            });

            message.success('job triggered ' + out.data?.Job?.trigger.status);
        } catch (e) {
            console.error(e);
            message.error('job trigger error');
        }
    }

    const d = reverse(sortBy(data.Jobs, 'created'));

    return (
        <Spin spinning={loading}>
            <h2>
                Zadania: <Button onClick={scanPhrase}>Skanuj</Button>
            </h2>

            <Table columns={columns((job: any) => cancelJob({
                variables: {
                    id: job.id,
                },
            }))} dataSource={d}/>
        </Spin>
    );
}
