import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { useAddLinkMutation } from '../generated/graphql.d';
import TextArea from "antd/es/input/TextArea";
import { PropagateUpdate } from "../helpers/PropagateUpdate";
import { CompanySlugProp } from '../api';

function splitByNewLine(text: string): string[] {
    return text
        .split(/\n/gm)
        .map(url => url.trim())
        .filter(url => url.length > 0);
}

export function AddLinkModal(props: CompanySlugProp & PropagateUpdate) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addLinks] = useAddLinkMutation();
    const [text, setLinks] = useState('');

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        setIsModalVisible(false);

        const links = splitByNewLine(text);

        await addLinks({
            variables: {
                company: props.companySlug,
                links
            }
        });

        props.propagateUpdate();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setLinks('');
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLinks(event.target.value);
    };

    return <>
        <Button onClick={showModal}>
            Dodaj linki
        </Button>

        <Modal title="Dodaj nowe linki" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <TextArea
                placeholder="https://wiadomosci.onet.pl/tylko-w-onecie/"
                value={text}
                onChange={handleChange}
            />
        </Modal>
    </>;
}
