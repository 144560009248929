import { useContext, useMemo } from 'react';
import { DateRangeContext } from '../../context/DateRange';
import { CompanyContext } from '../../context/CompanyContext';
import { useFilter } from './useFilter';

export function useBaseOptions() {
    const context = useContext(DateRangeContext);
    const company = useContext(CompanyContext);
    const filter = useFilter();

    return useMemo(() => {
        return {
            variables: {
                slug: company.companySlug,
                from: context.dateRange.from,
                to: context.dateRange.to,
                filter,
            },
        };
    }, [company.companySlug, context.dateRange.from, context.dateRange.to, filter]);
}

export function usePreviousBaseOptions() {
    const context = useContext(DateRangeContext);
    const company = useContext(CompanyContext);
    const filter = useFilter();

    return useMemo(() => {
        return {
            variables: {
                slug: company.companySlug,
                from: context.previousDateRange.from,
                to: context.previousDateRange.to,
                filter,
            },
            skip: !context.compare,
        };
    }, [company.companySlug, context.previousDateRange.from, context.previousDateRange.to, context.compare, filter]);
}