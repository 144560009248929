import React from 'react';

export const CompanyContext = React.createContext({
    companySlug: '',
    setCompanySlug: (slug: string) => {
    },
});

export const CompanyContextProvider: React.FC = ({ children }) => {
    const [companySlug, setCompanySlug] = React.useState('');

    const setCompanySlugFn = (slug: string) => {
        setCompanySlug(slug);
    };

    return (
        <CompanyContext.Provider value={{ companySlug, setCompanySlug: setCompanySlugFn }}>
            {children}
        </CompanyContext.Provider>
    );
};