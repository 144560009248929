import React, { useCallback, useContext, useState } from 'react';
import {
    Company,
    GetMediaListDocument,
    useAddToMediaListMutation,
    useGetMediaListQuery,
    useGetMediaSuggestionsQuery,
    useRemoveFromMediaListMutation,
} from './generated/graphql.d';
import { AutoComplete, Button, Modal, Space, Table } from 'antd';
import { MediaListDropdown } from './media/MediaListDropdown';
import { MediaTabContext, MediaTabProvider } from './media/context';

export interface IMediaTabProps {
    company: Pick<Company, 'name' | 'slug'>;
    refetch?: () => void;
}

function refetchGetMediaList(companySlug: string, listSlug: string) {
    return {
        refetchQueries: [
            {
                query: GetMediaListDocument,
                variables: {
                    companySlug: companySlug,
                    listSlug: listSlug,
                },
            },
        ],
    };
}

export function AddMediaModal(props: IMediaTabProps) {
    const { company } = props;

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>('');
    const tab = useContext(MediaTabContext);

    const [addToMediaList] = useAddToMediaListMutation(refetchGetMediaList(company.slug, tab.activeList));

    const suggestions = useGetMediaSuggestionsQuery({
        variables: {
            query: '',
        },
    });

    const onOk = useCallback(async () => {
        try {
            await addToMediaList({
                variables: {
                    slug: company.slug,
                    domain: selected,
                    listSlug: tab.activeList,
                },
            });
            setIsVisible(false);
        } catch (e) {
            console.log(e);
        }
    }, [selected]);

    const onCancel = useCallback(() => {
        setIsVisible(false);
    }, []);

    const showModal = useCallback(() => {
        setSelected('');
        setIsVisible(true);
    }, []);

    const handleSearch = useCallback(async (value: string) => {
        await suggestions.refetch({ query: value });
    }, []);

    const handleChange = useCallback((value: string) => {
        setSelected(value);
    }, []);

    const rawResponse = suggestions.data?.Media?.autocomplete || [];
    const options = rawResponse.map(x => ({
        value: x,
    }));

    return (
        <>
            <Button onClick={showModal}>
                Dodaj domenę
            </Button>

            <Modal visible={isVisible} title='Dodaj domenę' onOk={onOk} onCancel={onCancel}>
                <AutoComplete
                    style={{ width: '100%' }}
                    options={options}
                    value={selected}
                    onSearch={handleSearch}
                    onChange={handleChange} />
            </Modal>
        </>
    );
}

export function MediaTable({ company }: IMediaTabProps) {
    const tab = useContext(MediaTabContext);
    const list = useGetMediaListQuery({
        variables: {
            companySlug: company.slug,
            listSlug: tab.activeList,
        },
    });
    const [remove] = useRemoveFromMediaListMutation(refetchGetMediaList(company.slug, tab.activeList));

    const onRemove = useCallback(async ({ domain }) => {
        try {
            await remove({
                variables: {
                    slug: company.slug,
                    domain,
                    listSlug: tab.activeList,
                },
            });
        } catch (e) {
            console.log(e);
        }
    }, [company.slug, tab.activeList]);

    return (
        <Table
            // @ts-ignore
            dataSource={list.data?.Company?.media?.list || []}
            columns={[
                {
                    title: 'Domena',
                    dataIndex: 'domain',
                    key: 'domain',
                },
                {
                    title: '',
                    dataIndex: '',
                    key: 'action',
                    render: (text, record) => (
                        <Space size='middle'>
                            <Button onClick={() => onRemove(record)}>Usuń</Button>
                        </Space>
                    ),
                    align: 'right',
                },
            ]}
        />
    );
}

export function MediaTab(props: IMediaTabProps) {
    return (
        <MediaTabProvider>
            <Space direction={'vertical'} style={{ width: '100%' }}>
                <Space>
                    <MediaListDropdown />
                    <AddMediaModal company={props.company} />
                </Space>

                <MediaTable company={props.company} />
            </Space>
        </MediaTabProvider>
    );
}