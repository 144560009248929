import React from 'react';
import { SessionProp, CompanySlugProp } from '../../api';
import { DateRangeProp } from '../../DatePanel';
import { useGetNewsQuery } from '../../generated/graphql.d';
import NewsTable from './NewsTable';

export function NewsTab(props: SessionProp & CompanySlugProp & DateRangeProp) {
    return <div>
        <News {...props}></News>
    </div>;
}

export function News(props: SessionProp & CompanySlugProp & DateRangeProp) {
    const { data, loading, error } = useGetNewsQuery({
        variables: {
           slug: props.companySlug,
           from: props.dateRange[0],
           to: props.dateRange[1],
        },
      });
      
      
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    // const theses = {};
    // @ts-ignore
    // data.Company.theses.forEach((i: any) => theses[i.slug] = i);
    // @ts-ignore
    // data.Company.thesesStats.forEach((i: any) => theses[i.slug] = { ...theses[i.slug], ...i });

    return (
        <NewsTable news={data?.Company?.news}></NewsTable>
        // <ThesisElement
        //     onAdd={async text => {
        //         await addThesis({
        //             variables: { companySlug: props.companySlug, text },
        //         });
        //         refetch();
        //     }}
        //     theses={Object.values(theses)}
        // />
    );
}
