import React, { useEffect, useState } from 'react';
import * as api from '../api';
import { Session } from '../api';
import { SessionContext } from '../SessionContext';
import { Button, List } from 'antd';
import { drop, reverse, sortBy, take } from 'lodash';

interface IDir {
    time: string;
    dir: string;
}

function parseImports(dir: string): IDir | undefined {
    const p = /^Lightscape_xml.*_(\d{2})_(\d{2})_(\d{4})__(\d{2})_(\d{2})_(\d{2})$/i;
    const match = dir.match(p);
    if (match) {
        const [, day, month, year, hour, minute, second] = match;
        return {
            time: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
            dir,
        };
    }
}

export function Imm() {
    const [session] = useState(SessionContext);
    const [imports, setImports] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 10;

    useEffect(() => {
        async function fetchData() {
            const newData = await api.immImports(
                (session as unknown) as Session,
            );

            if (newData) {
                setImports(newData);
            }
        }

        fetchData();
    }, [session]);

    function loadLinks(folder: string) {
        api.immImport((session as unknown) as Session, folder);
    }

    const items = reverse(sortBy(imports.map(parseImports).filter(i => i), 'time')) as IDir[];
    const show = take(drop(items, (page - 1) * pageSize), pageSize);


    return (
        <div>
            <h2>Imm</h2>
            <List itemLayout="horizontal"
                  pagination={{ pageSize, total: items.length, defaultPageSize: pageSize, onChange: setPage }}>
                {show.map((dir: IDir) => (
                    <List.Item
                        style={{ alignItems: 'start' }}
                        key={dir.dir}
                        extra={
                            <Button onClick={() => loadLinks(dir.dir)}>
                                Import
                            </Button>
                        }
                    >
                        <List.Item.Meta
                            title={dir.time}
                            description={dir.dir}
                        />
                    </List.Item>
                ))}
            </List>
        </div>
    );
}
