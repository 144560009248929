import {List, Table} from 'antd';
import { Maybe, News, Scan } from '../../generated/graphql';
import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { reverse, sortBy } from 'lodash';
import { Screenshot } from '../../components/screenshot';

function printDate(stamp: string) {
    return new Date(parseInt(stamp, 10))
        .toISOString()
        .replace('T', ' ')
        .substr(0, 19);
}

type IScan = Pick<Scan, 'date' | 'screenShot' | 'urls'>;

interface INews extends Omit<News, 'scans'> {
    scans: Array<Maybe<IScan>>;
}

export function NewsDetails(
    props: {news: INews} & {phrase: string; phraseSlug: string;}
) {
    return (
        <List itemLayout="horizontal">
            {reverse(sortBy(props.news.scans, 'date')).map((scan) => {
                if (scan) {
                    return (
                        <List.Item
                            style={{ alignItems: 'start' }}
                            key={scan.date}
                            extra={
                                <Screenshot phrase={props.phraseSlug} date={scan.date} />
                            }
                        >
                            <List.Item.Meta title={printDate(scan.date)}/>
                            <div>
                                {scan.urls.map(url => <p key={url}>
                                    <a href={url}>{url}</a>
                                </p>)}
                            </div>
                            {/*<List itemLayout="horizontal">*/}
                            {/*    {scan.urls.map((url: string) => <List.Item><span>{url}</span></List.Item>)}*/}
                            {/*</List>*/}
                        </List.Item>
                    );
                }
                return;
            })}
        </List>
    );
}

export default function NewsTable(
    props: { news?: INews[] }
) {
    const columns: ColumnProps<INews>[] = [
        {
            title: 'Fraza',
            key: 'phrase',
            dataIndex: 'phrase'
        }
    ];

    return (
        <Table 
            dataSource={props.news}
            columns={columns}
            expandable={{
                expandedRowRender: (record) => <NewsDetails news={record} phrase={record.phrase} phraseSlug={record.phraseSlug} />,
                defaultExpandAllRows: true,
                // rowExpandable: () => true,
            }}
            rowKey="phrase"
        />
    );
}
