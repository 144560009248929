import React, { useContext } from 'react';
import { useGetContentMassQuery } from '../../generated/graphql.d';
import { CompareCard } from './CompareCard';
import { DateRangeContext } from '../../context/DateRange';
import { useBaseOptions, usePreviousBaseOptions } from './useBaseOptions';

export const ContentMassCard = () => {
    const context = useContext(DateRangeContext);

    const periodOptions = usePreviousBaseOptions();
    const baseOptions = useBaseOptions();

    const stats = useGetContentMassQuery(baseOptions);
    const compare = useGetContentMassQuery(periodOptions);

    return (<CompareCard value={stats?.data?.Company?.stats.contentMass || 0}
                         value2={context.compare ? compare?.data?.Company?.stats.contentMass : null}
                         title={'Content Mass'}
                         loading={stats.loading || compare?.loading === true} />);
};
