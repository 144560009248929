import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';

export const getColumnSearchProps = (
    dataIndex: string | number,
    input: any,
    setInput: any,
) => {
    return {
        filterDropdown: ({
                             setSelectedKeys,
                             selectedKeys,
                             confirm,
                             clearFilters,
                         }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        if (node) setInput(node);
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => confirm()}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => clearFilters()}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value: any, record: any) => {

            if (JSON.stringify(record).toLowerCase().includes('e')) console.log('r', record, 'i', dataIndex, 'di', record[dataIndex].toString());
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                // TODO: stopped working after update
                setTimeout(() => {
                    if (input) {
                        input.select();
                    }
                }, 100);
            }
        },
    };
};
