import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import SimpleSessionManager from './session/SimpleSessionManager';
import LocalSessionStorage from './session/LocalSessionStorage';
import SessionRepository from './repository/SessionRepository';

async function init() {

    const repository = new SessionRepository();

    const sessionStorage = new LocalSessionStorage();

    const sessionManager = new SimpleSessionManager(await sessionStorage.load());
    sessionManager.registerObserver(sessionStorage.save);

    ReactDOM.render(
        <App repository={repository} sessionManager={sessionManager}/>,
        document.getElementById('root'),
    );
}

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
