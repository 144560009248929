import React, { useContext, useState } from 'react';
import { last, sortBy } from 'lodash';
import { CompanyProp } from '../api';
import PhrasesTableReport from '../phrase/PhrasesTableReport';
import { DateRange, MaybeDateRangeProp } from '../DatePanel';
import { Phrase } from '../phrase/PhrasesTable';
import graphql, { GetPhraseQueryVariables, Link, useGetPhraseQuery, useGetStatsQuery } from '../generated/graphql.d';
import { Card, Dropdown, Menu, Spin } from 'antd';
import { DashboardCardProp, DashboardContextualCardProps } from '../common';
import { DateRangeContext } from '../context/DateRange';
import { CheckOutlined, SettingOutlined } from '@ant-design/icons';
import { CompanyContext } from '../context/CompanyContext';
import { useFilter } from './Cards/useFilter';

export interface CompanySlugProp {
    companySlug: string;
}

export function filterLinkByDateRange(links: Link[], dateRange?: DateRange): Link[] {
    if (dateRange) {
        const from = dateRange[0];
        const to = dateRange[1];

        return links.filter(link => link.date >= from && link.date <= to);
    }
    return links;
}

export function getAve(link: Link): number | undefined {
    return last(sortBy(link.ave, 'date'))?.value;
}

export function abc(p: Pick<graphql.Phrase, 'slug' | 'text' | 'potential' | 'theses'>[], s: graphql.PhraseStats[]): Phrase[] {
    const matcher = {};
    s.forEach((i: any) => {
        // @ts-ignore
        matcher[i.slug] = i;
    });

    const phrases: any = p.map((i: any) => {
        // @ts-ignore
        const stats = matcher[i.slug] || {};
        return { ...i, stats, ranks: stats.positions?.join(', ') || '' };
    });

    return phrases;
}

export function CompanyPhraseReportElement(
    props: MaybeDateRangeProp & CompanyProp,
) {
    const baseOptions = {
        variables: {
            slug: props.company.slug,
            // @ts-ignore
            from: props.dateRange[0],
            // @ts-ignore
            to: props.dateRange[1],
        },
    };

    const stats = useGetStatsQuery(baseOptions);

    return (
        <div>
            <h2>VISIBILITY: </h2>
            <div>
                Łączny uzyskany ruch organiczny:{' '}
                <strong style={{ color: 'black', fontWeight: 900 }}>
                    {Math.round(stats?.data?.Company?.stats.reads || 0)}
                </strong>{' '}
                odwiedzin w wybranym okresie
            </div>
            {!Number.isNaN(stats?.data?.Company?.stats.ave) && <div>
                {'Łączne AVE uzyskane z linków zaimportowanych w danym okresie: '}
                <strong style={{color: 'black', fontWeight: 900}}>
                    {stats?.data?.Company?.stats.ave}
                </strong>{' '}
            </div>}
            <br/>
            <div>
                Podsumowanie obecności publikacji pozycjonujących markę{' '}
                {props.company.name} w czołówce wyników wyszukiwania Google
                ustalonych fraz, wypracowanym wysyceniem TOP10 oraz ruchem
                organicznym.
            </div>
        </div>
    );
}

export function PhrasesTableReportElement(
    props: DashboardCardProp & {type?: string},
) {
    const filter = useFilter();
    const companyContext = useContext(CompanyContext);
    // const dateRangeContext = useContext(DateRangeContext);

    const variables: GetPhraseQueryVariables = {
        slug: props.company.slug,
        // @ts-ignore
        from: props.dateRange[0],
        // @ts-ignore
        to: props.dateRange[1],
        // list: props.type === 'FAV' ? 'favourite' : null,
        filter,

    };
    const baseOptions = {
        variables,
    };

    const phraseStats = useGetPhraseQuery(baseOptions);
    const company = phraseStats.data?.Company;
    const phrase = company ? abc(company.phrases, company.phrasesStats) : [];

    return (<Spin spinning={phraseStats.loading}>
        <PhrasesTableReport
            dateRange={props.dateRange}
            phrases={phrase}
            companySlug={props.company.slug}
        />
    </Spin>);
}

export function PhrasesTableCard(props: DashboardContextualCardProps) {
    const [type, setType] = useState('ALL');
    const context = useContext(DateRangeContext);

    const menu = <Menu>
        <Menu.Item icon={type === 'ALL' ? <CheckOutlined /> : null} onClick={() => setType('ALL')}>
            Uwzględnij wszystkie
        </Menu.Item>
        <Menu.Item icon={type === 'FAV' ? <CheckOutlined /> : null} onClick={() => setType('FAV')}>
            Uwzględnij ulubione
        </Menu.Item>
    </Menu>;

    return (<Card
        title={type === 'ALL' ? 'Najlepsze frazy generujące ruch' : 'Ruch generowany przez ulubione frazy'}
        extra={<Dropdown overlay={menu} trigger={['click']}>
            <SettingOutlined />
        </Dropdown>}
        type={'inner'}
    >
        <PhrasesTableReportElement
            company={props.company}
            dateRange={[context.dateRange.from, context.dateRange.to]}
            type={type}
        />
    </Card>)
}

// export function CompanyPhrasesReport(
//     props: SessionProp & CompanySlugProp & MaybeDateRangeProp & CompanyProp,
// ) {
//     const [data] = usePhraseStats(props);
//     return (
//         <CompanyPhraseReportElement {...props} />
//     );
// }
