import React, { useContext, useState } from 'react';
import { Button, Modal } from 'antd';
import { AddPhraseMutationVariables, useAddPhraseMutation } from '../generated/graphql.d';
import TextArea from 'antd/es/input/TextArea';
import { TabListContext } from '../context/ListContext';
import { CompanyContext } from '../context/CompanyContext';

function splitByNewLine(text: string): string[] {
    return text
        .split(/\n/gm)
        .map(url => url.trim())
        .filter(url => url.length > 0);
}

export function AddPhraseModal() {
    const company = useContext(CompanyContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addPhrase] = useAddPhraseMutation({
        refetchQueries: ['GetPhrasesAndThesis'],
    });
    const [text, setText] = useState('');
    const listContext = useContext(TabListContext);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        setIsModalVisible(false);

        const phrases = splitByNewLine(text);

        if (!text) return;

        const variables: AddPhraseMutationVariables = {
            companySlug: company.companySlug,
            phraseText: phrases,
        };

        if (listContext.activeList && listContext.activeList !== 'all') {
            variables.listSlug = listContext.activeList;
        }

        await addPhrase({ variables });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setText('');
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };

    return <>
        <Button onClick={showModal}>
            Dodaj frazy
        </Button>

        <Modal title="Dodaj nowe frazy" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <TextArea
                value={text}
                onChange={handleChange}
            />
        </Modal>
    </>;
}
