import React, { useContext, useState } from 'react';
import { Button, Modal } from 'antd';
import { AddPhraseMutationVariables, useAddPhraseMutation } from '../generated/graphql.d';
import TextArea from 'antd/es/input/TextArea';
import { TabListContext } from '../context/ListContext';
import { CompanyContext } from '../context/CompanyContext';
import { ArticleForm } from './ArticleForm';

export function AddArticle() {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    return <>
        <Button onClick={showModal}>
            Dodaj artykuł
        </Button>

        <ArticleForm visible={isModalVisible} onClose={hideModal} />
    </>;
}
