import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { Credentials } from './api';
import { Authentication } from './session';
import { IncorrectCredentials, SessionRepositoryInterface } from './repository';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const LoginForm = (props: {
    onSubmit: (credentials: Credentials) => Promise<any>;
}) => {
    const onFinish = (values: any) => {
        if (values.login && values.pass) {
            const { login, pass } = values;
            props.onSubmit({ login, pass });
        }
    };

    return (
        <Form
            {...layout}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Form.Item
                label="Username"
                name="login"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Password"
                name="pass"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default function Login(props: {
    onLogIn: (auth: Authentication) => void;
    repository: SessionRepositoryInterface;
}) {

    async function tellMe(credentials: Credentials) {
        try {
            const response = await props.repository.login(credentials);
            props.onLogIn(response);
        } catch (e) {
            if (e instanceof IncorrectCredentials) {
                message.error('Username and Password do not match');
            } else {
                console.error(e);
            }
        }
    }

    return (
        <div className="login-page">
            <div style={{ maxWidth: '300px', margin: 'auto' }}>
                <LoginForm onSubmit={tellMe}/>
            </div>
        </div>
    );
}
