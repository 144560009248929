import React, { useState } from 'react';

export const SessionContext = React.createContext({
    userType: '',
    setUserType: (type: string) => {

    },
    isAdmin: (): boolean => false,
});

export const SessionContextProvider: React.FC = ({ children }) => {
    const [userType, setUserType] = useState('');
    const isAdmin = () => {
        console.log({ userType });
        return userType === 'admin';
    };
    return <SessionContext.Provider value={{ userType, setUserType, isAdmin }}>
        {children}
    </SessionContext.Provider>;
};