import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { SessionProp } from '../api';
import { CompanyPage } from './CompanyPage/CompanyPage';
import { DateRangeProp } from '../DatePanel';
import { CompanyPhraseSubpage } from './CompanyPhraseSubpage';
import { useGetCompanyDetailsQuery } from '../generated/graphql.d';
import { Result, Spin } from 'antd';
import { CompanyContext } from '../context/CompanyContext';
import { CompanyArticleSubpage } from '../article/CompanyArticleSubpage';
import { FiltersContext } from '../context/FiltersContext';

export type CompanyParams = {
    phrase?: string;
    company: string;
    tab?: string;
    articleId?: string;
};

export function CompanyRouter(props: SessionProp & DateRangeProp) {
    const companyContext = useContext(CompanyContext);
    const filtersContext = useContext(FiltersContext);
    const params = useParams() as CompanyParams;

    useEffect(() => {
        companyContext.setCompanySlug(params.company);
    }, [params.company]);

    useEffect(() => {
        const articles = params.articleId ? [params.articleId] : [];
        filtersContext.setArticles(articles);
    }, [params.articleId]);

    const tab = params.tab || 'dashboard';

    const { loading, error, data, refetch } = useGetCompanyDetailsQuery({
        variables: {
            slug: params.company,
        },
    });

    if (error) return <Result
        status="warning"
        title="Wystąpił problem podczas pobierania danych :-("
    />;

    if (params.phrase) {
        return (
            <CompanyPhraseSubpage
                company={data?.Company}
                session={props.session}
                dateRange={props.dateRange}
                phrase={params.phrase}
            />
        );
    }

    if (params.articleId) {
        return (
            <CompanyArticleSubpage />
        );
    }

    return (
        <Spin spinning={loading}>
            {!loading && data?.Company && <CompanyPage
                session={props.session}
                company={data.Company}
                dateRange={props.dateRange}
                tab={tab}
                propagateUpdate={refetch}
                news={[]}
            />}
        </Spin>
    );
}
