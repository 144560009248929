import { ResponsiveLine, Serie } from '@nivo/line';
import React, { useContext, useState } from 'react';
import logo from '../visibly_logo_black_white.svg';
import { DashboardContextualCardProps } from '../common';
import { useGetContentMassPlotQuery } from '../generated/graphql.d';
import { sortBy } from 'lodash';
import { startOfMonth, subMonths } from 'date-fns';
import { Card, Dropdown, Menu, Spin } from 'antd';
import { CheckOutlined, SettingOutlined } from '@ant-design/icons';
import { DateRangeContext } from '../context/DateRange';
import { useFilter } from '../company/Cards/useFilter';

function Tooltip({ point }: { point: any }) {
    return (
        <div
            style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
            }}
        >
            {point.data.xFormatted}
            <div>
                Publikacje: <b>{point.data.y}</b>`
            </div>
        </div>
    );
}

export default function ContentMassPlot(props: { series: Serie[] }) {
    const gradProps = {
        gradientUnits: 'userSpaceOnUse',
        x1: '0',
        y1: '0',
        x2: '50',
        y2: '550',
    };

    return (
        <div
            style={{
                position: 'relative',
                height: '40vh',
                backgroundImage: logo,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundSize: '100% 100%',
            }}
        >
            <img
                src={logo}
                style={{
                    zIndex: 100,
                    position: 'absolute',
                    bottom: '15%',
                    right: '50px',
                    height: '80px',
                    opacity: 0.2,
                }}
                alt="logo"
            />
            <ResponsiveLine
                colors={data => data.color}
                tooltip={Tooltip}
                data={props.series}
                animate={true}
                margin={{ bottom: 50, left: 40, top: 20, right: 10 }}
                xScale={{ type: 'time', format: 'native' }}
                yScale={{ type: 'linear' }}
                axisTop={null}
                xFormat="time:%Y-%m-%d"
                axisRight={null}
                enableArea={true}
                areaOpacity={0.5}
                enableGridX={false}
                curve="monotoneX"
                axisBottom={{
                    format: '%Y-%m',
                    tickValues: 'every 1 month',
                    legendPosition: 'middle',
                    legendOffset: 46,
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle',
                }}
                enablePoints={false}
                useMesh={true}
            />
            <svg>
                <defs>
                    <linearGradient id="default" {...gradProps}>
                        <stop offset="25%" stopColor="#00abe8"/>
                        <stop offset="55%" stopColor="rgba(00, 171,255,0)"/>
                    </linearGradient>
                    <linearGradient id="range" {...gradProps}>
                        <stop offset="25%" stopColor="#301091"/>
                        <stop offset="55%" stopColor="rgba(48, 16, 145, 0)"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export function ContentMassPlotElement(props: DashboardContextualCardProps) {
    const context = useContext(DateRangeContext);
    const [mode, setMode] = useState<'CUMULATIVE' | 'SIMPLE'>('CUMULATIVE');
    const [list, setList] = useState<string | null>(null);
    const filter = useFilter();

    const baseOptions = {
        variables: {
            slug: props.company.slug,
            from: context.dateRange.from,
            to: context.dateRange.to,
            filter,
        },
    };

    const { loading, data } = useGetContentMassPlotQuery(baseOptions);
    const details = data?.Company?.stats.contentMassDetails || [];

    let series: Serie[];
    let sum = 0;

    const publicationsPerDay = sortBy(details.map(item => {
        return {
            x: new Date(item!.date),
            y: item!.count,
        };
    }), 'x');

    const dayCount = mode === 'CUMULATIVE' ? publicationsPerDay.map(item => {
        sum += item.y || 0;
        return {
            x: item.x,
            y: sum,
        };
    }) : publicationsPerDay;

    if (context.dateRange) {
        const { from, to } = context.dateRange;
        const selectedRange = dayCount.filter(i => i.x <= to && i.x >= from);
        const monthsAgo = subMonths(startOfMonth(to), 12);
        const previously = dayCount.filter(i => i.x <= from && i.x >= monthsAgo);
        if (selectedRange.length) {
            previously.push(selectedRange[0]);
        }
        series = [
            {
                id: 'zakres',
                data: selectedRange,
                color: '#301091',
            },
            {
                id: 'ostatnie 12 miesięcy',
                data: previously,
                color: '#00abe8',
            },
        ];
    } else {
        series = [
            {
                id: 'publikacje',
                data: dayCount,
//                color: 'url(#default)',
                color: '#00abe8',
            },
        ];
    }

    const menu = <Menu>
        {/*<Menu.Item onClick={download}>Pobierz wykres</Menu.Item>*/}
        <Menu.Item icon={mode === 'CUMULATIVE' ? <CheckOutlined /> : null} onClick={() => setMode('CUMULATIVE')}>Pokaż
            sumę</Menu.Item>
        <Menu.Item icon={mode === 'SIMPLE' ? <CheckOutlined /> : null} onClick={() => setMode('SIMPLE')}>Pokaż
            zmianę</Menu.Item>
        <Menu.Item icon={list === 'media_a' ? <CheckOutlined /> : null} onClick={() =>
            list === 'media_a' ? setList(null) : setList('media_a')}>Media A</Menu.Item>
    </Menu>;

    const dropdown = <Dropdown overlay={menu} trigger={['click']}>
        <SettingOutlined />
    </Dropdown>;

    return (
        <Card
            title={'Content Mass'}
            extra={dropdown}
            type={'inner'}
        >
            <Spin spinning={loading}>
                {/*<div style={{height: '70vh'}}>*/}
                <div>
                    <ContentMassPlot series={series}/>
                </div>
            </Spin>
        </Card>
    );
}
