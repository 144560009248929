import { Link, SessionProp, CompanySlugProp } from '../api';
import { PropagateUpdate } from '../helpers/PropagateUpdate';
import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { useUpdateLinkMutation } from '../generated/graphql.d';
import Loading from '../Active/Loading';

interface EditLinkProps {
    link: Link;
    save: (companySlug: string, immReference: string, editable: string) => void;
}

export function EditLink(props: EditLinkProps & CompanySlugProp & SessionProp & PropagateUpdate) {
    const [editable, setEditable] = useState(props.link.editable);

    const [updateLink, { loading }] = useUpdateLinkMutation();

    async function save() {
        try {
            await updateLink({
                variables: {
                    link: props.link.immReference,
                    params: {
                        editable,
                    },
                },
            });
            // done();
        } catch (e) {
            console.error(e);
            // message.error('błąd podczas aktualizacji linka');
        } finally {
            props.propagateUpdate(Date.now());
        }
    }

    return (
        <Loading monitor={loading}>
            <Button onClick={save}>Zapisz</Button>{' '}
            <Input
                value={editable}
                onChange={e => setEditable(e.target.value)}
            />
        </Loading>
    );
}
