import { gql } from 'apollo-boost';

export const GET_THESES = gql`
    query GetThesis($companySlug: String!, $from: Date!, $to: Date!) {
        Company(slug: $companySlug) {
            theses {
                text
                slug
                companySlug
            }
            thesesStats(from: $from, to: $to) {
                slug
                direct
                indirect
            }
        }
    }
`;
export const CREATE_THESIS = gql`
    mutation AddThesis($companySlug: String!, $text: String!) {
        Company(slug: $companySlug) {
            addThesis(text: $text) {
                success
            }
        }
    }
`;
