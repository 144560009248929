import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as api from '../api';
import { Session, SessionProp } from '../api';
import { Button, Checkbox, List } from 'antd';
import Plot from '../phrase/Plot';
import { reverse, sortBy } from 'lodash';
import { MaybeDateRangeProp } from '../DatePanel';
import { endOfDay, subDays } from 'date-fns';
import { Screenshot } from '../components/screenshot'
import { useGetScansQuery } from '../generated/graphql.d';

type TParams = {
    phrase: string;
};

export interface IPhrase {
    phrase: string;
    companySlug?: string;
    companyUrls?: string[];
}

function printDate(stamp: string) {
    return new Date(parseInt(stamp, 10))
        .toISOString()
        .replace('T', ' ')
        .substr(0, 19);
}

export function PhraseRoute(props: SessionProp & MaybeDateRangeProp) {
    const match = useParams() as TParams;
    return (
        <Phrase
            phrase={match.phrase}
            session={props.session}
            dateRange={props.dateRange}
        />
    );
}

export function Phrase({
                           session,
                           ...props
                       }: IPhrase & SessionProp & MaybeDateRangeProp) {
    const now = endOfDay(new Date());

    const dateRange = props.dateRange || [subDays(now, 21), now];

    const { loading, error, data, refetch } = useGetScansQuery({
        variables: {
            slug: props.phrase,
            from: dateRange[0],
            to: dateRange[1],
            companies: [props.companySlug || '']
        },
    });

    const [checked, setChecked] = useState(new Set(props.companyUrls || []));
    const [visible, setVisible] = useState(false);
    const [maxRank, setMaxRank] = useState(10);

    useEffect(() => {
        if (data?.Phrase?.scans) {
            setChecked(
                new Set(
                    data.Phrase.scans.flatMap((scan) => scan.urls.filter((item, index) => scan.marked[index]))
                ),
            );
        }
    }, [data?.Phrase?.scans]);

    const switchMaxRank = () => {
        setMaxRank(maxRank === 10 ? 20 : 10);
    }

    if (loading) return <div>loading...</div>;
    if (error) return <div>error...</div>;

    async function scanPhrase() {
        await api.newJob((session as unknown) as Session, {
            jobType: 'scan',
            extra: props.phrase,
        });
        refetch();
    }

    const scans = (data?.Phrase?.scans || []).map(item => ({
        ...item,
        urls: item.urls.slice(0, maxRank)
    }));

    const data2 = scans
        ? Array.from(
            new Set(
                scans.flatMap((scan) => scan.urls.filter((item, index) => scan.marked[index]))
            ),
        )
        : [];

    const urls = data2.map(url => ({
        // @ts-ignore
        checked: checked.has(url),
        url,
    }));

    function checkedChange(e: { target: any }) {
        if (e.target.checked) {
            checked.add(e.target.value);
        } else {
            checked.delete(e.target.value);
        }
        setChecked(new Set(checked));
    }

    return (
        <div>
            <h2 onClick={switchMaxRank}>Monitorowana fraza: "{data?.Phrase?.text}"</h2>

            {data2.length ? (
                <div className="App" style={{ height: '40vh' }}>
                    <Plot
                        scans={reverse(sortBy(scans, 'date'))}
                        data={data2.filter(
                            // @ts-ignore
                            i => (checked.size ? checked.has(i) : true),
                        )}
                        maxRank={maxRank}
                    />
                </div>
            ) : (
                <div>Brak danych do wykresu, skanuj frazę</div>
            )}

            <h2 onClick={() => setVisible(!visible)}>Linki</h2>
            {visible ? (
                <List itemLayout="horizontal">
                    {sortBy(urls).map((url, id: number) => (
                        <List.Item style={{ alignItems: 'start' }} key={id}>
                            <label>
                                <Checkbox
                                    checked={url.checked}
                                    onChange={checkedChange}
                                    value={url.url}
                                />{' '}
                                {url.url}
                            </label>
                        </List.Item>
                    ))}
                </List>
            ) : (
                ''
            )}
            <h2>
                Skanowania: <Button onClick={scanPhrase}>Skanuj</Button>
            </h2>
            <List itemLayout="horizontal">
                {reverse(sortBy(data?.Phrase?.scans, 'date')).map(
                    (scan: { date: string; urls: string[], marked: boolean[] }) => (
                        <List.Item
                            style={{ alignItems: 'start' }}
                            key={scan.date}
                            extra={
                                <Screenshot phrase={props.phrase} date={scan.date} />
                            }
                        >
                            <List.Item.Meta title={printDate(scan.date)}/>
                            <div>
                                {scan.urls
                                    .map((link, index) => ({
                                        i: link,
                                        c: 1 + index,
                                        s: scan.marked[index]
                                    }))
                                    .filter((i) => i.s)
                                    .map(t => (
                                        <p key={t.i}>
                                            <a href={t.i}>
                                                {t.c}: {t.i}
                                            </a>
                                        </p>
                                    ))}
                            </div>
                        </List.Item>
                    ),
                )}
            </List>
        </div>
    );
}
