import { MaybeDateRangeProp } from '../DatePanel';
import React, { useState } from 'react';
import { List, Select, Space, Spin, Typography } from 'antd';
import { green, red } from '@ant-design/colors';
import { Company, useGetLogsForCompanyQuery } from '../generated/graphql.d';

const { Option } = Select;

type EventLogTopic = 'TOP3' | 'TOP10' | 'NEW' | 'LOST' | 'CHANGE';
type EventLogDirection = 'UP' | 'DOWN';

export interface IEventLogModel {
    date?: Date;
    topic: EventLogTopic;
    change?: EventLogDirection;
    message: string;
}

export interface IEventsTabProps extends MaybeDateRangeProp {
    company: Pick<Company, 'slug'>
}

export function EventsTab(props: IEventsTabProps) {
    const [from, to] = props.dateRange ? props.dateRange : [null, null];
    const [filter, setFilter] = useState<string[]>([]);
    const {data, loading} = useGetLogsForCompanyQuery({
        variables: {
            slug: props.company.slug,
            from,
            to,
        }
    })
    // const logs = data?.Logs || [];
    const logs = data?.Company?.log || [];

    const handleChange = (value: string[]) => {
        setFilter(value);
    };

    const children = ['TOP3', 'TOP10', 'NEW', 'LOST', 'CHANGE'].map((i: string) =>
        <Option key={i} value={i}>{i}</Option>);

    const filteredLogs = logs.filter(item => {
        return item?.topic && filter.length > 0 ? filter.includes(item.topic) : true;
    })

    return <Space direction={"vertical"} style={{width: '100%'}}>
        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            onChange={handleChange}
        >
            {children}
        </Select>

        <Spin spinning={loading}>
            <List
                dataSource={filteredLogs}
                renderItem={(item) => (
                    <List.Item>
                        <Typography.Text><span style={{backgroundColor: item?.change === 'UP' ? green.primary : red.primary}}>[{item?.topic}]</span></Typography.Text> {item?.message}
                    </List.Item>
                )}
            />
        </Spin>
    </Space>;
}
