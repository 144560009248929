import React from 'react';
import { CheckOutlined, ClockCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export function JobStatusIcon({ status }: { status: string }) {
    switch (status) {
        case 'started':
            return (
                <Spin
                    indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin/>
                    }
                />
            );
        case 'created':
            return (
                <ClockCircleOutlined style={{ fontSize: 24, color: 'blue' }}/>
            );
        case 'failed':
            return <WarningOutlined style={{ fontSize: 24, color: 'red' }}/>;
        case 'done':
            return <CheckOutlined style={{ fontSize: 24, color: 'green' }}/>;
        default:
            return <span>{status}</span>;
    }
}
