import { DatePicker } from 'antd';
import React, { useContext, useState } from 'react';
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';
import moment, { Moment } from 'moment';
import { DateRangeContext } from './context/DateRange';

function Def(props: { onClick: () => void }) {
    return (
        <span
            style={{
                textDecoration: 'underline',
                textDecorationStyle: 'dashed',
                fontSize: '18px',
                margin: '10px',
                cursor: 'pointer',
            }}
            onClick={props.onClick}
        >
            Domyślnie
        </span>
    );
}

type MaybeMomentDateRange = [Moment, Moment] | undefined;
export type MaybeDateRange = [Date, Date] | undefined;

export interface MaybeDateRangeProp {
    dateRange: MaybeDateRange;
}

export type DateRange = [Date, Date];

export interface DateRangeProp {
    dateRange: DateRange;
}

export function DatePanel(props: { onChange?: (dates: DateRange) => void }) {
    const now = new Date();
    const context = useContext(DateRangeContext);
    const [showPicker, setShowPicker] = useState(true);

    function mySetDates(maybeDates: MaybeDateRange) {
        const dates: DateRange = maybeDates
            ? [startOfDay(maybeDates[0]), endOfDay(maybeDates[1])]
            : [startOfMonth(new Date()), endOfDay(new Date())];

        props.onChange?.(dates);
        context.setDateRange({
            from: dates[0],
            to: dates[1],
        }, 'QUARTER');
    }

    function handleOpenPicker() {
        setShowPicker(true);
        mySetDates([startOfMonth(now), now] as MaybeDateRange);
    }

    function handleClosePicker() {
        setShowPicker(false);
        mySetDates(undefined);
    }

    function handleDateChange(e: any) {
        mySetDates([e[0].toDate(), e[1].toDate()]);
    }

    return (
        <div
            style={{
                textAlign: 'right',
                padding: '5px',
                borderBottom: '1px solid #25293e',
            }}
        >
            <span
                style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#301091',
                }}
            >
                Wyniki dla zakresu Dat
            </span>
            {showPicker ? (
                <>
                    <DatePicker.RangePicker
                        style={{ marginLeft: '20px' }}
                        onChange={handleDateChange}
                        defaultValue={
                            context.dateRange
                                ? [moment(context.dateRange.from), moment(context.dateRange.to)] as MaybeMomentDateRange
                                : undefined
                        }
                    />{' '}
                    {/*<Button onClick={handleClosePicker}>reset</Button>*/}
                </>
            ) : (
                <Def onClick={handleOpenPicker}/>
            )}
        </div>
    );
}

//
