import { reverse, sortBy, takeWhile } from 'lodash';
import React from 'react';
import { Link } from '../../api';
import {TabBadgeWrapper} from "./NewsTabBadge";

export function LinksTabBadge(props: { links: Link[] }) {
    return <TabBadgeWrapper
        title="Linki"
        count={
            takeWhile(
                reverse(sortBy(props.links, 'date')),
                l => !l.active,
            ).length
        }
    />
}
