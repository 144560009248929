import React from 'react';

export const FiltersContext = React.createContext({
    filters: {
        mediaLists: [] as string[],
        phraseLists: [] as string[],
        articles: [] as string[],
    },
    setMediaLists: (mediaLists: string[]) => {
    },
    setPhraseLists: (phraseLists: string[]) => {
    },
    setArticles: (articles: string[]) => {
    },
});

export const FiltersContextProvider: React.FC = ({ children }) => {
    const [filters, setFilters] = React.useState<{ mediaLists: string[], phraseLists: string[], articles: string[] }>({
        mediaLists: [],
        phraseLists: [],
        articles: [],
    });

    const setMediaLists = (mediaLists: string[]) => {
        setFilters({
            ...filters,
            mediaLists,
        });
    };

    const setPhraseLists = (phraseLists: string[]) => {
        setFilters({
            ...filters,
            phraseLists,
        });
    };

    const setArticles = (articles: string[]) => {
        setFilters({
            ...filters,
            articles,
        });
    };

    return (
        <FiltersContext.Provider value={{ filters, setMediaLists, setPhraseLists, setArticles }}>
            {children}
        </FiltersContext.Provider>
    );
};