import React from 'react';
import { Button } from 'antd';
import { Phrase } from '../phrase/PhrasesTable';
import OpenCSV from '../open/open-csv';

export function PhrasesCSVDownload(props: {
    companySlug: string;
    phrases: Phrase[];
}) {
    const fileName = `frazy_dla_${props.companySlug}.csv`;

    return (
        <Button
            onClick={e => {
                e.stopPropagation();
                OpenCSV(
                    fileName,
                    props.phrases
                        .map(i => ({ ...i, stats: i.stats || {} }))
                        .map(i => [
                            i.slug,
                            i.text,
                            i.potential,
                            i.ranks,
                            i.stats.ctr,
                            i.stats.estimatedReads,
                        ]) as string[][],
                );
            }}
        >
            Pobierz CSV Fraz
        </Button>
    );
}