import { Card, Spin, Table, Typography } from 'antd';
import React, { useContext } from 'react';
import { DateRangeContext } from '../../context/DateRange';
import { useGetTopLinksQuery } from '../../generated/graphql.d';
import type { DashboardContextualCardProps } from '../../common';
import { useFilter } from './useFilter';
import { CompanyContext } from '../../context/CompanyContext';

const { Link } = Typography;

export const TopLinksCard = () => {
    const companyContext = useContext(CompanyContext);
    const context = useContext(DateRangeContext);
    const filter = useFilter();

    const { loading, data } = useGetTopLinksQuery({
        variables: {
            from: context.dateRange.from,
            slug: companyContext.companySlug,
            to: context.dateRange.to,
            filter,
        },
    });

    const topLinks: any[] = data?.Company?.stats.topLinks.slice(0, 10) || [];

    return <Card title='Top Linki'>
        <Spin spinning={loading}>
            <Table
                pagination={false}
                dataSource={topLinks}
                columns={[{
                    title: 'Url',
                    dataIndex: 'url',
                    render: (url: string) => <Link href={url}>{url}</Link>,
                }, {
                    title: 'Ruch',
                    dataIndex: 'clicks',
                    align: 'right',
                }]} />
        </Spin>
    </Card>;
};