import React, { ReactNode } from 'react';
import { Doing } from '../StatusIcon/Doing';

interface LoadingParams<T> {
    monitor: T | undefined;
    children: ReactNode
}

export default function Loading<T>(params: LoadingParams<T>) {

    if (checkUndefined(params.monitor)) {
        return (
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    height: '100%',
                }}
            >
                <Doing/>
            </div>
        );
    } else {
        return <>{params.children}</>;
    }
}


function checkUndefined<T>(a: T | undefined): a is T {
    return a === undefined;
}
