import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './Login';
import Main from './Main';
import { MaybeSession, NoSession, SessionManager } from './session';
import { SessionRepositoryInterface } from './repository';
import { ApolloConfig } from './ApolloConfig';


interface AppProps {
    repository: SessionRepositoryInterface;
    sessionManager: SessionManager;
}

export default function App(props: AppProps) {
    const [session, setSession] = useState(
        new NoSession('not logged in yet') as MaybeSession,
    );

    useEffect(() => props.sessionManager.registerObserver(setSession), [
        props.sessionManager,
    ]);

    return (
        <ApolloConfig>
            <div>
                {session instanceof NoSession ? (
                    <Login
                        repository={props.repository}
                        onLogIn={props.sessionManager.login.bind(
                            props.sessionManager,
                        )}
                    />
                ) : (
                    <Main
                        session={session}
                        logout={props.sessionManager.logout.bind(
                            props.sessionManager,
                        )}
                    />
                )}
            </div>
        </ApolloConfig>
    );
}
