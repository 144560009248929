import React, {useState} from 'react';
import {UrlInfo} from './LinksTable';
import {Button, Space, Spin, Tooltip} from 'antd';
import {
    LinkStatus,
    useAddLinkMutation,
    useCheckLinkMutation,
    useGetLinkDetailsQuery,
    useLinkToPhrasesQuery,
    useUpdateLinkMutation,
} from '../generated/graphql.d';
import {dateToLocal} from "../helpers/time/dateToLocal";
import {PropagateUpdate} from "../helpers/PropagateUpdate";
import { LinkDetailsWizard } from './LinkDetailsWizard';
import {CompanySlugProp} from "../api";

function UseLink({url, edited, propagateUpdate}: { url: string, edited: string } & PropagateUpdate) {
    const [updateLink, {loading}] = useUpdateLinkMutation();

    async function click() {
        await updateLink({
            variables: {
                link: url,
                params: {
                    editable: edited,
                    status: LinkStatus.Valid
                }
            }
        });
        propagateUpdate();
    }

    return <Button size={'small'} ghost={false} onClick={click} loading={loading}>Użyj</Button>;
}

function AddNewLink({url, companySlug, propagateUpdate}: { url: string, companySlug: string } & PropagateUpdate) {
    const [addLink, {loading}] = useAddLinkMutation();

    async function click() {
        await addLink({
            variables: {
                links: [url],
                company: companySlug
            }
        });
        propagateUpdate();
    }

    return <Button size={'small'} ghost={false} onClick={click} loading={loading}>Dodaj jako nowy</Button>;
}

function CheckLink({url, propagateUpdate}: { url: string } & PropagateUpdate) {
    const [loading, setLoading] = useState(false);
    const [checkLink] = useCheckLinkMutation();

    async function syncFn() {
        try {
            setLoading(true);
            await checkLink({variables: {url}});
        } catch (e) {
            console.error(e);
        } finally {
            propagateUpdate();
            setLoading(false);
        }
    }

    return <Button onClick={syncFn} loading={loading}>Sprawdź ponownie</Button>;
}

function MarkAs({url, propagateUpdate, valid}: { url: string, valid: boolean } & PropagateUpdate) {
    const [updateLink, {loading}] = useUpdateLinkMutation();

    async function click() {
        await updateLink({
            variables: {
                link: url,
                params: {
                    status: valid ? LinkStatus.Valid : LinkStatus.Invalid
                }
            }
        });
        propagateUpdate();
    }

    return <Button onClick={click}
                   loading={loading}>{valid ? "Oznacz jako poprawny" : "Oznacz jako niepoprawny"}</Button>;
}

function MarkAsValid(props: { url: string } & PropagateUpdate) {
    return <MarkAs valid={true} {...props} />;
}

export function InteractiveLink(props: { url: string, id: string, companySlug?: string } & PropagateUpdate) {
    const {data: phrasesData} = useLinkToPhrasesQuery({
        variables: {url: props.url}
    })
    const linkTitle = phrasesData?.Link?.phrases.map(p => p?.text) || [];
    return <Space>
        <Tooltip title={linkTitle?.join(", ")}><a href={props.url}>{props.url}</a></Tooltip>
        {linkTitle.length > 0 &&
        <Tooltip title={linkTitle?.join(", ")}><span>(Znalezione słowa kluczowe: {linkTitle.length})</span></Tooltip>}
        {phrasesData?.Link?.isInUse
            ? <span>Link już jest użyty w systemie</span>
            : <UseLink propagateUpdate={props.propagateUpdate} url={props.id} edited={props.url}/>}
        {!phrasesData?.Link?.isInUse && props.companySlug && <AddNewLink propagateUpdate={props.propagateUpdate} url={props.url} companySlug={props.companySlug} />}
    </Space>
}

export function LinkDetails(
    { url, status, propagateUpdate, companySlug }:
        { details: UrlInfo } & { url: string, edit: string, status: LinkStatus | null } & PropagateUpdate & CompanySlugProp
) {
    const {data, loading} = useGetLinkDetailsQuery({variables: {url}});

    const details = data?.LinkDetails;

    return <Spin spinning={loading}>
        {!loading && details
            && <LinkDetailsWizard details={details} url={url} propagateUpdate={propagateUpdate} companySlug={companySlug}/>}
        <div style={{float: "right"}}>
            <Space>
                {details?.date && <div><span>Data ostatniego sprawdzenia: {dateToLocal(details.date)}</span></div>}
                <CheckLink url={url} propagateUpdate={propagateUpdate}/>
                {status === LinkStatus.Invalid
                    ? <MarkAsValid url={url} propagateUpdate={propagateUpdate}/>
                    : <MarkAs url={url} propagateUpdate={propagateUpdate} valid={false}/>
                }
            </Space>
        </div>
    </Spin>;
}
