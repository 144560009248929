import { Card, Spin, Table, Typography } from 'antd';
import React, { useContext } from 'react';
import { DateRangeContext } from '../../context/DateRange';
import { TopArticle, useGetTopArticlesQuery } from '../../generated/graphql.d';
import { useFilter } from './useFilter';
import { CompanyContext } from '../../context/CompanyContext';

const { Link } = Typography;

export const TopArticlesCard = () => {
    const context = useContext(DateRangeContext);
    const companyContext = useContext(CompanyContext);
    const filter = useFilter();

    const { loading, data } = useGetTopArticlesQuery({
        variables: {
            from: context.dateRange.from,
            slug: companyContext.companySlug,
            to: context.dateRange.to,
            filter,
        },
    });

    const topArticles = data?.Company?.stats.topArticles.slice(0, 10) || [];

    return <Card title='Top Artykuły'>
        <Spin spinning={loading}>
            <Table
                pagination={false}
                // @ts-ignore
                dataSource={topArticles}
                columns={[{
                    title: 'Tytuł',
                    dataIndex: 'title',
                    render: (title: string, article: TopArticle) => <Link
                        href={`/company/${companyContext.companySlug}/article/${article.articleId}`}>{title}</Link>,
                }, {
                    title: 'Ruch',
                    dataIndex: 'clicks',
                    align: 'right',
                }]} />
        </Spin>
    </Card>;
};