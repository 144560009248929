import React, { useContext } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { CheckOutlined, EllipsisOutlined } from '@ant-design/icons';
import { DateRangeContext } from '../context/DateRange';
import {
    endOfMonth,
    endOfQuarter,
    endOfYear,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    subMonths,
    subQuarters,
    subYears,
} from 'date-fns';
import { FiltersContext } from '../context/FiltersContext';

export const ArticleMenu = () => {
    const dateContext = useContext(DateRangeContext);
    const filterContext = useContext(FiltersContext);
    const now = new Date();

    const previousMonth = () => {
        const date = subMonths(now, 1);
        dateContext.setDateRange({
            from: startOfMonth(date),
            to: endOfMonth(date),
        }, 'MONTH');
    };

    const currentMonth = () => {
        dateContext.setDateRange({
            from: startOfMonth(now),
            to: endOfMonth(now),
        }, 'MONTH');
    };

    const currentQuarter = () => {
        dateContext.setDateRange({
            from: startOfQuarter(now),
            to: endOfQuarter(now),
        }, 'QUARTER');
    };

    const previousQuarter = () => {
        const date = subQuarters(now, 1);
        dateContext.setDateRange({
            from: startOfQuarter(date),
            to: endOfQuarter(date),
        }, 'QUARTER');
    };

    const currentYear = () => {
        dateContext.setDateRange({
            from: startOfYear(now),
            to: endOfYear(now),
        }, 'YEAR');
    };

    const previousYear = () => {
        const date = subYears(now, 1);
        dateContext.setDateRange({
            from: startOfYear(date),
            to: endOfYear(date),
        }, 'YEAR');
    };

    const setCompare = () => {
        dateContext.setCompare(!dateContext.compare);
    };

    const setPhraseList = (listSlug: string) => {
        if (filterContext.filters.phraseLists.includes(listSlug)) {
            filterContext.setPhraseLists(
                filterContext.filters.phraseLists.filter(
                    (list) => list !== listSlug,
                ),
            );
        } else {
            filterContext.setPhraseLists([...filterContext.filters.phraseLists, listSlug]);
        }
    };

    const setMediaList = (listSlug: string) => {
        if (filterContext.filters.mediaLists.includes(listSlug)) {
            filterContext.setMediaLists(
                filterContext.filters.mediaLists.filter(
                    (list) => list !== listSlug,
                ),
            );
        } else {
            filterContext.setMediaLists([...filterContext.filters.mediaLists, listSlug]);
        }
    };

    const menu = (<Menu>
        <Menu.Item onClick={currentMonth}>
            Bieżący miesiąc
        </Menu.Item>
        <Menu.Item onClick={previousMonth}>
            Poprzedni miesiąc
        </Menu.Item>
        <Menu.Item onClick={currentQuarter}>
            Bieżacy kwartał
        </Menu.Item>
        <Menu.Item onClick={previousQuarter}>
            Poprzedni kwartał
        </Menu.Item>
        <Menu.Item onClick={currentYear}>
            Bieżacy rok
        </Menu.Item>
        <Menu.Item onClick={previousYear}>
            Poprzedni rok
        </Menu.Item>
        <Menu.Item icon={dateContext.articleCompare ? <CheckOutlined /> : null} onClick={() => setCompare()}>
            Pokaż poprzedni okres
        </Menu.Item>
    </Menu>);

    return <Dropdown key="more" overlay={menu}>
        <Button
            style={{
                border: 'none',
                padding: 0,
            }}
        >
            <EllipsisOutlined
                style={{
                    fontSize: 20,
                    verticalAlign: 'top',
                }}
            />
        </Button>
    </Dropdown>
};
