import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: any;
  Date: any;
};

export enum CompanyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum EventTopic {
  Top3 = 'TOP3',
  Top10 = 'TOP10',
  New = 'NEW',
  Lost = 'LOST',
  Change = 'CHANGE'
}

export enum LinkStatus {
  Pending = 'pending',
  Valid = 'valid',
  Invalid = 'invalid'
}

export type Subscription = {
  __typename?: 'Subscription';
  jobUpdated: Job;
  linkUpdated: Link;
};

export type DashboardQuery = {
  phraseList?: Maybe<Array<Maybe<Scalars['String']>>>;
  mediaList?: Maybe<Array<Maybe<Scalars['String']>>>;
  articles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type Article = {
  __typename?: 'Article';
  id: Scalars['ID'];
  title: Scalars['String'];
  date: Scalars['String'];
  content: Scalars['String'];
  author: Scalars['String'];
  reads: Scalars['Int'];
  ave: Scalars['Int'];
  reach: Scalars['Int'];
  linksCount: Scalars['Int'];
  keywords: Array<Maybe<ArticleKeywordsStats>>;
};

export type ArticleStats = {
  __typename?: 'ArticleStats';
  id: Scalars['ID'];
  title: Scalars['String'];
  date: Scalars['String'];
  content: Scalars['String'];
  author: Scalars['String'];
  keywords: Array<Maybe<ArticleKeywordsStats>>;
  links: Array<Maybe<TopLink>>;
  ave: Scalars['Int'];
  reach: Scalars['Int'];
  reads: Scalars['Int'];
};

export type SuggestedArticle = {
  __typename?: 'SuggestedArticle';
  id: Scalars['ID'];
  title: Scalars['String'];
  similarity: Scalars['Float'];
};

export type LinkStats = {
  __typename?: 'LinkStats';
  phrases: Array<Maybe<Phrase>>;
  isInUse: Scalars['Boolean'];
};


export type LinkStatsPhrasesArgs = {
  url: Scalars['String'];
};


export type LinkStatsIsInUseArgs = {
  url: Scalars['String'];
};

export type ContentMassDetails = {
  __typename?: 'ContentMassDetails';
  date: Scalars['String'];
  count: Scalars['Int'];
};

export type Medium = {
  __typename?: 'Medium';
  id: Scalars['ID'];
  domain: Scalars['String'];
};

export type TopMedia = {
  __typename?: 'TopMedia';
  domain: Scalars['String'];
  clicks: Scalars['Int'];
  links: Scalars['Int'];
};

export type TopLink = {
  __typename?: 'TopLink';
  url: Scalars['String'];
  clicks: Scalars['Int'];
  ave: Scalars['Int'];
  reach: Scalars['Int'];
};

export type TopArticle = {
  __typename?: 'TopArticle';
  title: Scalars['String'];
  clicks: Scalars['Int'];
  articleId: Scalars['ID'];
};

export type SimilarQuestions = {
  __typename?: 'SimilarQuestions';
  phrase: Scalars['String'];
  question: Scalars['String'];
  link: Scalars['String'];
};

export type LastPublications = {
  __typename?: 'LastPublications';
  title: Scalars['String'];
  articleId: Scalars['ID'];
  publicationsNumber: Scalars['Int'];
};

export type CompanyStats = {
  __typename?: 'CompanyStats';
  id: Scalars['ID'];
  ave: Scalars['Int'];
  reach: Scalars['Int'];
  contentMass: Scalars['Int'];
  contentMassDetails: Array<Maybe<ContentMassDetails>>;
  potential: Scalars['Int'];
  reads: Scalars['Int'];
  topMedia: Array<Maybe<TopMedia>>;
  topLinks: Array<Maybe<TopLink>>;
  topArticles: Array<Maybe<TopArticle>>;
  similarQuestions: Array<Maybe<SimilarQuestions>>;
  lastPublications: Array<Maybe<LastPublications>>;
};


export type CompanyStatsContentMassDetailsArgs = {
  list?: Maybe<Scalars['String']>;
};

export type PhraseList = {
  __typename?: 'PhraseList';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  phrases: Array<Phrase>;
};

export type MediaList = {
  __typename?: 'MediaList';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  domains: Array<Scalars['String']>;
};

export type CompanyMedia = {
  __typename?: 'CompanyMedia';
  list: Array<Maybe<Medium>>;
};


export type CompanyMediaListArgs = {
  listSlug: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['ID'];
  status: CompanyStatus;
  links: Array<Link>;
  theses: Array<Thesis>;
  thesesStats: Array<ThesisStats>;
  phrases: Array<Phrase>;
  phrasesStats: Array<PhraseStats>;
  phraseLists: Array<Maybe<PhraseList>>;
  news: Array<News>;
  log: Array<Maybe<EventLog>>;
  stats: CompanyStats;
  favourite: Array<Phrase>;
  media?: Maybe<CompanyMedia>;
  mediaLists: Array<Maybe<MediaList>>;
  article: ArticleStats;
  articles: Array<Article>;
  suggestedArticles: Array<SuggestedArticle>;
};


export type CompanyLinksArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type CompanyThesesStatsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
};


export type CompanyPhrasesArgs = {
  listSlug?: Maybe<Scalars['String']>;
};


export type CompanyPhrasesStatsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  useDbEngine?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DashboardQuery>;
};


export type CompanyNewsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type CompanyLogArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type CompanyStatsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  useDbEngine?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DashboardQuery>;
};


export type CompanyArticleArgs = {
  id: Scalars['ID'];
  filter?: Maybe<DashboardQuery>;
};


export type CompanySuggestedArticlesArgs = {
  linkId: Scalars['ID'];
};


export type News = {
  __typename?: 'News';
  phrase: Scalars['String'];
  phraseSlug: Scalars['String'];
  scans: Array<Maybe<Scan>>;
};

export type JobStats = {
  __typename?: 'JobStats';
  all: Scalars['Int'];
  error: Scalars['Int'];
  done: Scalars['Int'];
  doing: Scalars['Int'];
  waiting: Scalars['Int'];
};

export type SubTask = {
  __typename?: 'SubTask';
  started: Scalars['Timestamp'];
  out?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Timestamp']>;
  extra?: Maybe<Scalars['String']>;
  worker?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
  type: Scalars['String'];
  created: Scalars['Timestamp'];
  state: Scalars['String'];
  started?: Maybe<Scalars['Timestamp']>;
  updated: Scalars['Timestamp'];
  finished?: Maybe<Scalars['Timestamp']>;
  cancelledAt?: Maybe<Scalars['Timestamp']>;
  stats?: Maybe<JobStats>;
  subTasks: Array<SubTask>;
};

export type Scan = {
  __typename?: 'Scan';
  date: Scalars['String'];
  phrase: Phrase;
  urls: Array<Scalars['String']>;
  marked: Array<Scalars['Boolean']>;
  screenShot: Scalars['String'];
};

export type Potential = {
  __typename?: 'Potential';
  value: Scalars['Int'];
  date: Scalars['Timestamp'];
};

export type Phrase = {
  __typename?: 'Phrase';
  text: Scalars['String'];
  slug: Scalars['ID'];
  potential?: Maybe<Scalars['Int']>;
  potentials: Array<Potential>;
  theses?: Maybe<Array<Scalars['ID']>>;
  scans: Array<Scan>;
};


export type PhraseScansArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  companies: Array<Scalars['String']>;
};

export type PhraseStats = {
  __typename?: 'PhraseStats';
  slug: Scalars['ID'];
  ctr: Scalars['Float'];
  positions: Array<Scalars['Int']>;
  estimatedReads: Scalars['Int'];
};

export type ArticleKeywordsStats = {
  __typename?: 'ArticleKeywordsStats';
  slug: Scalars['ID'];
  text: Scalars['String'];
  estimatedReads: Scalars['Int'];
};


export type Ave = {
  __typename?: 'Ave';
  date: Scalars['Date'];
  value: Scalars['Float'];
};

export type Reach = {
  __typename?: 'Reach';
  date: Scalars['Date'];
  value: Scalars['Float'];
};

export type Link = {
  __typename?: 'Link';
  id: Scalars['ID'];
  link: Scalars['String'];
  immReference: Scalars['String'];
  sources: Array<Scalars['String']>;
  active: Scalars['Boolean'];
  editable: Scalars['String'];
  theses: Array<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  status?: Maybe<LinkStatus>;
  ave?: Maybe<Array<Maybe<Ave>>>;
  medium: Scalars['String'];
  article?: Maybe<Article>;
  reach?: Maybe<Array<Maybe<Reach>>>;
};

export type LinkDetailsDocumentMeta = {
  __typename?: 'LinkDetailsDocumentMeta';
  id: Scalars['ID'];
  robots?: Maybe<Scalars['String']>;
};

export type LinkDetailsDocument = {
  __typename?: 'LinkDetailsDocument';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  canonical?: Maybe<Scalars['String']>;
  meta?: Maybe<LinkDetailsDocumentMeta>;
};

export type LinkDetailsRequestsHeaders = {
  __typename?: 'LinkDetailsRequestsHeaders';
  location?: Maybe<Scalars['String']>;
};

export type LinkDetailsRequests = {
  __typename?: 'LinkDetailsRequests';
  id: Scalars['ID'];
  redirected: Scalars['Boolean'];
  status: Scalars['Float'];
  headers?: Maybe<LinkDetailsRequestsHeaders>;
};

export type LinkDetails = {
  __typename?: 'LinkDetails';
  id: Scalars['ID'];
  url: Scalars['String'];
  finalUrl?: Maybe<Scalars['String']>;
  finalStatus?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  document?: Maybe<LinkDetailsDocument>;
  requests: Array<Maybe<LinkDetailsRequests>>;
};

export type ThesisStats = {
  __typename?: 'ThesisStats';
  slug: Scalars['String'];
  direct: Scalars['Float'];
  indirect: Scalars['Float'];
};

export type Thesis = {
  __typename?: 'Thesis';
  id: Scalars['ID'];
  slug: Scalars['String'];
  text: Scalars['String'];
  companySlug: Scalars['ID'];
  stats: ThesisStats;
};


export type ThesisStatsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type AddThesisResponse = {
  __typename?: 'AddThesisResponse';
  success: Scalars['Boolean'];
  thesis?: Maybe<Thesis>;
};

export type RemoveThesisResponse = {
  __typename?: 'RemoveThesisResponse';
  success: Scalars['Boolean'];
  thesis?: Maybe<Thesis>;
};

export type Theses = {
  __typename?: 'Theses';
  forCompany: Array<Thesis>;
};


export type ThesesForCompanyArgs = {
  companySlug: Scalars['String'];
};

export type Phrases = {
  __typename?: 'Phrases';
  forCompany: Array<Phrase>;
};


export type PhrasesForCompanyArgs = {
  companySlug: Scalars['String'];
};

export type Links = {
  __typename?: 'Links';
  forCompany: Array<Link>;
};


export type LinksForCompanyArgs = {
  companySlug: Scalars['String'];
};

export type Companies = {
  __typename?: 'Companies';
  list: Array<Company>;
  details?: Maybe<Company>;
};


export type CompaniesDetailsArgs = {
  slug: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  login: Scalars['String'];
  type: Scalars['String'];
  companiesSlugs: Array<Scalars['String']>;
};

export type EventLog = {
  __typename?: 'EventLog';
  date?: Maybe<Scalars['Date']>;
  topic?: Maybe<EventTopic>;
  message?: Maybe<Scalars['String']>;
  change?: Maybe<Scalars['String']>;
  tag: Array<Maybe<EventTopic>>;
};

export type Media = {
  __typename?: 'Media';
  autocomplete: Array<Scalars['String']>;
};


export type MediaAutocompleteArgs = {
  query: Scalars['String'];
};

export type AddPhraseResponse = {
  __typename?: 'AddPhraseResponse';
  success: Scalars['Boolean'];
  phrase?: Maybe<Phrase>;
};

export type Response = {
  __typename?: 'Response';
  success: Scalars['Boolean'];
};

export type UpdatePhraseResponse = {
  __typename?: 'UpdatePhraseResponse';
  success: Scalars['Boolean'];
};

export type PhraseUpdateParams = {
  theses?: Maybe<Array<Scalars['ID']>>;
  potential?: Maybe<Scalars['Int']>;
};

export type PhraseOps = {
  __typename?: 'PhraseOps';
  update: UpdatePhraseResponse;
};


export type PhraseOpsUpdateArgs = {
  params: PhraseUpdateParams;
};

export type AddLinkResponse = {
  __typename?: 'AddLinkResponse';
  success: Scalars['Boolean'];
};

export type LinkUpdateParams = {
  active?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['String']>;
  theses?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<LinkStatus>;
};

export type LinkUpdateResponse = {
  __typename?: 'LinkUpdateResponse';
  success: Scalars['Boolean'];
};

export type LinkOps = {
  __typename?: 'LinkOps';
  update: LinkUpdateResponse;
  assignArticle: LinkUpdateResponse;
};


export type LinkOpsUpdateArgs = {
  params: LinkUpdateParams;
};


export type LinkOpsAssignArticleArgs = {
  articleId?: Maybe<Scalars['ID']>;
};

export type MediaListOps = {
  __typename?: 'MediaListOps';
  add: Response;
  remove: Response;
};


export type MediaListOpsAddArgs = {
  name: Scalars['String'];
};


export type MediaListOpsRemoveArgs = {
  listSlug: Scalars['String'];
};

export type PhraseListsOps = {
  __typename?: 'PhraseListsOps';
  add: Response;
  remove: Response;
  assign: Response;
  unassign: Response;
};


export type PhraseListsOpsAddArgs = {
  name: Scalars['String'];
};


export type PhraseListsOpsRemoveArgs = {
  listSlug: Scalars['String'];
};


export type PhraseListsOpsAssignArgs = {
  listSlug: Scalars['String'];
  phraseSlug: Array<Maybe<Scalars['String']>>;
};


export type PhraseListsOpsUnassignArgs = {
  listSlug: Scalars['String'];
  phraseSlug: Scalars['String'];
};

export type ArticleOps = {
  __typename?: 'ArticleOps';
  add: Response;
  remove: Response;
};


export type ArticleOpsAddArgs = {
  title: Scalars['String'];
  content: Scalars['String'];
  author: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
};


export type ArticleOpsRemoveArgs = {
  articleId: Scalars['ID'];
};

export type CompanyOps = {
  __typename?: 'CompanyOps';
  addThesis: AddThesisResponse;
  removeThesis: RemoveThesisResponse;
  addPhrase: AddPhraseResponse;
  removePhrase: Response;
  addLink: AddLinkResponse;
  addPhraseToList: Response;
  removePhraseToList: Response;
  addDomainToList: Response;
  removeDomainToList: Response;
  mediaLists: MediaListOps;
  phraseLists: PhraseListsOps;
  article: ArticleOps;
  deactivate: Response;
  activate: Response;
};


export type CompanyOpsAddThesisArgs = {
  text: Scalars['String'];
};


export type CompanyOpsRemoveThesisArgs = {
  slug: Scalars['String'];
};


export type CompanyOpsAddPhraseArgs = {
  text: Array<Scalars['String']>;
  listSlug?: Maybe<Scalars['String']>;
};


export type CompanyOpsRemovePhraseArgs = {
  slug: Scalars['String'];
};


export type CompanyOpsAddLinkArgs = {
  links: Array<Scalars['String']>;
};


export type CompanyOpsAddPhraseToListArgs = {
  phrase: Scalars['String'];
  list?: Maybe<Scalars['String']>;
};


export type CompanyOpsRemovePhraseToListArgs = {
  phrase: Scalars['String'];
  list?: Maybe<Scalars['String']>;
};


export type CompanyOpsAddDomainToListArgs = {
  domain: Scalars['String'];
  listSlug?: Maybe<Scalars['String']>;
};


export type CompanyOpsRemoveDomainToListArgs = {
  domain: Scalars['String'];
  listSlug?: Maybe<Scalars['String']>;
};

export type CompaniesOps = {
  __typename?: 'CompaniesOps';
  add: Response;
};


export type CompaniesOpsAddArgs = {
  name: Scalars['String'];
};

export type MediaOps = {
  __typename?: 'MediaOps';
  addMedium: Response;
};


export type MediaOpsAddMediumArgs = {
  domain: Scalars['String'];
};

export type UserUpdateParams = {
  login?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  companiesSlugs: Array<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
};

export type UserUpdateResponse = {
  __typename?: 'UserUpdateResponse';
  success: Scalars['Boolean'];
};

export type UserOps = {
  __typename?: 'UserOps';
  add: UserUpdateResponse;
  update: UserUpdateResponse;
};


export type UserOpsAddArgs = {
  update: UserUpdateParams;
};


export type UserOpsUpdateArgs = {
  update: UserUpdateParams;
};

export type LoginForm = {
  login: Scalars['String'];
  pass: Scalars['String'];
};

export type Session = {
  __typename?: 'Session';
  token: Scalars['String'];
  user: User;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  session?: Maybe<Session>;
};

export type StatusResponse = {
  __typename?: 'StatusResponse';
  status: Scalars['String'];
  error?: Maybe<Scalars['String']>;
};

export type JobOps = {
  __typename?: 'JobOps';
  cancel: StatusResponse;
  trigger: StatusResponse;
};


export type JobOpsCancelArgs = {
  id: Scalars['ID'];
};


export type JobOpsTriggerArgs = {
  type: Scalars['String'];
};

export type LinkDetailsOps = {
  __typename?: 'LinkDetailsOps';
  check: StatusResponse;
};


export type LinkDetailsOpsCheckArgs = {
  url: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  Login: LoginResponse;
  Company?: Maybe<CompanyOps>;
  Phrase?: Maybe<PhraseOps>;
  Link?: Maybe<LinkOps>;
  Job?: Maybe<JobOps>;
  User?: Maybe<UserOps>;
  LinkDetails?: Maybe<LinkDetailsOps>;
  Media?: Maybe<MediaOps>;
  Companies?: Maybe<CompaniesOps>;
};


export type MutationLoginArgs = {
  form: LoginForm;
};


export type MutationCompanyArgs = {
  slug: Scalars['String'];
};


export type MutationPhraseArgs = {
  slug: Scalars['String'];
  companySlug: Scalars['String'];
};


export type MutationLinkArgs = {
  immReference: Scalars['String'];
  companySlug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type MutationUserArgs = {
  login: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  Me?: Maybe<User>;
  Users: Array<User>;
  User?: Maybe<User>;
  Companies: Companies;
  Company?: Maybe<Company>;
  Phrase?: Maybe<Phrase>;
  LinkDetails?: Maybe<LinkDetails>;
  Link?: Maybe<LinkStats>;
  Jobs: Array<Job>;
  Media?: Maybe<Media>;
  Articles: Array<Article>;
};


export type QueryUserArgs = {
  login: Scalars['String'];
};


export type QueryCompanyArgs = {
  slug: Scalars['String'];
};


export type QueryPhraseArgs = {
  slug: Scalars['String'];
};


export type QueryLinkDetailsArgs = {
  url: Scalars['String'];
};


export type QueryArticlesArgs = {
  company?: Maybe<Scalars['String']>;
};

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { Companies: (
    { __typename?: 'Companies' }
    & { list: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'slug'>
    )> }
  ) }
);

export type GetThesisQueryVariables = Exact<{
  companySlug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetThesisQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { theses: Array<(
      { __typename?: 'Thesis' }
      & Pick<Thesis, 'text' | 'slug' | 'companySlug'>
    )>, thesesStats: Array<(
      { __typename?: 'ThesisStats' }
      & Pick<ThesisStats, 'slug' | 'direct' | 'indirect'>
    )> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  login: Scalars['String'];
  update: UserUpdateParams;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { User?: Maybe<(
    { __typename?: 'UserOps' }
    & { update: (
      { __typename?: 'UserUpdateResponse' }
      & Pick<UserUpdateResponse, 'success'>
    ) }
  )> }
);

export type GetArticleQueryVariables = Exact<{
  id: Scalars['ID'];
  company: Scalars['String'];
}>;


export type GetArticleQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { article: (
      { __typename?: 'ArticleStats' }
      & Pick<ArticleStats, 'id' | 'author' | 'title' | 'date'>
    ) }
  )> }
);

export type GetArticlesQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetArticlesQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { articles: Array<(
        { __typename?: 'Article' }
        & Pick<Article, 'id' | 'author' | 'title' | 'date' | 'ave' | 'reach' | 'linksCount'>
        & {
      keywords: Array<Maybe<(
          { __typename?: 'ArticleKeywordsStats' }
          & Pick<ArticleKeywordsStats, 'slug' | 'text' | 'estimatedReads'>
          )>>
    }
    )> }
  )> }
);

export type GetKeywordsForArticleQueryVariables = Exact<{
  slug: Scalars['String'];
  articleId: Scalars['ID'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetKeywordsForArticleQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { article: (
      { __typename?: 'ArticleStats' }
      & Pick<ArticleStats, 'id'>
      & { keywords: Array<Maybe<(
        { __typename?: 'ArticleKeywordsStats' }
        & Pick<ArticleKeywordsStats, 'text' | 'slug' | 'estimatedReads'>
      )>> }
    ) }
  )> }
);

export type GetPhrasesStatsQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetPhrasesStatsQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { phrasesStats: Array<(
      { __typename?: 'PhraseStats' }
      & Pick<PhraseStats, 'slug' | 'estimatedReads'>
    )> }
  )> }
);

export type GetArticleAveQueryVariables = Exact<{
  slug: Scalars['String'];
  articleId: Scalars['ID'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetArticleAveQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { article: (
      { __typename?: 'ArticleStats' }
      & Pick<ArticleStats, 'ave'>
    ) }
  )> }
);

export type GetArticleReachQueryVariables = Exact<{
  slug: Scalars['String'];
  articleId: Scalars['ID'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetArticleReachQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { article: (
      { __typename?: 'ArticleStats' }
      & Pick<ArticleStats, 'reach'>
    ) }
  )> }
);

export type GetArticleEstimatedReadsQueryVariables = Exact<{
  slug: Scalars['String'];
  articleId: Scalars['ID'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetArticleEstimatedReadsQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { article: (
      { __typename?: 'ArticleStats' }
      & Pick<ArticleStats, 'reads'>
    ) }
  )> }
);

export type GetLinksForArticleQueryVariables = Exact<{
  slug: Scalars['String'];
  articleId: Scalars['ID'];
}>;


export type GetLinksForArticleQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { article: (
      { __typename?: 'ArticleStats' }
      & Pick<ArticleStats, 'id' | 'title'>
      & { links: Array<Maybe<(
        { __typename?: 'TopLink' }
        & Pick<TopLink, 'url' | 'clicks' | 'ave'>
      )>> }
    ) }
  )> }
);

export type AddArticleMutationVariables = Exact<{
  slug: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  author: Scalars['String'];
  date: Scalars['Date'];
}>;


export type AddArticleMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
      { __typename?: 'CompanyOps' }
      & {
    article: (
        { __typename?: 'ArticleOps' }
        & {
      add: (
          { __typename?: 'Response' }
          & Pick<Response, 'success'>
          )
    }
        )
  }
      )>
}
    );

export type RemoveArticleMutationVariables = Exact<{
  slug: Scalars['String'];
  articleId: Scalars['ID'];
}>;


export type RemoveArticleMutation = (
    { __typename?: 'Mutation' }
    & {
  Company?: Maybe<(
      { __typename?: 'CompanyOps' }
      & {
    article: (
        { __typename?: 'ArticleOps' }
        & {
      remove: (
          { __typename?: 'Response' }
          & Pick<Response, 'success'>
          )
    }
        )
  }
      )>
}
    );

export type CompanyCommonFragment = (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'slug' | 'status'>
    );

export type PhraseCommonFragment = (
    { __typename?: 'Phrase' }
    & Pick<Phrase, 'text' | 'slug' | 'potential' | 'theses'>
    & {
  potentials: Array<(
    { __typename?: 'Potential' }
    & Pick<Potential, 'date' | 'value'>
  )> }
);

export type GetCompanyDetailsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetCompanyDetailsQuery = (
    { __typename?: 'Query' }
    & {
  Company?: Maybe<(
      { __typename?: 'Company' }
      & CompanyCommonFragment
      )>
}
    );

export type DeactivateCompanyMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type DeactivateCompanyMutation = (
    { __typename?: 'Mutation' }
    & {
  Company?: Maybe<(
      { __typename?: 'CompanyOps' }
      & {
    deactivate: (
        { __typename?: 'Response' }
        & Pick<Response, 'success'>
        )
  }
      )>
}
    );

export type ActivateCompanyMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ActivateCompanyMutation = (
    { __typename?: 'Mutation' }
    & {
  Company?: Maybe<(
      { __typename?: 'CompanyOps' }
      & {
    activate: (
        { __typename?: 'Response' }
        & Pick<Response, 'success'>
        )
  }
      )>
}
    );

export type GetPhrasesAndThesisQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
  listSlug?: Maybe<Scalars['String']>;
}>;


export type GetPhrasesAndThesisQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { theses: Array<(
      { __typename?: 'Thesis' }
      & Pick<Thesis, 'text' | 'slug'>
    )>, phrases: Array<(
      { __typename?: 'Phrase' }
      & PhraseCommonFragment
    )>, phrasesStats: Array<(
      { __typename?: 'PhraseStats' }
      & Pick<PhraseStats, 'slug' | 'ctr' | 'positions' | 'estimatedReads'>
    )> }
    & CompanyCommonFragment
  )> }
);

export type GetCompanyPhrasesAndThesisQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type GetCompanyPhrasesAndThesisQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { news: Array<(
      { __typename?: 'News' }
      & Pick<News, 'phrase' | 'phraseSlug'>
    )> }
    & CompanyCommonFragment
  )> }
);

export type GetNewsQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type GetNewsQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { news: Array<(
      { __typename?: 'News' }
      & Pick<News, 'phrase' | 'phraseSlug'>
      & { scans: Array<Maybe<(
        { __typename?: 'Scan' }
        & Pick<Scan, 'date' | 'screenShot' | 'urls'>
      )>> }
    )> }
  )> }
);

export type GetStatsQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type GetStatsQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'ave' | 'contentMass' | 'potential' | 'reads'>
      & { contentMassDetails: Array<Maybe<(
        { __typename?: 'ContentMassDetails' }
        & Pick<ContentMassDetails, 'count' | 'date'>
      )>> }
    ) }
  )> }
);

export type GetAveQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetAveQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'id' | 'ave'>
    ) }
  )> }
);

export type GetReachQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetReachQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'id' | 'reach'>
    ) }
  )> }
);

export type GetContentMassQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetContentMassQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'id' | 'contentMass'>
    ) }
  )> }
);

export type GetTopLinksQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetTopLinksQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'id'>
      & { topLinks: Array<Maybe<(
        { __typename?: 'TopLink' }
        & Pick<TopLink, 'url' | 'clicks'>
      )>> }
    ) }
  )> }
);

export type GetTopArticlesQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetTopArticlesQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'id'>
      & { topArticles: Array<Maybe<(
        { __typename?: 'TopArticle' }
        & Pick<TopArticle, 'articleId' | 'title' | 'clicks'>
      )>> }
    ) }
  )> }
);

export type GetTopMediaQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetTopMediaQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'id'>
      & { topMedia: Array<Maybe<(
        { __typename?: 'TopMedia' }
        & Pick<TopMedia, 'clicks' | 'domain' | 'links'>
      )>> }
    ) }
  )> }
);

export type GetEstimatedReadsQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetEstimatedReadsQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'id' | 'reads'>
    ) }
  )> }
);

export type GetContentMassPlotQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  list?: Maybe<Scalars['String']>;
  filter?: Maybe<DashboardQuery>;
}>;


export type GetContentMassPlotQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { stats: (
      { __typename?: 'CompanyStats' }
      & Pick<CompanyStats, 'id'>
      & { contentMassDetails: Array<Maybe<(
        { __typename?: 'ContentMassDetails' }
        & Pick<ContentMassDetails, 'count' | 'date'>
      )>> }
    ) }
  )> }
);

export type GetLinksQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetLinksQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { links: Array<(
        { __typename?: 'Link' }
        & Pick<Link, 'id' | 'link' | 'immReference' | 'active' | 'sources' | 'editable' | 'theses' | 'date' | 'status'>
        & {
      ave?: Maybe<Array<Maybe<(
          { __typename?: 'Ave' }
          & Pick<Ave, 'date' | 'value'>
          )>>>, reach?: Maybe<Array<Maybe<(
          { __typename?: 'Reach' }
          & Pick<Reach, 'date' | 'value'>
          )>>>, article?: Maybe<(
          { __typename?: 'Article' }
          & Pick<Article, 'id' | 'title'>
          )>
    }
    )> }
  )> }
);

export type GetPhraseQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetPhraseQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { phrases: Array<(
      { __typename?: 'Phrase' }
      & Pick<Phrase, 'text' | 'slug' | 'potential' | 'theses'>
    )>, phrasesStats: Array<(
      { __typename?: 'PhraseStats' }
      & Pick<PhraseStats, 'slug' | 'ctr' | 'positions' | 'estimatedReads'>
    )> }
  )> }
);

export type GetPhraseListQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetPhraseListQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { favourite: Array<(
      { __typename?: 'Phrase' }
      & PhraseCommonFragment
    )> }
    & CompanyCommonFragment
  )> }
);

export type GetCompanyPhraseListsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetCompanyPhraseListsQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { phraseLists: Array<Maybe<(
      { __typename?: 'PhraseList' }
      & Pick<PhraseList, 'id' | 'name' | 'slug'>
    )>>, mediaLists: Array<Maybe<(
      { __typename?: 'MediaList' }
      & Pick<MediaList, 'id' | 'name' | 'slug'>
    )>> }
  )> }
);

export type GetSimilarQuestionQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetSimilarQuestionQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { stats: (
      { __typename?: 'CompanyStats' }
      & { similarQuestions: Array<Maybe<(
        { __typename?: 'SimilarQuestions' }
        & Pick<SimilarQuestions, 'link' | 'phrase' | 'question'>
      )>> }
    ) }
  )> }
);

export type GetLastPublicationsQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<DashboardQuery>;
}>;


export type GetLastPublicationsQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { stats: (
      { __typename?: 'CompanyStats' }
      & { lastPublications: Array<Maybe<(
        { __typename?: 'LastPublications' }
        & Pick<LastPublications, 'title' | 'articleId' | 'publicationsNumber'>
      )>> }
    ) }
  )> }
);

export type LinksSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type LinksSubscription = (
  { __typename?: 'Subscription' }
  & { linkUpdated: (
    { __typename?: 'Link' }
    & Pick<Link, 'id' | 'active' | 'editable' | 'immReference' | 'link' | 'status' | 'theses'>
  ) }
);

export type GetLogsForCompanyQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type GetLogsForCompanyQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'slug'>
    & { log: Array<Maybe<(
      { __typename?: 'EventLog' }
      & Pick<EventLog, 'topic' | 'message' | 'change'>
    )>> }
  )> }
);

export type CancelJobMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelJobMutation = (
  { __typename?: 'Mutation' }
  & { Job?: Maybe<(
    { __typename?: 'JobOps' }
    & { cancel: (
      { __typename?: 'StatusResponse' }
      & Pick<StatusResponse, 'status' | 'error'>
    ) }
  )> }
);

export type GetJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJobsQuery = (
  { __typename?: 'Query' }
  & { Jobs: Array<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'state' | 'started' | 'updated' | 'created' | 'type' | 'finished' | 'cancelledAt'>
    & { stats?: Maybe<(
      { __typename?: 'JobStats' }
      & Pick<JobStats, 'error' | 'done' | 'doing' | 'waiting' | 'all'>
    )> }
  )> }
);

export type TriggerJobMutationVariables = Exact<{
  type: Scalars['String'];
}>;


export type TriggerJobMutation = (
  { __typename?: 'Mutation' }
  & { Job?: Maybe<(
    { __typename?: 'JobOps' }
    & { trigger: (
      { __typename?: 'StatusResponse' }
      & Pick<StatusResponse, 'status' | 'error'>
    ) }
  )> }
);

export type AddLinkMutationVariables = Exact<{
  company: Scalars['String'];
  links: Array<Scalars['String']>;
}>;


export type AddLinkMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { addLink: (
      { __typename?: 'AddLinkResponse' }
      & Pick<AddLinkResponse, 'success'>
    ) }
  )> }
);

export type UpdateLinkMutationVariables = Exact<{
  link: Scalars['String'];
  params: LinkUpdateParams;
}>;


export type UpdateLinkMutation = (
  { __typename?: 'Mutation' }
  & { Link?: Maybe<(
    { __typename?: 'LinkOps' }
    & { update: (
      { __typename?: 'LinkUpdateResponse' }
      & Pick<LinkUpdateResponse, 'success'>
    ) }
  )> }
);

export type AssignArticleToLinkMutationVariables = Exact<{
  link: Scalars['String'];
  article?: Maybe<Scalars['ID']>;
  linkId?: Maybe<Scalars['ID']>;
  companySlug?: Maybe<Scalars['String']>;
}>;


export type AssignArticleToLinkMutation = (
  { __typename?: 'Mutation' }
  & { Link?: Maybe<(
    { __typename?: 'LinkOps' }
    & { assignArticle: (
      { __typename?: 'LinkUpdateResponse' }
      & Pick<LinkUpdateResponse, 'success'>
    ) }
  )> }
);

export type GetLinkDetailsQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type GetLinkDetailsQuery = (
  { __typename?: 'Query' }
  & { LinkDetails?: Maybe<(
    { __typename?: 'LinkDetails' }
    & Pick<LinkDetails, 'id' | 'url' | 'finalUrl' | 'finalStatus' | 'date'>
    & { document?: Maybe<(
      { __typename?: 'LinkDetailsDocument' }
      & Pick<LinkDetailsDocument, 'id' | 'title' | 'canonical'>
      & { meta?: Maybe<(
        { __typename?: 'LinkDetailsDocumentMeta' }
        & Pick<LinkDetailsDocumentMeta, 'id' | 'robots'>
      )> }
    )>, requests: Array<Maybe<(
      { __typename?: 'LinkDetailsRequests' }
      & Pick<LinkDetailsRequests, 'id' | 'redirected' | 'status'>
      & { headers?: Maybe<(
        { __typename?: 'LinkDetailsRequestsHeaders' }
        & Pick<LinkDetailsRequestsHeaders, 'location'>
      )> }
    )>> }
  )> }
);

export type CheckLinkMutationVariables = Exact<{
  url: Scalars['String'];
}>;


export type CheckLinkMutation = (
  { __typename?: 'Mutation' }
  & { LinkDetails?: Maybe<(
    { __typename?: 'LinkDetailsOps' }
    & { check: (
      { __typename?: 'StatusResponse' }
      & Pick<StatusResponse, 'status'>
    ) }
  )> }
);

export type LinkToPhrasesQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type LinkToPhrasesQuery = (
  { __typename?: 'Query' }
  & { Link?: Maybe<(
    { __typename?: 'LinkStats' }
    & Pick<LinkStats, 'isInUse'>
    & { phrases: Array<Maybe<(
      { __typename?: 'Phrase' }
      & Pick<Phrase, 'text'>
    )>> }
  )> }
);

export type GetSuggesterArticlesQueryVariables = Exact<{
  slug: Scalars['String'];
  linkId: Scalars['ID'];
}>;


export type GetSuggesterArticlesQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { suggestedArticles: Array<(
      { __typename?: 'SuggestedArticle' }
      & Pick<SuggestedArticle, 'id' | 'title'>
    )> }
  )> }
);

export type GetMediaSuggestionsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type GetMediaSuggestionsQuery = (
  { __typename?: 'Query' }
  & { Media?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'autocomplete'>
  )> }
);

export type AddToMediaListMutationVariables = Exact<{
  slug: Scalars['String'];
  domain: Scalars['String'];
  listSlug: Scalars['String'];
}>;


export type AddToMediaListMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { addDomainToList: (
      { __typename?: 'Response' }
      & Pick<Response, 'success'>
    ) }
  )> }
);

export type RemoveFromMediaListMutationVariables = Exact<{
  slug: Scalars['String'];
  domain: Scalars['String'];
  listSlug: Scalars['String'];
}>;


export type RemoveFromMediaListMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { removeDomainToList: (
      { __typename?: 'Response' }
      & Pick<Response, 'success'>
    ) }
  )> }
);

export type GetMediaListQueryVariables = Exact<{
  companySlug: Scalars['String'];
  listSlug: Scalars['String'];
}>;


export type GetMediaListQuery = (
  { __typename?: 'Query' }
  & { Company?: Maybe<(
    { __typename?: 'Company' }
    & { media?: Maybe<(
      { __typename?: 'CompanyMedia' }
      & { list: Array<Maybe<(
        { __typename?: 'Medium' }
        & Pick<Medium, 'id' | 'domain'>
      )>> }
    )> }
  )> }
);

export type CreateMediaListMutationVariables = Exact<{
  slug: Scalars['String'];
  listName: Scalars['String'];
}>;


export type CreateMediaListMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { mediaLists: (
      { __typename?: 'MediaListOps' }
      & { add: (
        { __typename?: 'Response' }
        & Pick<Response, 'success'>
      ) }
    ) }
  )> }
);

export type AddPhraseMutationVariables = Exact<{
  companySlug: Scalars['String'];
  phraseText: Array<Scalars['String']>;
  listSlug?: Maybe<Scalars['String']>;
}>;


export type AddPhraseMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { addPhrase: (
      { __typename?: 'AddPhraseResponse' }
      & Pick<AddPhraseResponse, 'success'>
    ) }
  )> }
);

export type GetScansQueryVariables = Exact<{
  slug: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  companies: Array<Scalars['String']>;
}>;


export type GetScansQuery = (
  { __typename?: 'Query' }
  & { Phrase?: Maybe<(
    { __typename?: 'Phrase' }
    & Pick<Phrase, 'text'>
    & { scans: Array<(
      { __typename?: 'Scan' }
      & Pick<Scan, 'date' | 'urls' | 'marked'>
    )> }
  )> }
);

export type UpdatePhrasePotentialMutationVariables = Exact<{
  companySlug: Scalars['String'];
  phraseSlug: Scalars['String'];
  potential: Scalars['Int'];
}>;


export type UpdatePhrasePotentialMutation = (
  { __typename?: 'Mutation' }
  & { Phrase?: Maybe<(
    { __typename?: 'PhraseOps' }
    & { update: (
      { __typename?: 'UpdatePhraseResponse' }
      & Pick<UpdatePhraseResponse, 'success'>
    ) }
  )> }
);

export type AddPhraseToListMutationVariables = Exact<{
  slug: Scalars['String'];
  phrase: Scalars['String'];
  list: Scalars['String'];
}>;


export type AddPhraseToListMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { addPhraseToList: (
      { __typename?: 'Response' }
      & Pick<Response, 'success'>
    ) }
  )> }
);

export type RemovePhraseFromListMutationVariables = Exact<{
  slug: Scalars['String'];
  phrase: Scalars['String'];
  list: Scalars['String'];
}>;


export type RemovePhraseFromListMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { removePhraseToList: (
      { __typename?: 'Response' }
      & Pick<Response, 'success'>
    ) }
  )> }
);

export type RemovePhraseMutationVariables = Exact<{
  slug: Scalars['String'];
  phrase: Scalars['String'];
}>;


export type RemovePhraseMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { removePhrase: (
      { __typename?: 'Response' }
      & Pick<Response, 'success'>
    ) }
  )> }
);

export type CreatePhraseListMutationVariables = Exact<{
  slug: Scalars['String'];
  listName: Scalars['String'];
}>;


export type CreatePhraseListMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { phraseLists: (
      { __typename?: 'PhraseListsOps' }
      & { add: (
        { __typename?: 'Response' }
        & Pick<Response, 'success'>
      ) }
    ) }
  )> }
);

export type DeletePhraseListMutationVariables = Exact<{
  slug: Scalars['String'];
  listSlug: Scalars['String'];
}>;


export type DeletePhraseListMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { phraseLists: (
      { __typename?: 'PhraseListsOps' }
      & { remove: (
        { __typename?: 'Response' }
        & Pick<Response, 'success'>
      ) }
    ) }
  )> }
);

export type AssignPhraseMutationVariables = Exact<{
  companySlug: Scalars['String'];
  listSlug: Scalars['String'];
  phraseSlug: Array<Maybe<Scalars['String']>>;
}>;


export type AssignPhraseMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { phraseLists: (
      { __typename?: 'PhraseListsOps' }
      & { assign: (
        { __typename?: 'Response' }
        & Pick<Response, 'success'>
      ) }
    ) }
  )> }
);

export type UnassignPhraseMutationVariables = Exact<{
  slug: Scalars['String'];
  phraseSlug: Scalars['String'];
  listSlug: Scalars['String'];
}>;


export type UnassignPhraseMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { phraseLists: (
      { __typename?: 'PhraseListsOps' }
      & { unassign: (
        { __typename?: 'Response' }
        & Pick<Response, 'success'>
      ) }
    ) }
  )> }
);

export type DeleteThesisMutationVariables = Exact<{
  company: Scalars['String'];
  thesis: Scalars['String'];
}>;


export type DeleteThesisMutation = (
  { __typename?: 'Mutation' }
  & { Company?: Maybe<(
    { __typename?: 'CompanyOps' }
    & { removeThesis: (
      { __typename?: 'RemoveThesisResponse' }
      & Pick<RemoveThesisResponse, 'success'>
    ) }
  )> }
);

export type GetUserQueryVariables = Exact<{
  login: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { User?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'login' | 'type' | 'companiesSlugs'>
  )> }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { Users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'login' | 'type'>
  )> }
);

export const CompanyCommonFragmentDoc = gql`
  fragment CompanyCommon on Company {
    id
    name
    slug
    status
  }
`;
export const PhraseCommonFragmentDoc = gql`
  fragment PhraseCommon on Phrase {
    text
    slug
    potential
    theses
    potentials {
      date
      value
    }
  }
`;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  Companies {
    list {
      name
      slug
    }
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetThesisDocument = gql`
    query GetThesis($companySlug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $companySlug) {
    id
    theses {
      text
      slug
      companySlug
    }
    thesesStats(from: $from, to: $to, filter: $filter) {
      slug
      direct
      indirect
    }
  }
}
    `;

/**
 * __useGetThesisQuery__
 *
 * To run a query within a React component, call `useGetThesisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThesisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThesisQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetThesisQuery(baseOptions?: Apollo.QueryHookOptions<GetThesisQuery, GetThesisQueryVariables>) {
        return Apollo.useQuery<GetThesisQuery, GetThesisQueryVariables>(GetThesisDocument, baseOptions);
      }
export function useGetThesisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThesisQuery, GetThesisQueryVariables>) {
          return Apollo.useLazyQuery<GetThesisQuery, GetThesisQueryVariables>(GetThesisDocument, baseOptions);
        }
export type GetThesisQueryHookResult = ReturnType<typeof useGetThesisQuery>;
export type GetThesisLazyQueryHookResult = ReturnType<typeof useGetThesisLazyQuery>;
export type GetThesisQueryResult = Apollo.QueryResult<GetThesisQuery, GetThesisQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($login: String!, $update: UserUpdateParams!) {
  User(login: $login) {
    update(update: $update) {
      success
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      login: // value for 'login'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetArticleDocument = gql`
    query GetArticle($id: ID!, $company: String!) {
  Company(slug: $company) {
    article(id: $id) {
      id
      author
      title
      date
    }
  }
}
    `;

/**
 * __useGetArticleQuery__
 *
 * To run a query within a React component, call `useGetArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useGetArticleQuery(baseOptions?: Apollo.QueryHookOptions<GetArticleQuery, GetArticleQueryVariables>) {
        return Apollo.useQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, baseOptions);
      }
export function useGetArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleQuery, GetArticleQueryVariables>) {
          return Apollo.useLazyQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, baseOptions);
        }
export type GetArticleQueryHookResult = ReturnType<typeof useGetArticleQuery>;
export type GetArticleLazyQueryHookResult = ReturnType<typeof useGetArticleLazyQuery>;
export type GetArticleQueryResult = Apollo.QueryResult<GetArticleQuery, GetArticleQueryVariables>;
export const GetArticlesDocument = gql`
  query GetArticles($slug: String!) {
    Company(slug: $slug) {
      articles {
        id
        author
        title
        date
        ave
        reach
        linksCount
        keywords {
          slug
          text
          estimatedReads
        }
      }
    }
  }
`;

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetArticlesQuery(baseOptions?: Apollo.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
        return Apollo.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, baseOptions);
      }
export function useGetArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
          return Apollo.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, baseOptions);
        }
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>;
export type GetArticlesQueryResult = Apollo.QueryResult<GetArticlesQuery, GetArticlesQueryVariables>;
export const GetKeywordsForArticleDocument = gql`
    query GetKeywordsForArticle($slug: String!, $articleId: ID!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    article(id: $articleId, filter: $filter) {
      id
      keywords {
        text
        slug
        estimatedReads
      }
    }
  }
}
    `;

/**
 * __useGetKeywordsForArticleQuery__
 *
 * To run a query within a React component, call `useGetKeywordsForArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordsForArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordsForArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      articleId: // value for 'articleId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetKeywordsForArticleQuery(baseOptions?: Apollo.QueryHookOptions<GetKeywordsForArticleQuery, GetKeywordsForArticleQueryVariables>) {
        return Apollo.useQuery<GetKeywordsForArticleQuery, GetKeywordsForArticleQueryVariables>(GetKeywordsForArticleDocument, baseOptions);
      }
export function useGetKeywordsForArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeywordsForArticleQuery, GetKeywordsForArticleQueryVariables>) {
          return Apollo.useLazyQuery<GetKeywordsForArticleQuery, GetKeywordsForArticleQueryVariables>(GetKeywordsForArticleDocument, baseOptions);
        }
export type GetKeywordsForArticleQueryHookResult = ReturnType<typeof useGetKeywordsForArticleQuery>;
export type GetKeywordsForArticleLazyQueryHookResult = ReturnType<typeof useGetKeywordsForArticleLazyQuery>;
export type GetKeywordsForArticleQueryResult = Apollo.QueryResult<GetKeywordsForArticleQuery, GetKeywordsForArticleQueryVariables>;
export const GetPhrasesStatsDocument = gql`
    query GetPhrasesStats($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    phrasesStats(from: $from, to: $to, filter: $filter) {
      slug
      estimatedReads
    }
  }
}
    `;

/**
 * __useGetPhrasesStatsQuery__
 *
 * To run a query within a React component, call `useGetPhrasesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhrasesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhrasesStatsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPhrasesStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetPhrasesStatsQuery, GetPhrasesStatsQueryVariables>) {
        return Apollo.useQuery<GetPhrasesStatsQuery, GetPhrasesStatsQueryVariables>(GetPhrasesStatsDocument, baseOptions);
      }
export function useGetPhrasesStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhrasesStatsQuery, GetPhrasesStatsQueryVariables>) {
          return Apollo.useLazyQuery<GetPhrasesStatsQuery, GetPhrasesStatsQueryVariables>(GetPhrasesStatsDocument, baseOptions);
        }
export type GetPhrasesStatsQueryHookResult = ReturnType<typeof useGetPhrasesStatsQuery>;
export type GetPhrasesStatsLazyQueryHookResult = ReturnType<typeof useGetPhrasesStatsLazyQuery>;
export type GetPhrasesStatsQueryResult = Apollo.QueryResult<GetPhrasesStatsQuery, GetPhrasesStatsQueryVariables>;
export const GetArticleAveDocument = gql`
    query GetArticleAve($slug: String!, $articleId: ID!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    article(id: $articleId, filter: $filter) {
      ave
    }
  }
}
    `;

/**
 * __useGetArticleAveQuery__
 *
 * To run a query within a React component, call `useGetArticleAveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleAveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleAveQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      articleId: // value for 'articleId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetArticleAveQuery(baseOptions?: Apollo.QueryHookOptions<GetArticleAveQuery, GetArticleAveQueryVariables>) {
        return Apollo.useQuery<GetArticleAveQuery, GetArticleAveQueryVariables>(GetArticleAveDocument, baseOptions);
      }
export function useGetArticleAveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleAveQuery, GetArticleAveQueryVariables>) {
          return Apollo.useLazyQuery<GetArticleAveQuery, GetArticleAveQueryVariables>(GetArticleAveDocument, baseOptions);
        }
export type GetArticleAveQueryHookResult = ReturnType<typeof useGetArticleAveQuery>;
export type GetArticleAveLazyQueryHookResult = ReturnType<typeof useGetArticleAveLazyQuery>;
export type GetArticleAveQueryResult = Apollo.QueryResult<GetArticleAveQuery, GetArticleAveQueryVariables>;
export const GetArticleReachDocument = gql`
    query GetArticleReach($slug: String!, $articleId: ID!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    article(id: $articleId, filter: $filter) {
      reach
    }
  }
}
    `;

/**
 * __useGetArticleReachQuery__
 *
 * To run a query within a React component, call `useGetArticleReachQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleReachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleReachQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      articleId: // value for 'articleId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetArticleReachQuery(baseOptions?: Apollo.QueryHookOptions<GetArticleReachQuery, GetArticleReachQueryVariables>) {
        return Apollo.useQuery<GetArticleReachQuery, GetArticleReachQueryVariables>(GetArticleReachDocument, baseOptions);
      }
export function useGetArticleReachLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleReachQuery, GetArticleReachQueryVariables>) {
          return Apollo.useLazyQuery<GetArticleReachQuery, GetArticleReachQueryVariables>(GetArticleReachDocument, baseOptions);
        }
export type GetArticleReachQueryHookResult = ReturnType<typeof useGetArticleReachQuery>;
export type GetArticleReachLazyQueryHookResult = ReturnType<typeof useGetArticleReachLazyQuery>;
export type GetArticleReachQueryResult = Apollo.QueryResult<GetArticleReachQuery, GetArticleReachQueryVariables>;
export const GetArticleEstimatedReadsDocument = gql`
    query GetArticleEstimatedReads($slug: String!, $articleId: ID!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    article(id: $articleId, filter: $filter) {
      reads
    }
  }
}
    `;

/**
 * __useGetArticleEstimatedReadsQuery__
 *
 * To run a query within a React component, call `useGetArticleEstimatedReadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleEstimatedReadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleEstimatedReadsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      articleId: // value for 'articleId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetArticleEstimatedReadsQuery(baseOptions?: Apollo.QueryHookOptions<GetArticleEstimatedReadsQuery, GetArticleEstimatedReadsQueryVariables>) {
        return Apollo.useQuery<GetArticleEstimatedReadsQuery, GetArticleEstimatedReadsQueryVariables>(GetArticleEstimatedReadsDocument, baseOptions);
      }
export function useGetArticleEstimatedReadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleEstimatedReadsQuery, GetArticleEstimatedReadsQueryVariables>) {
          return Apollo.useLazyQuery<GetArticleEstimatedReadsQuery, GetArticleEstimatedReadsQueryVariables>(GetArticleEstimatedReadsDocument, baseOptions);
        }
export type GetArticleEstimatedReadsQueryHookResult = ReturnType<typeof useGetArticleEstimatedReadsQuery>;
export type GetArticleEstimatedReadsLazyQueryHookResult = ReturnType<typeof useGetArticleEstimatedReadsLazyQuery>;
export type GetArticleEstimatedReadsQueryResult = Apollo.QueryResult<GetArticleEstimatedReadsQuery, GetArticleEstimatedReadsQueryVariables>;
export const GetLinksForArticleDocument = gql`
    query GetLinksForArticle($slug: String!, $articleId: ID!) {
  Company(slug: $slug) {
    article(id: $articleId) {
      id
      title
      links {
        url
        clicks
        ave
      }
    }
  }
}
    `;

/**
 * __useGetLinksForArticleQuery__
 *
 * To run a query within a React component, call `useGetLinksForArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinksForArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinksForArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useGetLinksForArticleQuery(baseOptions?: Apollo.QueryHookOptions<GetLinksForArticleQuery, GetLinksForArticleQueryVariables>) {
        return Apollo.useQuery<GetLinksForArticleQuery, GetLinksForArticleQueryVariables>(GetLinksForArticleDocument, baseOptions);
      }
export function useGetLinksForArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinksForArticleQuery, GetLinksForArticleQueryVariables>) {
          return Apollo.useLazyQuery<GetLinksForArticleQuery, GetLinksForArticleQueryVariables>(GetLinksForArticleDocument, baseOptions);
        }
export type GetLinksForArticleQueryHookResult = ReturnType<typeof useGetLinksForArticleQuery>;
export type GetLinksForArticleLazyQueryHookResult = ReturnType<typeof useGetLinksForArticleLazyQuery>;
export type GetLinksForArticleQueryResult = Apollo.QueryResult<GetLinksForArticleQuery, GetLinksForArticleQueryVariables>;
export const AddArticleDocument = gql`
  mutation AddArticle($slug: String!, $title: String!, $content: String!, $author: String!, $date: Date!) {
    Company(slug: $slug) {
      article {
        add(title: $title, content: $content, author: $author, date: $date) {
          success
        }
      }
    }
  }
`;
export type AddArticleMutationFn = Apollo.MutationFunction<AddArticleMutation, AddArticleMutationVariables>;

/**
 * __useAddArticleMutation__
 *
 * To run a mutation, you first call `useAddArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArticleMutation, { data, loading, error }] = useAddArticleMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      author: // value for 'author'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAddArticleMutation(baseOptions?: Apollo.MutationHookOptions<AddArticleMutation, AddArticleMutationVariables>) {
  return Apollo.useMutation<AddArticleMutation, AddArticleMutationVariables>(AddArticleDocument, baseOptions);
}

export type AddArticleMutationHookResult = ReturnType<typeof useAddArticleMutation>;
export type AddArticleMutationResult = Apollo.MutationResult<AddArticleMutation>;
export type AddArticleMutationOptions = Apollo.BaseMutationOptions<AddArticleMutation, AddArticleMutationVariables>;
export const RemoveArticleDocument = gql`
  mutation RemoveArticle($slug: String!, $articleId: ID!) {
    Company(slug: $slug) {
      article {
        remove(articleId: $articleId) {
          success
        }
      }
    }
  }
`;
export type RemoveArticleMutationFn = Apollo.MutationFunction<RemoveArticleMutation, RemoveArticleMutationVariables>;

/**
 * __useRemoveArticleMutation__
 *
 * To run a mutation, you first call `useRemoveArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArticleMutation, { data, loading, error }] = useRemoveArticleMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useRemoveArticleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveArticleMutation, RemoveArticleMutationVariables>) {
  return Apollo.useMutation<RemoveArticleMutation, RemoveArticleMutationVariables>(RemoveArticleDocument, baseOptions);
}

export type RemoveArticleMutationHookResult = ReturnType<typeof useRemoveArticleMutation>;
export type RemoveArticleMutationResult = Apollo.MutationResult<RemoveArticleMutation>;
export type RemoveArticleMutationOptions = Apollo.BaseMutationOptions<RemoveArticleMutation, RemoveArticleMutationVariables>;
export const GetCompanyDetailsDocument = gql`
  query GetCompanyDetails($slug: String!) {
    Company(slug: $slug) {
      ...CompanyCommon
    }
  }
${CompanyCommonFragmentDoc}`;

/**
 * __useGetCompanyDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCompanyDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>) {
  return Apollo.useQuery<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>(GetCompanyDetailsDocument, baseOptions);
}
export function useGetCompanyDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>) {
  return Apollo.useLazyQuery<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>(GetCompanyDetailsDocument, baseOptions);
}
export type GetCompanyDetailsQueryHookResult = ReturnType<typeof useGetCompanyDetailsQuery>;
export type GetCompanyDetailsLazyQueryHookResult = ReturnType<typeof useGetCompanyDetailsLazyQuery>;
export type GetCompanyDetailsQueryResult = Apollo.QueryResult<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>;
export const DeactivateCompanyDocument = gql`
  mutation DeactivateCompany($slug: String!) {
    Company(slug: $slug) {
      deactivate {
        success
      }
    }
  }
`;
export type DeactivateCompanyMutationFn = Apollo.MutationFunction<DeactivateCompanyMutation, DeactivateCompanyMutationVariables>;

/**
 * __useDeactivateCompanyMutation__
 *
 * To run a mutation, you first call `useDeactivateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCompanyMutation, { data, loading, error }] = useDeactivateCompanyMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDeactivateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateCompanyMutation, DeactivateCompanyMutationVariables>) {
  return Apollo.useMutation<DeactivateCompanyMutation, DeactivateCompanyMutationVariables>(DeactivateCompanyDocument, baseOptions);
}
export type DeactivateCompanyMutationHookResult = ReturnType<typeof useDeactivateCompanyMutation>;
export type DeactivateCompanyMutationResult = Apollo.MutationResult<DeactivateCompanyMutation>;
export type DeactivateCompanyMutationOptions = Apollo.BaseMutationOptions<DeactivateCompanyMutation, DeactivateCompanyMutationVariables>;
export const ActivateCompanyDocument = gql`
  mutation ActivateCompany($slug: String!) {
    Company(slug: $slug) {
      activate {
        success
      }
    }
  }
`;
export type ActivateCompanyMutationFn = Apollo.MutationFunction<ActivateCompanyMutation, ActivateCompanyMutationVariables>;

/**
 * __useActivateCompanyMutation__
 *
 * To run a mutation, you first call `useActivateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompanyMutation, { data, loading, error }] = useActivateCompanyMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useActivateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCompanyMutation, ActivateCompanyMutationVariables>) {
  return Apollo.useMutation<ActivateCompanyMutation, ActivateCompanyMutationVariables>(ActivateCompanyDocument, baseOptions);
}
export type ActivateCompanyMutationHookResult = ReturnType<typeof useActivateCompanyMutation>;
export type ActivateCompanyMutationResult = Apollo.MutationResult<ActivateCompanyMutation>;
export type ActivateCompanyMutationOptions = Apollo.BaseMutationOptions<ActivateCompanyMutation, ActivateCompanyMutationVariables>;
export const GetPhrasesAndThesisDocument = gql`
  query GetPhrasesAndThesis($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery, $listSlug: String) {
    Company(slug: $slug) {
      ...CompanyCommon
      theses {
        text
        slug
      }
      phrases(listSlug: $listSlug) {
        ...PhraseCommon
      }
      phrasesStats(from: $from, to: $to, useDbEngine: true, filter: $filter) {
        slug
        ctr
        positions
        estimatedReads
      }
    }
  }
  ${CompanyCommonFragmentDoc}
${PhraseCommonFragmentDoc}`;

/**
 * __useGetPhrasesAndThesisQuery__
 *
 * To run a query within a React component, call `useGetPhrasesAndThesisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhrasesAndThesisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhrasesAndThesisQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *      listSlug: // value for 'listSlug'
 *   },
 * });
 */
export function useGetPhrasesAndThesisQuery(baseOptions?: Apollo.QueryHookOptions<GetPhrasesAndThesisQuery, GetPhrasesAndThesisQueryVariables>) {
        return Apollo.useQuery<GetPhrasesAndThesisQuery, GetPhrasesAndThesisQueryVariables>(GetPhrasesAndThesisDocument, baseOptions);
      }
export function useGetPhrasesAndThesisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhrasesAndThesisQuery, GetPhrasesAndThesisQueryVariables>) {
          return Apollo.useLazyQuery<GetPhrasesAndThesisQuery, GetPhrasesAndThesisQueryVariables>(GetPhrasesAndThesisDocument, baseOptions);
        }
export type GetPhrasesAndThesisQueryHookResult = ReturnType<typeof useGetPhrasesAndThesisQuery>;
export type GetPhrasesAndThesisLazyQueryHookResult = ReturnType<typeof useGetPhrasesAndThesisLazyQuery>;
export type GetPhrasesAndThesisQueryResult = Apollo.QueryResult<GetPhrasesAndThesisQuery, GetPhrasesAndThesisQueryVariables>;
export const GetCompanyPhrasesAndThesisDocument = gql`
    query GetCompanyPhrasesAndThesis($slug: String!, $from: Date!, $to: Date!) {
  Company(slug: $slug) {
    ...CompanyCommon
    news(from: $from, to: $to) {
      phrase
      phraseSlug
    }
  }
}
    ${CompanyCommonFragmentDoc}`;

/**
 * __useGetCompanyPhrasesAndThesisQuery__
 *
 * To run a query within a React component, call `useGetCompanyPhrasesAndThesisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPhrasesAndThesisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPhrasesAndThesisQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetCompanyPhrasesAndThesisQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyPhrasesAndThesisQuery, GetCompanyPhrasesAndThesisQueryVariables>) {
        return Apollo.useQuery<GetCompanyPhrasesAndThesisQuery, GetCompanyPhrasesAndThesisQueryVariables>(GetCompanyPhrasesAndThesisDocument, baseOptions);
      }
export function useGetCompanyPhrasesAndThesisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyPhrasesAndThesisQuery, GetCompanyPhrasesAndThesisQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyPhrasesAndThesisQuery, GetCompanyPhrasesAndThesisQueryVariables>(GetCompanyPhrasesAndThesisDocument, baseOptions);
        }
export type GetCompanyPhrasesAndThesisQueryHookResult = ReturnType<typeof useGetCompanyPhrasesAndThesisQuery>;
export type GetCompanyPhrasesAndThesisLazyQueryHookResult = ReturnType<typeof useGetCompanyPhrasesAndThesisLazyQuery>;
export type GetCompanyPhrasesAndThesisQueryResult = Apollo.QueryResult<GetCompanyPhrasesAndThesisQuery, GetCompanyPhrasesAndThesisQueryVariables>;
export const GetNewsDocument = gql`
    query GetNews($slug: String!, $from: Date!, $to: Date!) {
  Company(slug: $slug) {
    id
    news(from: $from, to: $to) {
      phrase
      phraseSlug
      scans {
        date
        screenShot
        urls
      }
    }
  }
}
    `;

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetNewsQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
        return Apollo.useQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, baseOptions);
      }
export function useGetNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
          return Apollo.useLazyQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, baseOptions);
        }
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsQueryResult = Apollo.QueryResult<GetNewsQuery, GetNewsQueryVariables>;
export const GetStatsDocument = gql`
    query GetStats($slug: String!, $from: Date!, $to: Date!) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to) {
      ave
      contentMass
      potential
      reads
      contentMassDetails {
        count
        date
      }
    }
  }
}
    `;

/**
 * __useGetStatsQuery__
 *
 * To run a query within a React component, call `useGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
        return Apollo.useQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, baseOptions);
      }
export function useGetStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
          return Apollo.useLazyQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, baseOptions);
        }
export type GetStatsQueryHookResult = ReturnType<typeof useGetStatsQuery>;
export type GetStatsLazyQueryHookResult = ReturnType<typeof useGetStatsLazyQuery>;
export type GetStatsQueryResult = Apollo.QueryResult<GetStatsQuery, GetStatsQueryVariables>;
export const GetAveDocument = gql`
    query GetAve($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to, filter: $filter) {
      id
      ave
    }
  }
}
    `;

/**
 * __useGetAveQuery__
 *
 * To run a query within a React component, call `useGetAveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAveQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAveQuery(baseOptions?: Apollo.QueryHookOptions<GetAveQuery, GetAveQueryVariables>) {
        return Apollo.useQuery<GetAveQuery, GetAveQueryVariables>(GetAveDocument, baseOptions);
      }
export function useGetAveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAveQuery, GetAveQueryVariables>) {
          return Apollo.useLazyQuery<GetAveQuery, GetAveQueryVariables>(GetAveDocument, baseOptions);
        }
export type GetAveQueryHookResult = ReturnType<typeof useGetAveQuery>;
export type GetAveLazyQueryHookResult = ReturnType<typeof useGetAveLazyQuery>;
export type GetAveQueryResult = Apollo.QueryResult<GetAveQuery, GetAveQueryVariables>;
export const GetReachDocument = gql`
    query GetReach($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to, filter: $filter) {
      id
      reach
    }
  }
}
    `;

/**
 * __useGetReachQuery__
 *
 * To run a query within a React component, call `useGetReachQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReachQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetReachQuery(baseOptions?: Apollo.QueryHookOptions<GetReachQuery, GetReachQueryVariables>) {
        return Apollo.useQuery<GetReachQuery, GetReachQueryVariables>(GetReachDocument, baseOptions);
      }
export function useGetReachLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReachQuery, GetReachQueryVariables>) {
          return Apollo.useLazyQuery<GetReachQuery, GetReachQueryVariables>(GetReachDocument, baseOptions);
        }
export type GetReachQueryHookResult = ReturnType<typeof useGetReachQuery>;
export type GetReachLazyQueryHookResult = ReturnType<typeof useGetReachLazyQuery>;
export type GetReachQueryResult = Apollo.QueryResult<GetReachQuery, GetReachQueryVariables>;
export const GetContentMassDocument = gql`
    query GetContentMass($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to, filter: $filter) {
      id
      contentMass
    }
  }
}
    `;

/**
 * __useGetContentMassQuery__
 *
 * To run a query within a React component, call `useGetContentMassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentMassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentMassQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetContentMassQuery(baseOptions?: Apollo.QueryHookOptions<GetContentMassQuery, GetContentMassQueryVariables>) {
        return Apollo.useQuery<GetContentMassQuery, GetContentMassQueryVariables>(GetContentMassDocument, baseOptions);
      }
export function useGetContentMassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentMassQuery, GetContentMassQueryVariables>) {
          return Apollo.useLazyQuery<GetContentMassQuery, GetContentMassQueryVariables>(GetContentMassDocument, baseOptions);
        }
export type GetContentMassQueryHookResult = ReturnType<typeof useGetContentMassQuery>;
export type GetContentMassLazyQueryHookResult = ReturnType<typeof useGetContentMassLazyQuery>;
export type GetContentMassQueryResult = Apollo.QueryResult<GetContentMassQuery, GetContentMassQueryVariables>;
export const GetTopLinksDocument = gql`
    query GetTopLinks($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to, useDbEngine: true, filter: $filter) {
      id
      topLinks {
        url
        clicks
      }
    }
  }
}
    `;

/**
 * __useGetTopLinksQuery__
 *
 * To run a query within a React component, call `useGetTopLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopLinksQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTopLinksQuery(baseOptions?: Apollo.QueryHookOptions<GetTopLinksQuery, GetTopLinksQueryVariables>) {
        return Apollo.useQuery<GetTopLinksQuery, GetTopLinksQueryVariables>(GetTopLinksDocument, baseOptions);
      }
export function useGetTopLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopLinksQuery, GetTopLinksQueryVariables>) {
          return Apollo.useLazyQuery<GetTopLinksQuery, GetTopLinksQueryVariables>(GetTopLinksDocument, baseOptions);
        }
export type GetTopLinksQueryHookResult = ReturnType<typeof useGetTopLinksQuery>;
export type GetTopLinksLazyQueryHookResult = ReturnType<typeof useGetTopLinksLazyQuery>;
export type GetTopLinksQueryResult = Apollo.QueryResult<GetTopLinksQuery, GetTopLinksQueryVariables>;
export const GetTopArticlesDocument = gql`
    query GetTopArticles($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to, useDbEngine: true, filter: $filter) {
      id
      topArticles {
        articleId
        title
        clicks
      }
    }
  }
}
    `;

/**
 * __useGetTopArticlesQuery__
 *
 * To run a query within a React component, call `useGetTopArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopArticlesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTopArticlesQuery(baseOptions?: Apollo.QueryHookOptions<GetTopArticlesQuery, GetTopArticlesQueryVariables>) {
        return Apollo.useQuery<GetTopArticlesQuery, GetTopArticlesQueryVariables>(GetTopArticlesDocument, baseOptions);
      }
export function useGetTopArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopArticlesQuery, GetTopArticlesQueryVariables>) {
          return Apollo.useLazyQuery<GetTopArticlesQuery, GetTopArticlesQueryVariables>(GetTopArticlesDocument, baseOptions);
        }
export type GetTopArticlesQueryHookResult = ReturnType<typeof useGetTopArticlesQuery>;
export type GetTopArticlesLazyQueryHookResult = ReturnType<typeof useGetTopArticlesLazyQuery>;
export type GetTopArticlesQueryResult = Apollo.QueryResult<GetTopArticlesQuery, GetTopArticlesQueryVariables>;
export const GetTopMediaDocument = gql`
    query GetTopMedia($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to, useDbEngine: true, filter: $filter) {
      id
      topMedia {
        clicks
        domain
        links
      }
    }
  }
}
    `;

/**
 * __useGetTopMediaQuery__
 *
 * To run a query within a React component, call `useGetTopMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopMediaQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTopMediaQuery(baseOptions?: Apollo.QueryHookOptions<GetTopMediaQuery, GetTopMediaQueryVariables>) {
        return Apollo.useQuery<GetTopMediaQuery, GetTopMediaQueryVariables>(GetTopMediaDocument, baseOptions);
      }
export function useGetTopMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopMediaQuery, GetTopMediaQueryVariables>) {
          return Apollo.useLazyQuery<GetTopMediaQuery, GetTopMediaQueryVariables>(GetTopMediaDocument, baseOptions);
        }
export type GetTopMediaQueryHookResult = ReturnType<typeof useGetTopMediaQuery>;
export type GetTopMediaLazyQueryHookResult = ReturnType<typeof useGetTopMediaLazyQuery>;
export type GetTopMediaQueryResult = Apollo.QueryResult<GetTopMediaQuery, GetTopMediaQueryVariables>;
export const GetEstimatedReadsDocument = gql`
    query GetEstimatedReads($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to, useDbEngine: true, filter: $filter) {
      id
      reads
    }
  }
}
    `;

/**
 * __useGetEstimatedReadsQuery__
 *
 * To run a query within a React component, call `useGetEstimatedReadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstimatedReadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstimatedReadsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEstimatedReadsQuery(baseOptions?: Apollo.QueryHookOptions<GetEstimatedReadsQuery, GetEstimatedReadsQueryVariables>) {
        return Apollo.useQuery<GetEstimatedReadsQuery, GetEstimatedReadsQueryVariables>(GetEstimatedReadsDocument, baseOptions);
      }
export function useGetEstimatedReadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstimatedReadsQuery, GetEstimatedReadsQueryVariables>) {
          return Apollo.useLazyQuery<GetEstimatedReadsQuery, GetEstimatedReadsQueryVariables>(GetEstimatedReadsDocument, baseOptions);
        }
export type GetEstimatedReadsQueryHookResult = ReturnType<typeof useGetEstimatedReadsQuery>;
export type GetEstimatedReadsLazyQueryHookResult = ReturnType<typeof useGetEstimatedReadsLazyQuery>;
export type GetEstimatedReadsQueryResult = Apollo.QueryResult<GetEstimatedReadsQuery, GetEstimatedReadsQueryVariables>;
export const GetContentMassPlotDocument = gql`
    query GetContentMassPlot($slug: String!, $from: Date!, $to: Date!, $list: String, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    stats(from: $from, to: $to, filter: $filter) {
      id
      contentMassDetails(list: $list) {
        count
        date
      }
    }
  }
}
    `;

/**
 * __useGetContentMassPlotQuery__
 *
 * To run a query within a React component, call `useGetContentMassPlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentMassPlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentMassPlotQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      list: // value for 'list'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetContentMassPlotQuery(baseOptions?: Apollo.QueryHookOptions<GetContentMassPlotQuery, GetContentMassPlotQueryVariables>) {
        return Apollo.useQuery<GetContentMassPlotQuery, GetContentMassPlotQueryVariables>(GetContentMassPlotDocument, baseOptions);
      }
export function useGetContentMassPlotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentMassPlotQuery, GetContentMassPlotQueryVariables>) {
          return Apollo.useLazyQuery<GetContentMassPlotQuery, GetContentMassPlotQueryVariables>(GetContentMassPlotDocument, baseOptions);
        }
export type GetContentMassPlotQueryHookResult = ReturnType<typeof useGetContentMassPlotQuery>;
export type GetContentMassPlotLazyQueryHookResult = ReturnType<typeof useGetContentMassPlotLazyQuery>;
export type GetContentMassPlotQueryResult = Apollo.QueryResult<GetContentMassPlotQuery, GetContentMassPlotQueryVariables>;
export const GetLinksDocument = gql`
    query GetLinks($slug: String!) {
  Company(slug: $slug) {
    id
    links {
      id
      link
      immReference
      active
      sources
      active
      editable
      theses
      date
      status
      ave {
        date
        value
      }
      reach {
        date
        value
      }
      reach {
        date
        value
      }
      article {
        id
        title
      }
    }
  }
    }
`;

/**
 * __useGetLinksQuery__
 *
 * To run a query within a React component, call `useGetLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinksQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetLinksQuery(baseOptions?: Apollo.QueryHookOptions<GetLinksQuery, GetLinksQueryVariables>) {
        return Apollo.useQuery<GetLinksQuery, GetLinksQueryVariables>(GetLinksDocument, baseOptions);
      }
export function useGetLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinksQuery, GetLinksQueryVariables>) {
          return Apollo.useLazyQuery<GetLinksQuery, GetLinksQueryVariables>(GetLinksDocument, baseOptions);
        }
export type GetLinksQueryHookResult = ReturnType<typeof useGetLinksQuery>;
export type GetLinksLazyQueryHookResult = ReturnType<typeof useGetLinksLazyQuery>;
export type GetLinksQueryResult = Apollo.QueryResult<GetLinksQuery, GetLinksQueryVariables>;
export const GetPhraseDocument = gql`
    query GetPhrase($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    id
    phrases {
      text
      slug
      potential
      theses
    }
    phrasesStats(from: $from, to: $to, useDbEngine: true, filter: $filter) {
      slug
      ctr
      positions
      estimatedReads
    }
  }
}
    `;

/**
 * __useGetPhraseQuery__
 *
 * To run a query within a React component, call `useGetPhraseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhraseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhraseQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPhraseQuery(baseOptions?: Apollo.QueryHookOptions<GetPhraseQuery, GetPhraseQueryVariables>) {
        return Apollo.useQuery<GetPhraseQuery, GetPhraseQueryVariables>(GetPhraseDocument, baseOptions);
      }
export function useGetPhraseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhraseQuery, GetPhraseQueryVariables>) {
          return Apollo.useLazyQuery<GetPhraseQuery, GetPhraseQueryVariables>(GetPhraseDocument, baseOptions);
        }
export type GetPhraseQueryHookResult = ReturnType<typeof useGetPhraseQuery>;
export type GetPhraseLazyQueryHookResult = ReturnType<typeof useGetPhraseLazyQuery>;
export type GetPhraseQueryResult = Apollo.QueryResult<GetPhraseQuery, GetPhraseQueryVariables>;
export const GetPhraseListDocument = gql`
    query GetPhraseList($slug: String!) {
  Company(slug: $slug) {
    ...CompanyCommon
    favourite {
      ...PhraseCommon
    }
  }
}
    ${CompanyCommonFragmentDoc}
${PhraseCommonFragmentDoc}`;

/**
 * __useGetPhraseListQuery__
 *
 * To run a query within a React component, call `useGetPhraseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhraseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhraseListQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPhraseListQuery(baseOptions?: Apollo.QueryHookOptions<GetPhraseListQuery, GetPhraseListQueryVariables>) {
        return Apollo.useQuery<GetPhraseListQuery, GetPhraseListQueryVariables>(GetPhraseListDocument, baseOptions);
      }
export function useGetPhraseListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhraseListQuery, GetPhraseListQueryVariables>) {
          return Apollo.useLazyQuery<GetPhraseListQuery, GetPhraseListQueryVariables>(GetPhraseListDocument, baseOptions);
        }
export type GetPhraseListQueryHookResult = ReturnType<typeof useGetPhraseListQuery>;
export type GetPhraseListLazyQueryHookResult = ReturnType<typeof useGetPhraseListLazyQuery>;
export type GetPhraseListQueryResult = Apollo.QueryResult<GetPhraseListQuery, GetPhraseListQueryVariables>;
export const GetCompanyPhraseListsDocument = gql`
    query GetCompanyPhraseLists($slug: String!) {
  Company(slug: $slug) {
    id
    phraseLists {
      id
      name
      slug
    }
    mediaLists {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useGetCompanyPhraseListsQuery__
 *
 * To run a query within a React component, call `useGetCompanyPhraseListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPhraseListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPhraseListsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCompanyPhraseListsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyPhraseListsQuery, GetCompanyPhraseListsQueryVariables>) {
        return Apollo.useQuery<GetCompanyPhraseListsQuery, GetCompanyPhraseListsQueryVariables>(GetCompanyPhraseListsDocument, baseOptions);
      }
export function useGetCompanyPhraseListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyPhraseListsQuery, GetCompanyPhraseListsQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyPhraseListsQuery, GetCompanyPhraseListsQueryVariables>(GetCompanyPhraseListsDocument, baseOptions);
        }
export type GetCompanyPhraseListsQueryHookResult = ReturnType<typeof useGetCompanyPhraseListsQuery>;
export type GetCompanyPhraseListsLazyQueryHookResult = ReturnType<typeof useGetCompanyPhraseListsLazyQuery>;
export type GetCompanyPhraseListsQueryResult = Apollo.QueryResult<GetCompanyPhraseListsQuery, GetCompanyPhraseListsQueryVariables>;
export const GetSimilarQuestionDocument = gql`
    query GetSimilarQuestion($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    stats(from: $from, to: $to, filter: $filter) {
      similarQuestions {
        link
        phrase
        question
      }
    }
  }
}
    `;

/**
 * __useGetSimilarQuestionQuery__
 *
 * To run a query within a React component, call `useGetSimilarQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarQuestionQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSimilarQuestionQuery(baseOptions?: Apollo.QueryHookOptions<GetSimilarQuestionQuery, GetSimilarQuestionQueryVariables>) {
        return Apollo.useQuery<GetSimilarQuestionQuery, GetSimilarQuestionQueryVariables>(GetSimilarQuestionDocument, baseOptions);
      }
export function useGetSimilarQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimilarQuestionQuery, GetSimilarQuestionQueryVariables>) {
          return Apollo.useLazyQuery<GetSimilarQuestionQuery, GetSimilarQuestionQueryVariables>(GetSimilarQuestionDocument, baseOptions);
        }
export type GetSimilarQuestionQueryHookResult = ReturnType<typeof useGetSimilarQuestionQuery>;
export type GetSimilarQuestionLazyQueryHookResult = ReturnType<typeof useGetSimilarQuestionLazyQuery>;
export type GetSimilarQuestionQueryResult = Apollo.QueryResult<GetSimilarQuestionQuery, GetSimilarQuestionQueryVariables>;
export const GetLastPublicationsDocument = gql`
    query GetLastPublications($slug: String!, $from: Date!, $to: Date!, $filter: DashboardQuery) {
  Company(slug: $slug) {
    stats(from: $from, to: $to, useDbEngine: true, filter: $filter) {
      lastPublications {
        title
        articleId
        publicationsNumber
      }
    }
  }
}
    `;

/**
 * __useGetLastPublicationsQuery__
 *
 * To run a query within a React component, call `useGetLastPublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastPublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastPublicationsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetLastPublicationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLastPublicationsQuery, GetLastPublicationsQueryVariables>) {
        return Apollo.useQuery<GetLastPublicationsQuery, GetLastPublicationsQueryVariables>(GetLastPublicationsDocument, baseOptions);
      }
export function useGetLastPublicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastPublicationsQuery, GetLastPublicationsQueryVariables>) {
          return Apollo.useLazyQuery<GetLastPublicationsQuery, GetLastPublicationsQueryVariables>(GetLastPublicationsDocument, baseOptions);
        }
export type GetLastPublicationsQueryHookResult = ReturnType<typeof useGetLastPublicationsQuery>;
export type GetLastPublicationsLazyQueryHookResult = ReturnType<typeof useGetLastPublicationsLazyQuery>;
export type GetLastPublicationsQueryResult = Apollo.QueryResult<GetLastPublicationsQuery, GetLastPublicationsQueryVariables>;
export const LinksDocument = gql`
    subscription Links {
  linkUpdated {
    id
    active
    editable
    immReference
    link
    status
    theses
  }
}
    `;

/**
 * __useLinksSubscription__
 *
 * To run a query within a React component, call `useLinksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLinksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLinksSubscription(baseOptions?: Apollo.SubscriptionHookOptions<LinksSubscription, LinksSubscriptionVariables>) {
        return Apollo.useSubscription<LinksSubscription, LinksSubscriptionVariables>(LinksDocument, baseOptions);
      }
export type LinksSubscriptionHookResult = ReturnType<typeof useLinksSubscription>;
export type LinksSubscriptionResult = Apollo.SubscriptionResult<LinksSubscription>;
export const GetLogsForCompanyDocument = gql`
    query GetLogsForCompany($slug: String!, $from: Date!, $to: Date!) {
  Company(slug: $slug) {
    name
    slug
    log(from: $from, to: $to) {
      topic
      message
      change
    }
  }
}
    `;

/**
 * __useGetLogsForCompanyQuery__
 *
 * To run a query within a React component, call `useGetLogsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogsForCompanyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetLogsForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetLogsForCompanyQuery, GetLogsForCompanyQueryVariables>) {
        return Apollo.useQuery<GetLogsForCompanyQuery, GetLogsForCompanyQueryVariables>(GetLogsForCompanyDocument, baseOptions);
      }
export function useGetLogsForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogsForCompanyQuery, GetLogsForCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetLogsForCompanyQuery, GetLogsForCompanyQueryVariables>(GetLogsForCompanyDocument, baseOptions);
        }
export type GetLogsForCompanyQueryHookResult = ReturnType<typeof useGetLogsForCompanyQuery>;
export type GetLogsForCompanyLazyQueryHookResult = ReturnType<typeof useGetLogsForCompanyLazyQuery>;
export type GetLogsForCompanyQueryResult = Apollo.QueryResult<GetLogsForCompanyQuery, GetLogsForCompanyQueryVariables>;
export const CancelJobDocument = gql`
    mutation CancelJob($id: ID!) {
  Job {
    cancel(id: $id) {
      status
      error
    }
  }
}
    `;
export type CancelJobMutationFn = Apollo.MutationFunction<CancelJobMutation, CancelJobMutationVariables>;

/**
 * __useCancelJobMutation__
 *
 * To run a mutation, you first call `useCancelJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelJobMutation, { data, loading, error }] = useCancelJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelJobMutation(baseOptions?: Apollo.MutationHookOptions<CancelJobMutation, CancelJobMutationVariables>) {
        return Apollo.useMutation<CancelJobMutation, CancelJobMutationVariables>(CancelJobDocument, baseOptions);
      }
export type CancelJobMutationHookResult = ReturnType<typeof useCancelJobMutation>;
export type CancelJobMutationResult = Apollo.MutationResult<CancelJobMutation>;
export type CancelJobMutationOptions = Apollo.BaseMutationOptions<CancelJobMutation, CancelJobMutationVariables>;
export const GetJobsDocument = gql`
    query GetJobs {
  Jobs {
    id
    stats {
      error
      done
      doing
      waiting
      all
    }
    state
    started
    updated
    created
    type
    finished
    cancelledAt
  }
}
    `;

/**
 * __useGetJobsQuery__
 *
 * To run a query within a React component, call `useGetJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
        return Apollo.useQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, baseOptions);
      }
export function useGetJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          return Apollo.useLazyQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, baseOptions);
        }
export type GetJobsQueryHookResult = ReturnType<typeof useGetJobsQuery>;
export type GetJobsLazyQueryHookResult = ReturnType<typeof useGetJobsLazyQuery>;
export type GetJobsQueryResult = Apollo.QueryResult<GetJobsQuery, GetJobsQueryVariables>;
export const TriggerJobDocument = gql`
    mutation TriggerJob($type: String!) {
  Job {
    trigger(type: $type) {
      status
      error
    }
  }
}
    `;
export type TriggerJobMutationFn = Apollo.MutationFunction<TriggerJobMutation, TriggerJobMutationVariables>;

/**
 * __useTriggerJobMutation__
 *
 * To run a mutation, you first call `useTriggerJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerJobMutation, { data, loading, error }] = useTriggerJobMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTriggerJobMutation(baseOptions?: Apollo.MutationHookOptions<TriggerJobMutation, TriggerJobMutationVariables>) {
        return Apollo.useMutation<TriggerJobMutation, TriggerJobMutationVariables>(TriggerJobDocument, baseOptions);
      }
export type TriggerJobMutationHookResult = ReturnType<typeof useTriggerJobMutation>;
export type TriggerJobMutationResult = Apollo.MutationResult<TriggerJobMutation>;
export type TriggerJobMutationOptions = Apollo.BaseMutationOptions<TriggerJobMutation, TriggerJobMutationVariables>;
export const AddLinkDocument = gql`
    mutation AddLink($company: String!, $links: [String!]!) {
  Company(slug: $company) {
    addLink(links: $links) {
      success
    }
  }
}
    `;
export type AddLinkMutationFn = Apollo.MutationFunction<AddLinkMutation, AddLinkMutationVariables>;

/**
 * __useAddLinkMutation__
 *
 * To run a mutation, you first call `useAddLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLinkMutation, { data, loading, error }] = useAddLinkMutation({
 *   variables: {
 *      company: // value for 'company'
 *      links: // value for 'links'
 *   },
 * });
 */
export function useAddLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddLinkMutation, AddLinkMutationVariables>) {
        return Apollo.useMutation<AddLinkMutation, AddLinkMutationVariables>(AddLinkDocument, baseOptions);
      }
export type AddLinkMutationHookResult = ReturnType<typeof useAddLinkMutation>;
export type AddLinkMutationResult = Apollo.MutationResult<AddLinkMutation>;
export type AddLinkMutationOptions = Apollo.BaseMutationOptions<AddLinkMutation, AddLinkMutationVariables>;
export const UpdateLinkDocument = gql`
    mutation UpdateLink($link: String!, $params: LinkUpdateParams!) {
  Link(immReference: $link) {
    update(params: $params) {
      success
    }
  }
}
    `;
export type UpdateLinkMutationFn = Apollo.MutationFunction<UpdateLinkMutation, UpdateLinkMutationVariables>;

/**
 * __useUpdateLinkMutation__
 *
 * To run a mutation, you first call `useUpdateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkMutation, { data, loading, error }] = useUpdateLinkMutation({
 *   variables: {
 *      link: // value for 'link'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLinkMutation, UpdateLinkMutationVariables>) {
        return Apollo.useMutation<UpdateLinkMutation, UpdateLinkMutationVariables>(UpdateLinkDocument, baseOptions);
      }
export type UpdateLinkMutationHookResult = ReturnType<typeof useUpdateLinkMutation>;
export type UpdateLinkMutationResult = Apollo.MutationResult<UpdateLinkMutation>;
export type UpdateLinkMutationOptions = Apollo.BaseMutationOptions<UpdateLinkMutation, UpdateLinkMutationVariables>;
export const AssignArticleToLinkDocument = gql`
    mutation AssignArticleToLink($link: String!, $article: ID, $linkId: ID, $companySlug: String) {
  Link(immReference: $link, companySlug: $companySlug, id: $linkId) {
    assignArticle(articleId: $article) {
      success
    }
  }
}
    `;
export type AssignArticleToLinkMutationFn = Apollo.MutationFunction<AssignArticleToLinkMutation, AssignArticleToLinkMutationVariables>;

/**
 * __useAssignArticleToLinkMutation__
 *
 * To run a mutation, you first call `useAssignArticleToLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignArticleToLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignArticleToLinkMutation, { data, loading, error }] = useAssignArticleToLinkMutation({
 *   variables: {
 *      link: // value for 'link'
 *      article: // value for 'article'
 *      linkId: // value for 'linkId'
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useAssignArticleToLinkMutation(baseOptions?: Apollo.MutationHookOptions<AssignArticleToLinkMutation, AssignArticleToLinkMutationVariables>) {
        return Apollo.useMutation<AssignArticleToLinkMutation, AssignArticleToLinkMutationVariables>(AssignArticleToLinkDocument, baseOptions);
      }
export type AssignArticleToLinkMutationHookResult = ReturnType<typeof useAssignArticleToLinkMutation>;
export type AssignArticleToLinkMutationResult = Apollo.MutationResult<AssignArticleToLinkMutation>;
export type AssignArticleToLinkMutationOptions = Apollo.BaseMutationOptions<AssignArticleToLinkMutation, AssignArticleToLinkMutationVariables>;
export const GetLinkDetailsDocument = gql`
    query GetLinkDetails($url: String!) {
  LinkDetails(url: $url) {
    id
    url
    finalUrl
    finalStatus
    date
    document {
      id
      title
      canonical
      meta {
        id
        robots
      }
    }
    requests {
      id
      redirected
      status
      headers {
        location
      }
    }
  }
}
    `;

/**
 * __useGetLinkDetailsQuery__
 *
 * To run a query within a React component, call `useGetLinkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkDetailsQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetLinkDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetLinkDetailsQuery, GetLinkDetailsQueryVariables>) {
        return Apollo.useQuery<GetLinkDetailsQuery, GetLinkDetailsQueryVariables>(GetLinkDetailsDocument, baseOptions);
      }
export function useGetLinkDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinkDetailsQuery, GetLinkDetailsQueryVariables>) {
          return Apollo.useLazyQuery<GetLinkDetailsQuery, GetLinkDetailsQueryVariables>(GetLinkDetailsDocument, baseOptions);
        }
export type GetLinkDetailsQueryHookResult = ReturnType<typeof useGetLinkDetailsQuery>;
export type GetLinkDetailsLazyQueryHookResult = ReturnType<typeof useGetLinkDetailsLazyQuery>;
export type GetLinkDetailsQueryResult = Apollo.QueryResult<GetLinkDetailsQuery, GetLinkDetailsQueryVariables>;
export const CheckLinkDocument = gql`
    mutation CheckLink($url: String!) {
  LinkDetails {
    check(url: $url) {
      status
    }
  }
}
    `;
export type CheckLinkMutationFn = Apollo.MutationFunction<CheckLinkMutation, CheckLinkMutationVariables>;

/**
 * __useCheckLinkMutation__
 *
 * To run a mutation, you first call `useCheckLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkLinkMutation, { data, loading, error }] = useCheckLinkMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCheckLinkMutation(baseOptions?: Apollo.MutationHookOptions<CheckLinkMutation, CheckLinkMutationVariables>) {
        return Apollo.useMutation<CheckLinkMutation, CheckLinkMutationVariables>(CheckLinkDocument, baseOptions);
      }
export type CheckLinkMutationHookResult = ReturnType<typeof useCheckLinkMutation>;
export type CheckLinkMutationResult = Apollo.MutationResult<CheckLinkMutation>;
export type CheckLinkMutationOptions = Apollo.BaseMutationOptions<CheckLinkMutation, CheckLinkMutationVariables>;
export const LinkToPhrasesDocument = gql`
    query LinkToPhrases($url: String!) {
  Link {
    phrases(url: $url) {
      text
    }
    isInUse(url: $url)
  }
}
    `;

/**
 * __useLinkToPhrasesQuery__
 *
 * To run a query within a React component, call `useLinkToPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkToPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkToPhrasesQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useLinkToPhrasesQuery(baseOptions?: Apollo.QueryHookOptions<LinkToPhrasesQuery, LinkToPhrasesQueryVariables>) {
        return Apollo.useQuery<LinkToPhrasesQuery, LinkToPhrasesQueryVariables>(LinkToPhrasesDocument, baseOptions);
      }
export function useLinkToPhrasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkToPhrasesQuery, LinkToPhrasesQueryVariables>) {
          return Apollo.useLazyQuery<LinkToPhrasesQuery, LinkToPhrasesQueryVariables>(LinkToPhrasesDocument, baseOptions);
        }
export type LinkToPhrasesQueryHookResult = ReturnType<typeof useLinkToPhrasesQuery>;
export type LinkToPhrasesLazyQueryHookResult = ReturnType<typeof useLinkToPhrasesLazyQuery>;
export type LinkToPhrasesQueryResult = Apollo.QueryResult<LinkToPhrasesQuery, LinkToPhrasesQueryVariables>;
export const GetSuggesterArticlesDocument = gql`
    query GetSuggesterArticles($slug: String!, $linkId: ID!) {
  Company(slug: $slug) {
    suggestedArticles(linkId: $linkId) {
      id
      title
    }
  }
}
    `;

/**
 * __useGetSuggesterArticlesQuery__
 *
 * To run a query within a React component, call `useGetSuggesterArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggesterArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggesterArticlesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useGetSuggesterArticlesQuery(baseOptions?: Apollo.QueryHookOptions<GetSuggesterArticlesQuery, GetSuggesterArticlesQueryVariables>) {
        return Apollo.useQuery<GetSuggesterArticlesQuery, GetSuggesterArticlesQueryVariables>(GetSuggesterArticlesDocument, baseOptions);
      }
export function useGetSuggesterArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuggesterArticlesQuery, GetSuggesterArticlesQueryVariables>) {
          return Apollo.useLazyQuery<GetSuggesterArticlesQuery, GetSuggesterArticlesQueryVariables>(GetSuggesterArticlesDocument, baseOptions);
        }
export type GetSuggesterArticlesQueryHookResult = ReturnType<typeof useGetSuggesterArticlesQuery>;
export type GetSuggesterArticlesLazyQueryHookResult = ReturnType<typeof useGetSuggesterArticlesLazyQuery>;
export type GetSuggesterArticlesQueryResult = Apollo.QueryResult<GetSuggesterArticlesQuery, GetSuggesterArticlesQueryVariables>;
export const GetMediaSuggestionsDocument = gql`
    query GetMediaSuggestions($query: String!) {
  Media {
    autocomplete(query: $query)
  }
}
    `;

/**
 * __useGetMediaSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetMediaSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaSuggestionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetMediaSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetMediaSuggestionsQuery, GetMediaSuggestionsQueryVariables>) {
        return Apollo.useQuery<GetMediaSuggestionsQuery, GetMediaSuggestionsQueryVariables>(GetMediaSuggestionsDocument, baseOptions);
      }
export function useGetMediaSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaSuggestionsQuery, GetMediaSuggestionsQueryVariables>) {
          return Apollo.useLazyQuery<GetMediaSuggestionsQuery, GetMediaSuggestionsQueryVariables>(GetMediaSuggestionsDocument, baseOptions);
        }
export type GetMediaSuggestionsQueryHookResult = ReturnType<typeof useGetMediaSuggestionsQuery>;
export type GetMediaSuggestionsLazyQueryHookResult = ReturnType<typeof useGetMediaSuggestionsLazyQuery>;
export type GetMediaSuggestionsQueryResult = Apollo.QueryResult<GetMediaSuggestionsQuery, GetMediaSuggestionsQueryVariables>;
export const AddToMediaListDocument = gql`
    mutation AddToMediaList($slug: String!, $domain: String!, $listSlug: String!) {
  Company(slug: $slug) {
    addDomainToList(domain: $domain, listSlug: $listSlug) {
      success
    }
  }
}
    `;
export type AddToMediaListMutationFn = Apollo.MutationFunction<AddToMediaListMutation, AddToMediaListMutationVariables>;

/**
 * __useAddToMediaListMutation__
 *
 * To run a mutation, you first call `useAddToMediaListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToMediaListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToMediaListMutation, { data, loading, error }] = useAddToMediaListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      domain: // value for 'domain'
 *      listSlug: // value for 'listSlug'
 *   },
 * });
 */
export function useAddToMediaListMutation(baseOptions?: Apollo.MutationHookOptions<AddToMediaListMutation, AddToMediaListMutationVariables>) {
        return Apollo.useMutation<AddToMediaListMutation, AddToMediaListMutationVariables>(AddToMediaListDocument, baseOptions);
      }
export type AddToMediaListMutationHookResult = ReturnType<typeof useAddToMediaListMutation>;
export type AddToMediaListMutationResult = Apollo.MutationResult<AddToMediaListMutation>;
export type AddToMediaListMutationOptions = Apollo.BaseMutationOptions<AddToMediaListMutation, AddToMediaListMutationVariables>;
export const RemoveFromMediaListDocument = gql`
    mutation RemoveFromMediaList($slug: String!, $domain: String!, $listSlug: String!) {
  Company(slug: $slug) {
    removeDomainToList(domain: $domain, listSlug: $listSlug) {
      success
    }
  }
}
    `;
export type RemoveFromMediaListMutationFn = Apollo.MutationFunction<RemoveFromMediaListMutation, RemoveFromMediaListMutationVariables>;

/**
 * __useRemoveFromMediaListMutation__
 *
 * To run a mutation, you first call `useRemoveFromMediaListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromMediaListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromMediaListMutation, { data, loading, error }] = useRemoveFromMediaListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      domain: // value for 'domain'
 *      listSlug: // value for 'listSlug'
 *   },
 * });
 */
export function useRemoveFromMediaListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromMediaListMutation, RemoveFromMediaListMutationVariables>) {
        return Apollo.useMutation<RemoveFromMediaListMutation, RemoveFromMediaListMutationVariables>(RemoveFromMediaListDocument, baseOptions);
      }
export type RemoveFromMediaListMutationHookResult = ReturnType<typeof useRemoveFromMediaListMutation>;
export type RemoveFromMediaListMutationResult = Apollo.MutationResult<RemoveFromMediaListMutation>;
export type RemoveFromMediaListMutationOptions = Apollo.BaseMutationOptions<RemoveFromMediaListMutation, RemoveFromMediaListMutationVariables>;
export const GetMediaListDocument = gql`
    query GetMediaList($companySlug: String!, $listSlug: String!) {
  Company(slug: $companySlug) {
    media {
      list(listSlug: $listSlug) {
        id
        domain
      }
    }
  }
}
    `;

/**
 * __useGetMediaListQuery__
 *
 * To run a query within a React component, call `useGetMediaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaListQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *      listSlug: // value for 'listSlug'
 *   },
 * });
 */
export function useGetMediaListQuery(baseOptions?: Apollo.QueryHookOptions<GetMediaListQuery, GetMediaListQueryVariables>) {
        return Apollo.useQuery<GetMediaListQuery, GetMediaListQueryVariables>(GetMediaListDocument, baseOptions);
      }
export function useGetMediaListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaListQuery, GetMediaListQueryVariables>) {
          return Apollo.useLazyQuery<GetMediaListQuery, GetMediaListQueryVariables>(GetMediaListDocument, baseOptions);
        }
export type GetMediaListQueryHookResult = ReturnType<typeof useGetMediaListQuery>;
export type GetMediaListLazyQueryHookResult = ReturnType<typeof useGetMediaListLazyQuery>;
export type GetMediaListQueryResult = Apollo.QueryResult<GetMediaListQuery, GetMediaListQueryVariables>;
export const CreateMediaListDocument = gql`
    mutation CreateMediaList($slug: String!, $listName: String!) {
  Company(slug: $slug) {
    mediaLists {
      add(name: $listName) {
        success
      }
    }
  }
}
    `;
export type CreateMediaListMutationFn = Apollo.MutationFunction<CreateMediaListMutation, CreateMediaListMutationVariables>;

/**
 * __useCreateMediaListMutation__
 *
 * To run a mutation, you first call `useCreateMediaListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaListMutation, { data, loading, error }] = useCreateMediaListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      listName: // value for 'listName'
 *   },
 * });
 */
export function useCreateMediaListMutation(baseOptions?: Apollo.MutationHookOptions<CreateMediaListMutation, CreateMediaListMutationVariables>) {
        return Apollo.useMutation<CreateMediaListMutation, CreateMediaListMutationVariables>(CreateMediaListDocument, baseOptions);
      }
export type CreateMediaListMutationHookResult = ReturnType<typeof useCreateMediaListMutation>;
export type CreateMediaListMutationResult = Apollo.MutationResult<CreateMediaListMutation>;
export type CreateMediaListMutationOptions = Apollo.BaseMutationOptions<CreateMediaListMutation, CreateMediaListMutationVariables>;
export const AddPhraseDocument = gql`
    mutation AddPhrase($companySlug: String!, $phraseText: [String!]!, $listSlug: String) {
  Company(slug: $companySlug) {
    addPhrase(text: $phraseText, listSlug: $listSlug) {
      success
    }
  }
}
    `;
export type AddPhraseMutationFn = Apollo.MutationFunction<AddPhraseMutation, AddPhraseMutationVariables>;

/**
 * __useAddPhraseMutation__
 *
 * To run a mutation, you first call `useAddPhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhraseMutation, { data, loading, error }] = useAddPhraseMutation({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *      phraseText: // value for 'phraseText'
 *      listSlug: // value for 'listSlug'
 *   },
 * });
 */
export function useAddPhraseMutation(baseOptions?: Apollo.MutationHookOptions<AddPhraseMutation, AddPhraseMutationVariables>) {
        return Apollo.useMutation<AddPhraseMutation, AddPhraseMutationVariables>(AddPhraseDocument, baseOptions);
      }
export type AddPhraseMutationHookResult = ReturnType<typeof useAddPhraseMutation>;
export type AddPhraseMutationResult = Apollo.MutationResult<AddPhraseMutation>;
export type AddPhraseMutationOptions = Apollo.BaseMutationOptions<AddPhraseMutation, AddPhraseMutationVariables>;
export const GetScansDocument = gql`
    query GetScans($slug: String!, $from: Date!, $to: Date!, $companies: [String!]!) {
  Phrase(slug: $slug) {
    text
    scans(from: $from, to: $to, companies: $companies) {
      date
      urls
      marked
    }
  }
}
    `;

/**
 * __useGetScansQuery__
 *
 * To run a query within a React component, call `useGetScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScansQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      companies: // value for 'companies'
 *   },
 * });
 */
export function useGetScansQuery(baseOptions?: Apollo.QueryHookOptions<GetScansQuery, GetScansQueryVariables>) {
        return Apollo.useQuery<GetScansQuery, GetScansQueryVariables>(GetScansDocument, baseOptions);
      }
export function useGetScansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScansQuery, GetScansQueryVariables>) {
          return Apollo.useLazyQuery<GetScansQuery, GetScansQueryVariables>(GetScansDocument, baseOptions);
        }
export type GetScansQueryHookResult = ReturnType<typeof useGetScansQuery>;
export type GetScansLazyQueryHookResult = ReturnType<typeof useGetScansLazyQuery>;
export type GetScansQueryResult = Apollo.QueryResult<GetScansQuery, GetScansQueryVariables>;
export const UpdatePhrasePotentialDocument = gql`
    mutation UpdatePhrasePotential($companySlug: String!, $phraseSlug: String!, $potential: Int!) {
  Phrase(slug: $phraseSlug, companySlug: $companySlug) {
    update(params: {potential: $potential}) {
      success
    }
  }
}
    `;
export type UpdatePhrasePotentialMutationFn = Apollo.MutationFunction<UpdatePhrasePotentialMutation, UpdatePhrasePotentialMutationVariables>;

/**
 * __useUpdatePhrasePotentialMutation__
 *
 * To run a mutation, you first call `useUpdatePhrasePotentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhrasePotentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhrasePotentialMutation, { data, loading, error }] = useUpdatePhrasePotentialMutation({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *      phraseSlug: // value for 'phraseSlug'
 *      potential: // value for 'potential'
 *   },
 * });
 */
export function useUpdatePhrasePotentialMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhrasePotentialMutation, UpdatePhrasePotentialMutationVariables>) {
        return Apollo.useMutation<UpdatePhrasePotentialMutation, UpdatePhrasePotentialMutationVariables>(UpdatePhrasePotentialDocument, baseOptions);
      }
export type UpdatePhrasePotentialMutationHookResult = ReturnType<typeof useUpdatePhrasePotentialMutation>;
export type UpdatePhrasePotentialMutationResult = Apollo.MutationResult<UpdatePhrasePotentialMutation>;
export type UpdatePhrasePotentialMutationOptions = Apollo.BaseMutationOptions<UpdatePhrasePotentialMutation, UpdatePhrasePotentialMutationVariables>;
export const AddPhraseToListDocument = gql`
    mutation AddPhraseToList($slug: String!, $phrase: String!, $list: String!) {
  Company(slug: $slug) {
    addPhraseToList(phrase: $phrase, list: $list) {
      success
    }
  }
}
    `;
export type AddPhraseToListMutationFn = Apollo.MutationFunction<AddPhraseToListMutation, AddPhraseToListMutationVariables>;

/**
 * __useAddPhraseToListMutation__
 *
 * To run a mutation, you first call `useAddPhraseToListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhraseToListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhraseToListMutation, { data, loading, error }] = useAddPhraseToListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      phrase: // value for 'phrase'
 *      list: // value for 'list'
 *   },
 * });
 */
export function useAddPhraseToListMutation(baseOptions?: Apollo.MutationHookOptions<AddPhraseToListMutation, AddPhraseToListMutationVariables>) {
        return Apollo.useMutation<AddPhraseToListMutation, AddPhraseToListMutationVariables>(AddPhraseToListDocument, baseOptions);
      }
export type AddPhraseToListMutationHookResult = ReturnType<typeof useAddPhraseToListMutation>;
export type AddPhraseToListMutationResult = Apollo.MutationResult<AddPhraseToListMutation>;
export type AddPhraseToListMutationOptions = Apollo.BaseMutationOptions<AddPhraseToListMutation, AddPhraseToListMutationVariables>;
export const RemovePhraseFromListDocument = gql`
    mutation RemovePhraseFromList($slug: String!, $phrase: String!, $list: String!) {
  Company(slug: $slug) {
    removePhraseToList(phrase: $phrase, list: $list) {
      success
    }
  }
}
    `;
export type RemovePhraseFromListMutationFn = Apollo.MutationFunction<RemovePhraseFromListMutation, RemovePhraseFromListMutationVariables>;

/**
 * __useRemovePhraseFromListMutation__
 *
 * To run a mutation, you first call `useRemovePhraseFromListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePhraseFromListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePhraseFromListMutation, { data, loading, error }] = useRemovePhraseFromListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      phrase: // value for 'phrase'
 *      list: // value for 'list'
 *   },
 * });
 */
export function useRemovePhraseFromListMutation(baseOptions?: Apollo.MutationHookOptions<RemovePhraseFromListMutation, RemovePhraseFromListMutationVariables>) {
        return Apollo.useMutation<RemovePhraseFromListMutation, RemovePhraseFromListMutationVariables>(RemovePhraseFromListDocument, baseOptions);
      }
export type RemovePhraseFromListMutationHookResult = ReturnType<typeof useRemovePhraseFromListMutation>;
export type RemovePhraseFromListMutationResult = Apollo.MutationResult<RemovePhraseFromListMutation>;
export type RemovePhraseFromListMutationOptions = Apollo.BaseMutationOptions<RemovePhraseFromListMutation, RemovePhraseFromListMutationVariables>;
export const RemovePhraseDocument = gql`
    mutation RemovePhrase($slug: String!, $phrase: String!) {
  Company(slug: $slug) {
    removePhrase(slug: $phrase) {
      success
    }
  }
}
    `;
export type RemovePhraseMutationFn = Apollo.MutationFunction<RemovePhraseMutation, RemovePhraseMutationVariables>;

/**
 * __useRemovePhraseMutation__
 *
 * To run a mutation, you first call `useRemovePhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePhraseMutation, { data, loading, error }] = useRemovePhraseMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      phrase: // value for 'phrase'
 *   },
 * });
 */
export function useRemovePhraseMutation(baseOptions?: Apollo.MutationHookOptions<RemovePhraseMutation, RemovePhraseMutationVariables>) {
        return Apollo.useMutation<RemovePhraseMutation, RemovePhraseMutationVariables>(RemovePhraseDocument, baseOptions);
      }
export type RemovePhraseMutationHookResult = ReturnType<typeof useRemovePhraseMutation>;
export type RemovePhraseMutationResult = Apollo.MutationResult<RemovePhraseMutation>;
export type RemovePhraseMutationOptions = Apollo.BaseMutationOptions<RemovePhraseMutation, RemovePhraseMutationVariables>;
export const CreatePhraseListDocument = gql`
    mutation CreatePhraseList($slug: String!, $listName: String!) {
  Company(slug: $slug) {
    phraseLists {
      add(name: $listName) {
        success
      }
    }
  }
}
    `;
export type CreatePhraseListMutationFn = Apollo.MutationFunction<CreatePhraseListMutation, CreatePhraseListMutationVariables>;

/**
 * __useCreatePhraseListMutation__
 *
 * To run a mutation, you first call `useCreatePhraseListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhraseListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhraseListMutation, { data, loading, error }] = useCreatePhraseListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      listName: // value for 'listName'
 *   },
 * });
 */
export function useCreatePhraseListMutation(baseOptions?: Apollo.MutationHookOptions<CreatePhraseListMutation, CreatePhraseListMutationVariables>) {
        return Apollo.useMutation<CreatePhraseListMutation, CreatePhraseListMutationVariables>(CreatePhraseListDocument, baseOptions);
      }
export type CreatePhraseListMutationHookResult = ReturnType<typeof useCreatePhraseListMutation>;
export type CreatePhraseListMutationResult = Apollo.MutationResult<CreatePhraseListMutation>;
export type CreatePhraseListMutationOptions = Apollo.BaseMutationOptions<CreatePhraseListMutation, CreatePhraseListMutationVariables>;
export const DeletePhraseListDocument = gql`
    mutation DeletePhraseList($slug: String!, $listSlug: String!) {
  Company(slug: $slug) {
    phraseLists {
      remove(listSlug: $listSlug) {
        success
      }
    }
  }
}
    `;
export type DeletePhraseListMutationFn = Apollo.MutationFunction<DeletePhraseListMutation, DeletePhraseListMutationVariables>;

/**
 * __useDeletePhraseListMutation__
 *
 * To run a mutation, you first call `useDeletePhraseListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhraseListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhraseListMutation, { data, loading, error }] = useDeletePhraseListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      listSlug: // value for 'listSlug'
 *   },
 * });
 */
export function useDeletePhraseListMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhraseListMutation, DeletePhraseListMutationVariables>) {
        return Apollo.useMutation<DeletePhraseListMutation, DeletePhraseListMutationVariables>(DeletePhraseListDocument, baseOptions);
      }
export type DeletePhraseListMutationHookResult = ReturnType<typeof useDeletePhraseListMutation>;
export type DeletePhraseListMutationResult = Apollo.MutationResult<DeletePhraseListMutation>;
export type DeletePhraseListMutationOptions = Apollo.BaseMutationOptions<DeletePhraseListMutation, DeletePhraseListMutationVariables>;
export const AssignPhraseDocument = gql`
    mutation AssignPhrase($companySlug: String!, $listSlug: String!, $phraseSlug: [String]!) {
  Company(slug: $companySlug) {
    phraseLists {
      assign(listSlug: $listSlug, phraseSlug: $phraseSlug) {
        success
      }
    }
  }
}
    `;
export type AssignPhraseMutationFn = Apollo.MutationFunction<AssignPhraseMutation, AssignPhraseMutationVariables>;

/**
 * __useAssignPhraseMutation__
 *
 * To run a mutation, you first call `useAssignPhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPhraseMutation, { data, loading, error }] = useAssignPhraseMutation({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *      listSlug: // value for 'listSlug'
 *      phraseSlug: // value for 'phraseSlug'
 *   },
 * });
 */
export function useAssignPhraseMutation(baseOptions?: Apollo.MutationHookOptions<AssignPhraseMutation, AssignPhraseMutationVariables>) {
        return Apollo.useMutation<AssignPhraseMutation, AssignPhraseMutationVariables>(AssignPhraseDocument, baseOptions);
      }
export type AssignPhraseMutationHookResult = ReturnType<typeof useAssignPhraseMutation>;
export type AssignPhraseMutationResult = Apollo.MutationResult<AssignPhraseMutation>;
export type AssignPhraseMutationOptions = Apollo.BaseMutationOptions<AssignPhraseMutation, AssignPhraseMutationVariables>;
export const UnassignPhraseDocument = gql`
    mutation UnassignPhrase($slug: String!, $phraseSlug: String!, $listSlug: String!) {
  Company(slug: $slug) {
    phraseLists {
      unassign(listSlug: $listSlug, phraseSlug: $phraseSlug) {
        success
      }
    }
  }
}
    `;
export type UnassignPhraseMutationFn = Apollo.MutationFunction<UnassignPhraseMutation, UnassignPhraseMutationVariables>;

/**
 * __useUnassignPhraseMutation__
 *
 * To run a mutation, you first call `useUnassignPhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignPhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignPhraseMutation, { data, loading, error }] = useUnassignPhraseMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      phraseSlug: // value for 'phraseSlug'
 *      listSlug: // value for 'listSlug'
 *   },
 * });
 */
export function useUnassignPhraseMutation(baseOptions?: Apollo.MutationHookOptions<UnassignPhraseMutation, UnassignPhraseMutationVariables>) {
        return Apollo.useMutation<UnassignPhraseMutation, UnassignPhraseMutationVariables>(UnassignPhraseDocument, baseOptions);
      }
export type UnassignPhraseMutationHookResult = ReturnType<typeof useUnassignPhraseMutation>;
export type UnassignPhraseMutationResult = Apollo.MutationResult<UnassignPhraseMutation>;
export type UnassignPhraseMutationOptions = Apollo.BaseMutationOptions<UnassignPhraseMutation, UnassignPhraseMutationVariables>;
export const DeleteThesisDocument = gql`
    mutation DeleteThesis($company: String!, $thesis: String!) {
  Company(slug: $company) {
    removeThesis(slug: $thesis) {
      success
    }
  }
}
    `;
export type DeleteThesisMutationFn = Apollo.MutationFunction<DeleteThesisMutation, DeleteThesisMutationVariables>;

/**
 * __useDeleteThesisMutation__
 *
 * To run a mutation, you first call `useDeleteThesisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThesisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThesisMutation, { data, loading, error }] = useDeleteThesisMutation({
 *   variables: {
 *      company: // value for 'company'
 *      thesis: // value for 'thesis'
 *   },
 * });
 */
export function useDeleteThesisMutation(baseOptions?: Apollo.MutationHookOptions<DeleteThesisMutation, DeleteThesisMutationVariables>) {
        return Apollo.useMutation<DeleteThesisMutation, DeleteThesisMutationVariables>(DeleteThesisDocument, baseOptions);
      }
export type DeleteThesisMutationHookResult = ReturnType<typeof useDeleteThesisMutation>;
export type DeleteThesisMutationResult = Apollo.MutationResult<DeleteThesisMutation>;
export type DeleteThesisMutationOptions = Apollo.BaseMutationOptions<DeleteThesisMutation, DeleteThesisMutationVariables>;
export const GetUserDocument = gql`
    query GetUser($login: String!) {
  User(login: $login) {
    login
    type
    companiesSlugs
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  Users {
    login
    type
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;