import React, { useState } from 'react';
import { Input } from 'antd';
import { SearchProps } from 'antd/es/input';

const Search = Input.Search;

export type OnAdd = (simple: string) => Promise<any>;

export type OnAddProp = {
    onAdd: OnAdd;
};

export function AddSimple(
    { onAdd, initialState, ...props }: {
        initialState?: string;
    } &
        OnAddProp & SearchProps,
) {
    const [current, set] = useState(initialState || '');

    async function onSearch() {
        if (!current) return;
        await onAdd(current);
        set('');
    }

    return (
        <Search
            size="large"
            enterButton="Dodaj"
            placeholder="każda firma potrzebuje Visibly "
            {...props}
            value={current}
            onChange={e => set(e.target.value)}
            onSearch={onSearch}
        />
    );
}
