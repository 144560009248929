import { Badge } from 'antd';
import React from 'react';
import { News } from '../../generated/graphql';

interface INewsTabBadgeProps {
    news: News[];
}

interface ITabBadgeWrapperProps {
    count?: number;
    title: string;
}

export function TabBadgeWrapper(props: ITabBadgeWrapperProps) {
    if (props.count) {
        return <Badge
            count={props.count}
            overflowCount={9999}
        >
            <span style={{ paddingRight: '20px' }}>{props.title}</span>
        </Badge>;
    }

    return <span>{props.title}</span>;
}

export function NewsTabBadge(props: INewsTabBadgeProps) {
    return <TabBadgeWrapper title="News" count={props.news.length} />;
}
