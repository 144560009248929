import { ResponsiveBar } from '@nivo/bar';
import React, { useContext, useEffect, useState } from 'react';
import { CompanyProp, CompanySlugProp, SessionProp } from '../api';
import { MaybeDateRangeProp } from '../DatePanel';
import { flatten, groupBy, reverse, sortBy } from 'lodash';
import { range } from '../phrase/Plot';
import { Card, Dropdown, Menu, Spin } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { GetPhraseQueryVariables, useGetPhraseQuery } from '../generated/graphql.d';
import { abc } from '../company/CompanyPhrasesReport';
import { DashboardContextualCardProps } from '../common';
import { DateRangeContext } from '../context/DateRange';
import { useFilter } from '../company/Cards/useFilter';
import { CompanyContext } from '../context/CompanyContext';

export interface ITOP10PositionsElementProps extends MaybeDateRangeProp {
    maxPosition?: number;
}

export function TOP10PositionsElement(props: ITOP10PositionsElementProps) {
    const companyContext = useContext(CompanyContext);
    const filter = useFilter();
    const variables: GetPhraseQueryVariables = {
        slug: companyContext.companySlug,
        // @ts-ignore
        from: props.dateRange[0],
        // @ts-ignore
        to: props.dateRange[1],
        filter,
    };

    const baseOptions = {
        variables,
    };

    const [maxPosition, setMaxPosition] = useState(props.maxPosition || 10);

    useEffect(() => {
        setMaxPosition(props.maxPosition || 10);
    }, [props.maxPosition]);

    const phraseStats = useGetPhraseQuery(baseOptions);
    const company = phraseStats.data?.Company;
    const phrase = company ? abc(company.phrases, company.phrasesStats) : [];

    const arts = flatten(
        phrase
            .filter(i => i.ranks)
            .map(i => i.ranks.split(',').map(i => i.trim()).filter(i => {
                const rank = Number.parseInt(i, 10);
                return Number.isInteger(rank) && rank <= maxPosition;
            })),
    );

    const emptyArray: {[key: string]: any[]} = {};
    for (let i = 1; i <= maxPosition; i++) {
        emptyArray[`${i}`] = [];
    }

    const groups = Object.assign(
        emptyArray,
        groupBy(arts, (i: any) => i),
    );

    const prepared = Object.entries(groups).map(([k, v], c) => ({
        id: k,
        value: v.length,
        color: '#00abe8', // getColor(10, c),
    }));

    const sorted = reverse(sortBy(prepared, i => parseInt(i.id, 10)));

    const switchMaxPosition = () => {
        setMaxPosition(maxPosition === 10 ? 20 : 10);
    };

    return (
        <Spin spinning={phraseStats.loading}>
            <div style={{ height: '70vh' }}>
                <ContentMassPlot data={sorted} />
            </div>
        </Spin>
    );
}

export function TOP10Positions(
    props: SessionProp & CompanySlugProp & MaybeDateRangeProp & CompanyProp,
) {
    return <TOP10PositionsElement {...props} />;
}

export function TOP10PositionsCard(props: DashboardContextualCardProps) {
    const [maxPosition, setMaxPosition] = useState(10);
    const context = useContext(DateRangeContext);

    const switchMaxPosition = () => {
        setMaxPosition(maxPosition === 10 ? 20 : 10);
    };

    const menu = <Menu>
        {[10, 20].filter(item => item !== maxPosition).map((item) => (<Menu.Item onClick={switchMaxPosition}>
            Top {item}
        </Menu.Item>))}
    </Menu>;

    return (
        <Card
            title={maxPosition === 10 ? "TOP10 Positions" : "TOP20 Positions"}
            extra={<Dropdown overlay={menu} trigger={['click']}>
                <SettingOutlined />
            </Dropdown>}
            type={"inner"}
        >
            <TOP10PositionsElement
                dateRange={[context.dateRange.from, context.dateRange.to]}
                maxPosition={maxPosition}
            />
        </Card>
    );
}

const BarComponent = (props: any) => {
    const position = props.data.indexValue === 10 ? 100 : 110;
    return (
        <g transform={`translate(${props.x},${props.y})`}>
            <rect
                x={-3}
                y={7}
                width={props.width}
                height={props.height}
                fill="rgba(0, 0, 0, .07)"
            />
            <rect
                width={props.width}
                height={props.height}
                fill={props.data.data.color}
            />
            <rect
                x={props.width - 5}
                width={5}
                height={props.height}
                fill={props.borderColor}
                fillOpacity={0.2}
            />
            <text
                x={props.width + 25}
                y={props.height / 2}
                textAnchor="end"
                dominantBaseline="central"
                fill={props.borderColor}
                style={{
                    fontWeight: 40,
                    fontSize: 30,
                }}
            >
                x
            </text>
            <text
                x={props.width + 75 - (props.data.value < 10 ? 25 : 0)}
                y={props.height / 2 - 1}
                textAnchor="end"
                dominantBaseline="central"
                fill="black"
                style={{
                    fontWeight: 400,
                    fontSize: 38,
                }}
            >
                {props.data.value}
            </text>
            <text
                x={-140 + position}
                y={props.height / 2 - 1}
                textAnchor="beginning"
                dominantBaseline="central"
                fill="black"
                style={{
                    fontWeight: 500,
                    fontSize: 20,
                }}
            >
                {props.data.indexValue}
            </text>
        </g>
    );
};

export function getColor(dataLength: any, c: number) {
    return `hsl(${196 + range(0, 60, dataLength, c)}, 100%,${range(
        50,
        80,
        dataLength,
        c,
    )}%)`;
}

function ContentMassPlot(props: {
    data: { id: string; value: number; color: string }[];
}) {
    return (
        <ResponsiveBar
            layout="horizontal"
            margin={{ top: 26, right: 100, bottom: 26, left: 55 }}
            data={props.data}
            indexBy="id"
            keys={['value']}
            colors={{ scheme: 'purples' }}
            // @ts-ignore
            colorBy="indexValue"
            borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
            enableGridX
            enableGridY={false}
            axisLeft={null}
            axisBottom={null}
            padding={0.3}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
            isInteractive={false}
            barComponent={BarComponent}
            motionStiffness={170}
            motionDamping={26}
        />
    );
}
