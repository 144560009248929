import React from 'react';
import { CompareCard } from '../../company/Cards/CompareCard';
import { useGetKeywordsForArticleQuery } from '../../generated/graphql.d';
import { useArticleId } from '../useArticleId';
import { useArticleBaseOptions } from './useArticleBaseOptions';

export const KeywordsCard = () => {
    const articleId = useArticleId();

    const baseOptions = useArticleBaseOptions(articleId);
    const { data, loading } = useGetKeywordsForArticleQuery(baseOptions);

    return (<CompareCard value={data?.Company?.article.keywords.length || 0}
                         title={'Keywords'}
                         loading={loading} />);
};