import React, { useContext } from 'react';
import { DatePicker, Form, Input, Modal } from 'antd';
import { useAddArticleMutation } from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';

interface ArticleData {
    title: string;
    content: string;
}

export function ArticleForm(props: { visible: boolean, onClose?: () => void}) {
    const company = useContext(CompanyContext);
    const [form] = Form.useForm();

    const [add] = useAddArticleMutation({
        refetchQueries: ['GetArticles']
    })

    const handleCancel = () => {
        form.resetFields();
        props.onClose?.();
    };

    const handleOk = async () => {
        const values = form.getFieldsValue();
        try {
            await add({
                variables: {
                    title: values.title,
                    content: values.content,
                    slug: company.companySlug,
                    author: values.author,
                    date: values.date,
                }
            })
        } catch (e) {
            console.log(e);
        }
        form.resetFields();
        props.onClose?.();
    };

    return <Modal title="Dodaj nowy artykuł" visible={props.visible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form}>
            <Form.Item name="title" label="Tytuł" required={true}>
                <Input />
            </Form.Item>
            <Form.Item name="author" label="Autor" required={true}>
                <Input />
            </Form.Item>
            <Form.Item name="date" label="Data">
                <DatePicker />
            </Form.Item>
            <Form.Item name="content" label="Treść" required={true}>
                <Input.TextArea />
            </Form.Item>
        </Form>
    </Modal>;
}