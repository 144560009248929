import { Button } from 'antd';
import React, { useCallback, useContext } from 'react';
import { useDeletePhraseListMutation } from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';
import { TabListContext } from '../context/ListContext';

export function RemovePhraseList() {
    const company = useContext(CompanyContext);
    const list = useContext(TabListContext);
    const [removePhraseList] = useDeletePhraseListMutation();

    const onClick = useCallback(async () => {
        await removePhraseList({
            variables: {
                slug: company.companySlug,
                listSlug: list.activeList,
            },
            refetchQueries: ['GetCompanyPhraseLists'],
        });
        list.setActiveList('all');
    }, []);

    return ['all', 'favourite'].indexOf(list.activeList) === -1
        ? <Button onClick={onClick}>Usuń listę</Button>
        : null;
}