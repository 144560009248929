import {Link} from '../api';
import local from '../helpers/time/local';
import {Menu, Dropdown} from 'antd';
import { ClickParam } from 'antd/es/menu';
import OpenCSV, { COL_SEPARATORS } from '../open/open-csv';
import React from 'react';

export interface LinksCSVDownloadProps {
    companySlug: string;
    links: Link[];
    label?: string;
}

export function LinksCSVDownload(props: LinksCSVDownloadProps) {
    const handleExport = (separator: string) => {
        const table = props.links.map(i => [
                local(i.date, 'yyyy-MM-dd'),
                `${i.editable}`,
                `${i.status}`
            ]
        );
        const fileName = `linki_dla_${props.companySlug}.csv`;
        OpenCSV(fileName, table, undefined, separator);
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleExport(COL_SEPARATORS.COMMA);
    };

    const handleDropdownClick = (param: ClickParam) => {
        handleExport(param.key);
    };

    const menu = (
        <Menu onClick={handleDropdownClick}>
            <Menu.Item key={COL_SEPARATORS.COMMA}>Oddzielane przecinkiem</Menu.Item>
            <Menu.Item key={COL_SEPARATORS.SEMICOLON}>Oddzielane średnikiem</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown.Button overlay={menu} onClick={handleClick}>{props.label ? props.label : 'Pobierz CSV'}</Dropdown.Button>
    )
}
