import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { Links } from './Links';
import Users from './user/Users';
import User from './user/User';
import { Imm } from './partner/Imm';
import { NoSession, Session } from './session';
import { CompanyRouter } from './company/CompanyRouter';
import { Jobs } from './job/Jobs';
import { PhraseRoute } from './phrase/Phrase';
import { MyMenu } from './MyMenu';
import { DatePanel, DateRange } from './DatePanel';
import { ReportPage } from './company/ReportPage';
import { gql, useQuery } from '@apollo/client';
import { Loading } from './Loading';
import { Dashboard } from './company/Dashboard';
import { DateRangeContext, DateRangeContextProvider } from './context/DateRange';
import { CompanyContextProvider } from './context/CompanyContext';
import { FiltersContextProvider } from './context/FiltersContext';
import { SessionContext, SessionContextProvider } from './context/SessionContext';
import { DashboardContextProvider } from './context/DashboardContext';

const { Content, Footer, Sider } = Layout;

function ContentWrapper(props: { onChange?: React.Dispatch<React.SetStateAction<DateRange>> } & { children: React.ReactNode }) {
    return <Content style={{ margin: '24px 16px 0' }}>
        <DatePanel
            onChange={range => {
                props.onChange?.(range);
            }}
        />
        <div
            style={{
                padding: 24,
                minHeight: 360,
            }}
        >
            {props.children}
        </div>
    </Content>;
}

function RealMain(props: { session: Session }) {
    const context = useContext(DateRangeContext);
    const sessionContext = useContext(SessionContext);
    const dateRange: DateRange = [context.dateRange.from, context.dateRange.to];

    useEffect(() => {
        sessionContext.setUserType(props.session.user.type);
    }, [props.session.user.type]);

    return <Switch>
        <Route exact path='/'>
            <ContentWrapper>Cześć!</ContentWrapper>
        </Route>
        <Route exact path='/user/'>
            <ContentWrapper><Users session={props.session} /></ContentWrapper>
        </Route>
        <Route path='/user/settings' exact>
            <ContentWrapper><UserSettings /></ContentWrapper>
        </Route>
        <Route path='/user/:login'>
            <ContentWrapper><User session={props.session} /></ContentWrapper>
        </Route>
        <Route path='/links'>
            <ContentWrapper><Links /></ContentWrapper>
        </Route>
        <Route exact path='/company/:company'>
            <ContentWrapper>
                <CompanyRouter
                    session={props.session}
                    dateRange={dateRange}
                />
            </ContentWrapper>
        </Route>
        <Route exact path='/company/:company/dashboard'>
            <div style={{ padding: '24px' }}>
                <Dashboard />
            </div>
        </Route>
        <Route exact path='/company/:company/:tab'>
            <ContentWrapper>
                <CompanyRouter
                    session={props.session}
                    dateRange={dateRange}
                />
            </ContentWrapper>
        </Route>
        <Route exact path='/company/:company/phrase/:phrase'>
            <ContentWrapper>
                <CompanyRouter
                    session={props.session}
                    dateRange={dateRange}
                />
            </ContentWrapper>
        </Route>
        <Route exact path='/company/:company/article/:articleId'>
            <div style={{ padding: '24px' }}>
                <CompanyRouter
                    session={props.session}
                    dateRange={dateRange}
                />
            </div>
        </Route>
        <Route path='/company/:company/report'>
            <ContentWrapper>
                <ReportPage
                    session={props.session}
                    dateRange={dateRange}
                />
            </ContentWrapper>
            </Route>
        <Route path='/phrase/:phrase'>
            <ContentWrapper>
                <PhraseRoute
                    session={props.session}
                    dateRange={dateRange}
                />
            </ContentWrapper>
        </Route>
        <Route path='/partner/imm'>
            <ContentWrapper>
                <Imm />
            </ContentWrapper>
        </Route>
        <Route path='/job'>
            <ContentWrapper>
                <Jobs />
            </ContentWrapper>
        </Route>
        </Switch>;
}

const GET_COMPANIES = gql`
    query GetCompanies {
        Companies {
            list {
                name
                slug
            }
        }
    }
`;
function Main(props: {
    session: Session;
    logout: (noSession: NoSession) => void;
}) {
    const { loading, error, data } = useQuery(GET_COMPANIES);

    if (loading) return <Loading/>;
    if (error) return <div>Error</div>;

    function handleLogout() {
        props.logout(new NoSession('button logout'));
    }

    const pathname = window.location.pathname;
    const key = pathname === '/' ? '/phrase' : pathname;

    return (
        <Router>
            <Layout style={{ minHeight: '100vh' }}>
                {/\/report$/.test(pathname) ? (
                    ''
                ) : (
                    <Sider breakpoint="sm" collapsedWidth="0">
                        <div className="logo"/>
                        <MyMenu
                            key={key}
                            session={props.session}
                            logOut={handleLogout}
                            companies={data.Companies.list}
                        />
                    </Sider>
                )}
                <Layout>
                    <DateRangeContextProvider>
                        <CompanyContextProvider>
                            <FiltersContextProvider>
                                <DashboardContextProvider>
                                    <SessionContextProvider>
                                        <RealMain session={props.session} />
                                    </SessionContextProvider>
                                </DashboardContextProvider>
                            </FiltersContextProvider>
                        </CompanyContextProvider>
                    </DateRangeContextProvider>
                    <Footer style={{ textAlign: 'center' }}>
                        2020 ©{' '}
                        <a href='https://lightscape.pl'>
                            Lightscape sp. z o.o.
                        </a>
                    </Footer>
                </Layout>
            </Layout>
        </Router>
    );
}

function UserSettings() {
    return <h2>User Settings</h2>;
}

export default Main;
