import { NewsProp, SessionProp } from '../../api';
import React, { useContext } from 'react';
import { PropagateUpdate } from '../../helpers/PropagateUpdate';
import { Result, Tabs } from 'antd';
import { DateRangeProp } from '../../DatePanel';
import { PhrasesTab } from './PhrasesTab';
import { createBrowserHistory } from 'history';
import { ThesisTab } from './ThesisTab';
import { LinksTabBadge } from './LinksTabBadge';
import { LinksTab } from './LinksTab';
import { NewsTabBadge } from './NewsTabBadge';
import { NewsTab } from './NewsTab';
import { EventsTab } from '../../events/EventsTab';
import { Link as LinkRouter } from 'react-router-dom';
import graphql, {
    GetPhrasesAndThesisQueryVariables,
    Link,
    useGetPhrasesAndThesisQuery,
} from '../../generated/graphql.d';
import './company.css';
import { MediaTab } from '../../MediaTab';
import { DateRangeContext } from '../../context/DateRange';
import { CompanyContext } from '../../context/CompanyContext';
import { TabListContext, TabListProvider } from '../../context/ListContext';
import { ArticlesTab } from './ArticlesTab';

function PhrasesQL(props: DateRangeProp & SessionProp) {
    const dateContext = useContext(DateRangeContext);
    const companyContext = useContext(CompanyContext);
    const listContext = useContext(TabListContext);

    const variables: GetPhrasesAndThesisQueryVariables = {
        slug: companyContext.companySlug,
        from: dateContext.dateRange.from,
        to: dateContext.dateRange.to,
    };

    if (listContext.activeList && listContext.activeList !== 'all') {
        variables.listSlug = listContext.activeList;
    }

    const { loading, error, data, refetch } = useGetPhrasesAndThesisQuery({ variables });

    if (error) return <Result
        status='error'
        title='Wystąpił błąd podczas ładowania danych'
    />;

    const matcher = {};
    data?.Company?.phrasesStats.forEach((i: any) => {
        // @ts-ignore
        matcher[i.slug] = i;
    });


    const phrases = data?.Company?.phrases.map((i: any) => {
        // @ts-ignore
        const stats = matcher[i.slug] || {};
        return ({ ...i, stats, ranks: stats.positions?.join(', ') || '' });
    }) || [];

    return (
        <PhrasesTab
            // @ts-ignore
            theses={data?.Company?.theses || []}
            // company={company}
            dateRange={props.dateRange}
            // @ts-ignore
            data={phrases}
            refresh={refetch}
            session={props.session}
            loading={loading}
        />
    );
}

export interface ICompanyPageProps extends NewsProp, SessionProp, PropagateUpdate, DateRangeProp {
    tab: string;
    company: Pick<graphql.Company, 'slug' | 'name'>;
}

export function CompanyPage(
    props: ICompanyPageProps,
) {
    // let links = props.company.links;
    let links: Link[] = [];
    const history = createBrowserHistory();

    const onTabChange = (tab: string) => {
        if (tab !== 'dashboard') {
            history.push(`/company/${props.company.slug}/${tab}`);
        }
    };

    return (
        <div>
            <h2>Firma: {props.company.name}</h2>
            <Tabs
                defaultActiveKey={props.tab}
                onChange={onTabChange}
            >
                <Tabs.TabPane tab={<LinkRouter to={`/company/${props.company.slug}/dashboard`}>Dashboard</LinkRouter>}
                              key='dashboard' />
                <Tabs.TabPane tab='Cele' key='thesis'>
                    <ThesisTab
                        session={props.session}
                        companySlug={props.company.slug}
                        dateRange={props.dateRange}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Frazy' key='phrases'>
                    <TabListProvider>
                        <PhrasesQL
                            dateRange={props.dateRange}
                            session={props.session}
                        />
                    </TabListProvider>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<LinksTabBadge links={links} />} key='links'>
                    <LinksTab
                        company={props.company}
                        session={props.session}
                        propagateUpdate={props.propagateUpdate}
                        dateRange={props.dateRange}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<NewsTabBadge news={props.news} />} key='news'>
                    <NewsTab
                        session={props.session}
                        companySlug={props.company.slug}
                        dateRange={props.dateRange}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Media' key='media'>
                    <MediaTab company={props.company} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Artykuły' key='articles'>
                    <ArticlesTab />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Event log' key='event_log'>
                    <EventsTab company={props.company} dateRange={props.dateRange} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}
