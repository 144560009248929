import { Link } from 'react-router-dom';
import { Phrase } from '../phrase/Phrase';
import React from 'react';
import { SessionProp } from '../api';
import { MaybeDateRangeProp } from '../DatePanel';
import graphql from '../generated/graphql.d';

export interface ICompanyPhraseSubpageProps extends SessionProp, MaybeDateRangeProp {
    phrase: string;
    company?: Pick<graphql.Company, 'slug' | 'name'> | null;
}

export function CompanyPhraseSubpage(props: ICompanyPhraseSubpageProps) {
    // const urls = props.company?.links.filter(i => i.active).map(i => i.link);
    const urls: string[] = [];
    return (
        <div>
            <Link to={'/company/' + props.company?.slug}>
                <h2>&lt; Firma: {props.company?.name}</h2>
            </Link>

            <Phrase
                dateRange={props.dateRange}
                companySlug={props.company?.slug}
                phrase={props.phrase}
                companyUrls={urls}
                session={props.session}
            />
        </div>
    );
}
