import { useParams } from 'react-router';
import { CompanyParams } from './CompanyRouter';
import React, { useContext, useEffect, useState } from 'react';
import { useGetCompanyDetailsQuery } from '../generated/graphql.d';
import { Button, Col, DatePicker, Descriptions, PageHeader, Result, Row, Spin } from 'antd';
import { ContentMassPlotElement } from '../markers/ContentMassPlot';
import { PhrasesTableCard } from './CompanyPhrasesReport';
import { DropdownMenu } from './DashboardMenu';
import { dateToLocal } from '../helpers/time/dateToLocal';
import { AveCard } from './Cards/Ave';
import { ContentMassCard } from './Cards/ContentMass';
import { TrafficCard } from './Cards/TrafficCard';
import { DateRangeContext } from '../context/DateRange';
import { TOP10PositionsCard } from '../markers/TOP10Positions';
import { ThesisMetricCard } from '../thesis/ThesisMetricCard';
import { NewsCard } from '../news/NewsCard';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { TopLinksCard } from './Cards/TopLinksCard';
import { TopDomainsCard } from './Cards/TopDomainsCard';
import { CompanyContext } from '../context/CompanyContext';
import { DashboardPhraseListFilters } from './DashboardPhraseListFilters';
import { FiltersContext } from '../context/FiltersContext';
import { DashboardMediaListFilters } from './DashboardMediaListFilters';
import { SimilarQuestionsCard } from './Cards/SimilarQuestionsCard';
import { TopArticlesCard } from './Cards/TopArticlesCard';
import { ArticleActivitiesCard } from './Cards/ArticleActivitiesCard';
import { ReachCard } from './Cards/ReachCard';
import { DashboardContext } from '../context/DashboardContext';

const { RangePicker } = DatePicker;

export function DateRangePicker(props: { from: Date, to: Date, onChange: (from: Date, to: Date) => void }) {
    const [closed, setClose] = useState(true);

    const onChange = (dates: RangeValue<Moment>, dateStrings: [string, string]) => {
        // @ts-ignore
        props.onChange(dates[0].toDate(), dates[1].toDate());
        setClose(true);
    };

    const onBlur = () => {
        setClose(true);
    };

    return <React.Fragment>
        {closed ? <div
                onClick={() => setClose(false)}>{dateToLocal(props.from.toISOString(), 'yyyy-MM-dd')} - {dateToLocal(props.to.toISOString(), 'yyyy-MM-dd')}</div>
            : <RangePicker picker={'date'} defaultValue={[moment(props.from), moment(props.to)]} allowClear={false}
                           allowEmpty={[false, false]} onChange={onChange} onBlur={onBlur} />}
    </React.Fragment>;
}

export function Dashboard() {
    const params = useParams() as CompanyParams;
    const context = useContext(DateRangeContext);
    const companyContext = useContext(CompanyContext);
    const filtersContext = useContext(FiltersContext);
    const dashboardContext = useContext(DashboardContext);

    const showPhraseListFilters = filtersContext.filters.phraseLists.length > 0;
    const showMediaListFilters = filtersContext.filters.mediaLists.length > 0;

    useEffect(() => {
        companyContext.setCompanySlug(params.company);
    }, [params.company]);

    const baseOptions = {
        variables: {
            slug: params.company,
            from: context.dateRange.from,
            to: context.dateRange.to,
        },
    };

    const { loading, error, data } = useGetCompanyDetailsQuery(baseOptions);

    const onDateChange = (from: Date, to: Date): void => {
        context.setDateRange({ from, to }, context.previousDateRange);
    };

    const onCompareDateChange = (from: Date, to: Date): void => {
        context.setDateRange(context.dateRange, { from, to });
    };

    if (error) {
        return (<Result
            status='warning'
            title='Wystąpił problem podczas pobierania danych :-('
        />);
    }

    return (
        <Spin spinning={loading}>
            {data?.Company && <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <PageHeader
                            ghost={false}
                            title={data.Company.name}
                            extra={[
                                <Button><Link to={`/company/${params.company}/thesis`}>Cele</Link></Button>,
                                <Button><Link to={`/company/${params.company}/phrases`}>Frazy</Link></Button>,
                                <Button><Link to={`/company/${params.company}/links`}>Linki</Link></Button>,
                                <Button><Link to={`/company/${params.company}/media`}>Media</Link></Button>,
                                <Button><Link to={`/company/${params.company}/news`}>Newsy</Link></Button>,
                                <Button><Link to={`/company/${params.company}/articles`}>Artykuły</Link></Button>,
                                <Button><Link to={`/company/${params.company}/event_log`}>Zdarzenia</Link></Button>,
                                <DropdownMenu key='more' />,
                            ]}>
                            <Descriptions size='small' column={1}>
                                <Descriptions.Item label='Okres analizy'>
                                    <DateRangePicker from={context.dateRange.from} to={context.dateRange.to}
                                                     onChange={onDateChange} />
                                </Descriptions.Item>
                                {context.compare && <Descriptions.Item label='Okres porównawczy'>
                                    <DateRangePicker from={context.previousDateRange.from}
                                                     to={context.previousDateRange.to} onChange={onCompareDateChange} />
                                </Descriptions.Item>}
                                {showPhraseListFilters && <Descriptions.Item label={'Lista fraz'}>
                                    <DashboardPhraseListFilters />
                                </Descriptions.Item>}
                                {showMediaListFilters && <Descriptions.Item label={'Lista mediów'}>
                                    <DashboardMediaListFilters />
                                </Descriptions.Item>}
                            </Descriptions>
                        </PageHeader>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <ContentMassCard />
                    </Col>

                    <Col span={6}>
                        <TrafficCard />
                    </Col>

                    <Col span={6}>
                        <AveCard />
                    </Col>

                    <Col span={6}>
                        <ReachCard />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <ContentMassPlotElement
                            company={data.Company}
                        />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <TopLinksCard />
                    </Col>
                    <Col span={12}>
                        <TopDomainsCard />
                    </Col>
                </Row>

                {dashboardContext.showArticles && <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TopArticlesCard />
                    </Col>
                </Row>}

                {dashboardContext.showArticles && <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <ArticleActivitiesCard />
                    </Col>
                </Row>}

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <PhrasesTableCard
                            company={data.Company}
                        />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TOP10PositionsCard
                            company={data.Company}/>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <ThesisMetricCard
                            company={data.Company} />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <NewsCard
                            company={data.Company} />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <SimilarQuestionsCard />
                    </Col>
                </Row>
            </>}
        </Spin>
    );
}
