import React, { useContext, useMemo } from 'react';
import { Button, message, Modal, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Thesis, useDeleteThesisMutation } from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';

export interface TThesis extends Pick<Thesis, 'text' | 'slug'> {
    // text: string;
    // slug: string;
    direct: number;
    indirect: number;
    companySlug: string;
}

export interface IThesisTableProps {
    theses: TThesis[];
}

const { confirm } = Modal;

function ThesisDeleteButton(props: { thesis: TThesis }) {
    const company = useContext(CompanyContext);

    const [del] = useDeleteThesisMutation();

    const onClick = () => {
        confirm({
            title: 'Czy na pewno chcesz usunąć cel biznesowy?',
            icon: <ExclamationCircleOutlined />,
            // content: props.thesis.text,
            async onOk() {
                try {
                    const ret = await del({
                        variables: {
                            thesis: props.thesis.slug,
                            company: company.companySlug,
                        },
                    });
                    if (ret.data?.Company?.removeThesis?.success) {
                        message.info('Cel usunięto');
                    } else {
                        throw new Error('thesis not deleted');
                    }
                } catch (e) {
                    console.error(e);
                    message.error('Nie udało się usunąć celu');
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return <Button icon={<DeleteOutlined />} onClick={onClick} />;
}

export function ThesisTable(props: IThesisTableProps) {
    const data = useMemo(() => {
        return props.theses.filter(thesis => thesis.text);
    }, [props.theses]);

    const columns: ColumnProps<TThesis>[] = [
        {
            title: 'Cel',
            dataIndex: 'text',
            key: 'text',
            sorter: (a, b) => a.text.localeCompare(b.text),
        },
        {
            title: 'Całkowity Ruch',
            dataIndex: 'text',
            key: 'text',
            render: (_, i) => i.direct + i.indirect,
            sorter: (a, b) =>
                (a.indirect + a.direct || 0) - (b.indirect + b.direct || 0),

        },
        {
            title: 'Akcje',
            dataIndex: 'text',
            key: 'text',
            render: (_, i) => <ThesisDeleteButton thesis={i} />,
        },
    ];
    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey='slug'
            pagination={{
                // @ts-ignore
                position: 'both',
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '25', '50', '100'],
            }}
        />
    );
}
