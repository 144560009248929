import React, { useContext } from 'react';
import { DashboardContextualCardProps } from '../common';
import { useGetNewsQuery, News } from '../generated/graphql.d';
import { DateRangeContext } from '../context/DateRange';
import { Spin, Card } from 'antd';

export const NewsCard = (props: DashboardContextualCardProps) => {
    const context = useContext(DateRangeContext);
    const {loading, data} = useGetNewsQuery({
        variables: {
            slug: props.company.slug,
            from: context.dateRange.from,
            to: context.dateRange.to
        }
    });

    const news: News[] = [];
    data?.Company?.news.forEach((i) => {
        // @ts-ignore
        news.push(i);
    });

    return (<Card
        title={news.length > 0 ? `Newsy` : `Znalezione newsy`}
        type={'inner'}
    >
        <Spin spinning={loading}>
            {news.length > 0 && <ul>
                {news.map((i) => <li>{i.phrase}</li>)}
            </ul>}
            {news.length === 0 && <span>Brak newsów w wybranym okresie</span>}
        </Spin>
    </Card>);
}
