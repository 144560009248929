import { DashboardCardProp, DashboardContextualCardProps } from '../common';
import { Thesis, useGetThesisQuery } from '../generated/graphql.d';
import { TThesis } from './ThesisTable';
import { Card, Spin } from 'antd';
import { ThesisMetricChart } from './ThesisMetricChart';
import React, { useContext } from 'react';
import { DateRangeContext } from '../context/DateRange';
import { useFilter } from '../company/Cards/useFilter';

export function ThesisMetric(props: DashboardCardProp) {
    const filter = useFilter();
    const { loading, data, error } = useGetThesisQuery({
        variables: {
            companySlug: props.company.slug,
            // @ts-ignore
            from: props.dateRange[0].toISOString(),
            // @ts-ignore
            to: props.dateRange[1].toISOString(),
            filter,
        },
    });

    const theses: TThesis[] = [];
    data?.Company?.theses.forEach((i: Pick<Thesis, 'slug' | 'companySlug' | 'text'>) => {
        const stats = data?.Company?.thesesStats.find((stat) => stat.slug === i.slug);
        if (stats) {
            theses.push({
                direct: stats.direct,
                indirect: stats.indirect,
                ...i
            });
        }
    });

    return (
        <Spin spinning={loading}>
            {theses.map(thesis => (
                <ThesisMetricChart key={thesis.slug} thesis={thesis}/>
            ))}
        </Spin>
    );
}

export function ThesisMetricCard(props: DashboardContextualCardProps) {
    const context = useContext(DateRangeContext);

    return (<Card
        title={'Zasięg kampanii / celu komunikacyjnego'}
        type={'inner'}
    >
        <ThesisMetric
            company={props.company}
            dateRange={[context.dateRange.from, context.dateRange.to]}
        />
    </Card>)
}
