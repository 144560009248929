import { endOfDay, format, subDays } from 'date-fns';
import logo from '../visibly_logo.svg';
import { CompanyPhraseReportElement, PhrasesTableReportElement } from './CompanyPhrasesReport';
import React from 'react';
import { DateRangeProp, MaybeDateRangeProp } from '../DatePanel';
import { CompanyProp, NewsProp } from '../api';
import ContentMass from '../markers/ContentMass';
import { TOP10PositionsElement } from '../markers/TOP10Positions';
import { Company, useGetCompanyPhrasesAndThesisQuery } from '../generated/graphql.d';
import { WatermarkWrapper } from './Watermark';
import { ThesisMetric } from '../thesis/ThesisMetricCard';

export interface IReportFetcherProps extends DateRangeProp {
    company: Pick<Company, 'slug'>;
}

export function ReportFetcher(props: IReportFetcherProps) {
    const { loading, error, data } = useGetCompanyPhrasesAndThesisQuery( {
        variables: {
            slug: props.company.slug,
            from: props.dateRange[0].toISOString(),
            to: props.dateRange[1].toISOString(),
        },
    });

    if (loading) return <div>Loading</div>;
    if (error) return <div>Error</div>;

    // const matcher = {};
    // data?.Company?.phrasesStats.forEach((i: any) => {
    //     // @ts-ignore
    //     matcher[i.slug] = i;
    // });
    //
    // const phrases: any = data?.Company?.phrases.map((i: any) => {
    //     // @ts-ignore
    //     const stats = matcher[i.slug] || {};
    //     return { ...i, stats, ranks: stats.positions?.join(', ') || '' };
    // });

    // @ts-ignore
    const news: News[] = [];
    // @ts-ignore
    data.Company.news.forEach((i) => {
        news.push(i);
    });

    // @ts-ignore
    return (
        <Report
            // @ts-ignore
            company={data.Company}
            dateRange={props.dateRange}
            news={news}
        />
    );
}

export function Report(
    props: MaybeDateRangeProp & CompanyProp & NewsProp,
) {
    const from = props.dateRange
        ? props.dateRange[0]
        : subDays(endOfDay(new Date()), 31);
    const to = props.dateRange ? props.dateRange[1] : endOfDay(new Date());

    return (
        <>
            <div
                style={{
                    position: 'relative',
                    textAlign: 'right',
                    color: 'rgb(48, 16, 145)',
                    fontSize: 24,
                }}
            >
                <img
                    src={logo}
                    style={{
                        position: 'absolute',
                        height: '80px',
                        top: 0,
                        left: 0,
                    }}
                    alt="logo"
                />

                <div>{format(from, 'yyyy-MM-dd')}</div>
                <div>{format(to, 'yyyy-MM-dd')}</div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>

            <ContentMass
                company={props.company}
                dateRange={props.dateRange}
                report={true}
            />

            <br/>
            <br/>
            <br/>
            <br/>

            <WatermarkWrapper>
                <CompanyPhraseReportElement
                    company={props.company}
                    dateRange={props.dateRange}
                />

                <PhrasesTableReportElement
                    company={props.company}
                    dateRange={props.dateRange}
                />
            </WatermarkWrapper>

            <br/>
            <br/>
            <br/>
            <br/>

            <WatermarkWrapper>
                <TOP10PositionsElement
                    dateRange={props.dateRange}
                />
            </WatermarkWrapper>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            <WatermarkWrapper>
                <h2>Zasięg kampanii / celu komunikacyjnego:</h2>
                <ThesisMetric
                    company={props.company}
                    dateRange={props.dateRange}
                />
            </WatermarkWrapper>

            <div>
                <h2 style={{ fontWeight: 400 }}>News {props.news.length}</h2>
                {props.news.length > 0 && <ul>
                    {props.news.map((i) => <li>{i.phrase}</li>)}
                </ul>}
            </div>
        </>
    );
}
