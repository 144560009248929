import React from 'react';
import { useBaseOptions } from './useBaseOptions';
import { useGetSimilarQuestionQuery } from '../../generated/graphql.d';
import Card from 'antd/lib/card';
import List from 'antd/lib/list';
import Spin from 'antd/lib/spin';

export const SimilarQuestionsCard = () => {
    const baseOptions = useBaseOptions();

    const { loading, data } = useGetSimilarQuestionQuery(baseOptions);

    return <Card title='Podobne pytania'>
        <Spin spinning={loading}>
            <List
                dataSource={data?.Company?.stats.similarQuestions || []}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            description={item!.phrase}
                            title={item!.question} />
                        <a href={item!.link}>{item!.link}</a>
                    </List.Item>
                )} />
        </Spin>
    </Card>;
};