import React from 'react';
import { useParams } from 'react-router';
import * as api from '../api';
import { SessionProp } from '../api';
import { Button, Form, Input, Radio, Select } from 'antd';
import {
    Company,
    useGetCompaniesQuery,
    useGetUserQuery,
    User as UserType,
    UserUpdateParams,
    useUpdateUserMutation,
} from '../generated/graphql.d';
import { FormInstance } from 'antd/lib/form';

const { Option } = Select;
interface IUser {
    login: string;
    type: string;
    pass: string;
    companiesSlugs?: string[];
}

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

interface IUserFormProps {
    editable?: boolean;
    onSubmit: (user: IUser) => Promise<any>;
    // user?: IUser;
    data?: Pick<UserType, 'type' | 'login' | 'companiesSlugs'> | null;
    companies?: Pick<Company, 'slug' | 'name'>[];
    form?: FormInstance;
}

const UserForm = (props: IUserFormProps) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        if (values.login && (values.pass || props.editable)) {
            props.onSubmit({ ...values });
        }
    };

    React.useEffect(() => {
        // console.log('React.useEffect', props);
        form.setFieldsValue({
            type: props.data?.type,
            login: props.data?.login,
            companiesSlugs: props.data?.companiesSlugs,
        });
    
    }, [props.data]);

    return (
        <Form
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                label="Login"
                name="login"
                rules={props.editable ? [] : [
                    {
                        required: true,
                        message: 'Podaj login!',
                    },
                ]}
            >
                <Input disabled={props.editable}/>
            </Form.Item>

            <Form.Item
                label="Hasło"
                name="pass"
                rules={props.editable ? [] : [
                    {
                        required: true,
                        message: 'Podaj hasło!',
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                label="Rodzaj użytkownika"
                name="type"
                rules={[
                    {
                        required: true,
                        message: 'Wybierz rodzaj urzytkownika',
                    },
                ]}
                initialValue={"user"}
                // value={props.data?.type}
            >
                <Radio.Group>
                    <Radio.Button value="admin">Administrator</Radio.Button>
                    <Radio.Button value="superuser">Super użytkownik</Radio.Button>
                    <Radio.Button value="user">Użytkownik</Radio.Button>
                    <Radio.Button disabled={true} value="report">
                        Konto techniczne
                    </Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="companiesSlugs"
                label="Obsługiwani klienci"
            >
                <Select mode="multiple" allowClear>
                    {props.companies?.map((item) => <Option key={item.slug} value={item.slug}>{item.name}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default function User(props: SessionProp) {
    const match = useParams() as { login: string };
    const { data } = useGetCompaniesQuery();

    if (match.login === 'new') {
        return <NewUser session={props.session} companies={data?.Companies.list}/>;
    } else {
        return <UpdateUser login={match.login} companies={data?.Companies.list}/>;
    }
}

export function UpdateUser({ login, companies }: { login: string, companies?: Pick<Company, 'name' | 'slug'>[] }) {
    const [ updateUser ] = useUpdateUserMutation();
    const { data } = useGetUserQuery({
        variables: {
            login
        }
    });

    async function submit(user: IUser) {
        const updateParams: UserUpdateParams = {
            companiesSlugs: user.companiesSlugs || [],
            type: user.type,
        };

        if (user.pass) {
            updateParams.pass = user.pass;
        }

        updateUser({
            variables: {
                login,
                update: updateParams
            }
        });
    }

    return <div>
        Update {login}
        <UserForm onSubmit={submit} companies={companies} data={data?.User} editable={true}/>
    </div>;
}

export function NewUser(props: SessionProp & { login?: string, companies?: Pick<Company, 'name' | 'slug'>[] }) {
    async function tellMe(user: IUser) {
        console.log(user);

        await api.createUser(props.session, user);
    }

    return (
        <div>
            <UserForm onSubmit={tellMe} companies={props.companies}/>
        </div>
    );
}
