import OpenBLOB from './open-blob';

export const COL_SEPARATORS = {
    COMMA: ',',
    SEMICOLON: ';'
};

function head(header: string[] | undefined, separator: string): string {
    if (header) {
        return header.join(separator) + '\n';
    } else {
        return '';
    }
}

function body(data: string[][], separator: string): string {
    return data.map(row => row.map(item => {
        return `${item}`.indexOf(separator) >= 0 ?
            `"${item.replaceAll('"', '""')}"` :
            `${item}`;
    }).join(separator)).join('\n');
}

function getCsv(header: string[] | undefined, data: string[][], separator: string) {
    return head(header, separator) + body(data, separator);
}

export default function OpenCSV(
    fileName: string,
    data: string[][],
    header?: string[],
    separator = COL_SEPARATORS.SEMICOLON
) {
    const csv = getCsv(header, data, separator);

    OpenBLOB(new Blob([csv]), 'text/csv', fileName);

    // const hiddenElement = document.createElement('a');
    // hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    // hiddenElement.target = '_blank';
    // hiddenElement.download = fileName;
    // hiddenElement.click();
}
