import {LinkStatus} from "../generated/graphql.d";
import {CheckOutlined, ExclamationOutlined, QuestionOutlined} from '@ant-design/icons';
import {green, red, orange} from "@ant-design/colors";
import React from "react";


export function LinkStatusIcon({status}: {status: LinkStatus | null}) {
    if (status === LinkStatus.Valid) {
        return <CheckOutlined style={{color: green.primary}} title={"Link poprawny"} />;
    }

    if (status === LinkStatus.Invalid) {
        return <ExclamationOutlined style={{color: red.primary}} title={"Link wymaga Twojej uwagi"} />;
    }

    const title = status === LinkStatus.Pending
        ? "Link oczekuje na sprawdzenie"
        : "Link nie został jeszcze sprawdzony";

    return <QuestionOutlined style={{color: orange.primary}} title={title} />;
}
