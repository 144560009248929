import { Authentication, MaybeSession, NoSession, SessionManager, SessionObserver } from './index';

export default class SimpleSessionManager implements SessionManager {
    private observers: Map<SessionObserver, SessionObserver> = new Map();
    private authentication: Authentication | undefined;

    constructor(private session: MaybeSession = new NoSession('session manager init')) {
    }

    async login(auth: Authentication) {
        this.authentication = auth;
        this.session = {
            user: auth.user,
            token: auth.token,
        };
        this.notify(this.session);
    }

    async logout(noSession: NoSession) {
        this.session = noSession;
        this.notify(this.session);
    }

    registerObserver(observer: SessionObserver) {
        observer(this.session);
        this.observers.set(observer, observer);
        return () => {
            this.observers.delete(observer);
        };
    }

    private notify(session: MaybeSession) {
        this.observers.forEach(observer => observer(session));
    }

}
