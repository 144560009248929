import logo_black_white from '../visibly_logo_black_white.svg';
import React, { ReactNode } from 'react';

export function Watermark() {
    return (
        <img
            src={logo_black_white}
            style={{
                zIndex: 100,
                position: 'absolute',
                bottom: '10px',
                right: '50px',
                height: '80px',
                opacity: 0.1,
            }}
            alt="logo"
        />
    );
}

export function WatermarkWrapper({children}: {children: ReactNode}) {
    return (
        <div style={{ position: 'relative' }}>
            <Watermark/>
            {children}
        </div>
    )
}
