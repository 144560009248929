import React, { useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';

export const TopDomainsPie = (props: { topLinks: any[], limit: number; }) => {
    const slices = useMemo(() => {
        const topMedia = props.topLinks || [];
        const sum = topMedia.reduce((acc, cur) => acc + (cur?.clicks || 0), 0);
        const top = topMedia
            .filter((media, index) => (media?.clicks || 0) > (sum * 0.02) && index < props.limit)
            .map(({ domain, clicks }) => {
                return {
                    id: domain,
                    label: domain,
                    value: clicks,
                };
            }) || [];

        const topSum = top.reduce((acc, cur) => acc + cur.value, 0);

        if (topSum < sum) {
            top.push({
                id: 'other',
                label: 'Pozostałe',
                value: sum - topSum,
            });
        }

        return top;
    }, [props.topLinks, props.limit]);

    return <div style={{ height: '400px' }}>
        <ResponsivePie
            data={slices}
            margin={{ top: 40, right: 120, bottom: 80, left: 120 }}
        />
    </div>;
};