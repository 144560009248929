import React, { useContext } from 'react';
import { AddSimple, OnAddProp } from '../Active/AddSimple';
import { SessionProp } from '../api';
import { IThesisTableProps, ThesisTable } from './ThesisTable';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_THESIS, GET_THESES } from '../GQL/Thesis';
import { DateRangeProp } from '../DatePanel';
import { CompanyContext } from '../context/CompanyContext';

export function Thesis(props: SessionProp & DateRangeProp) {
    const [addThesis] = useMutation(CREATE_THESIS);
    const companyContext = useContext(CompanyContext);
    const { loading, error, data, refetch } = useQuery(GET_THESES, {
        variables: {
            companySlug: companyContext.companySlug,
            from: props.dateRange[0].toISOString(),
            to: props.dateRange[1].toISOString(),
        },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>T Error :(</p>;

    const theses = {};
    // @ts-ignore
    data.Company.theses.forEach((i: any) => theses[i.slug] = i);
    // @ts-ignore
    data.Company.thesesStats.forEach((i: any) => theses[i.slug] = { ...theses[i.slug], ...i });


    return (
        <ThesisElement
            onAdd={async text => {
                await addThesis({
                    variables: { companySlug: companyContext.companySlug, text },
                });
                refetch();
            }}
            theses={Object.values(theses)}
        />
    );
}

export function ThesisElement(props: OnAddProp & IThesisTableProps) {
    return (
        <>
            <AddSimple onAdd={props.onAdd} />
            <ThesisTable theses={props.theses} />
            {/*<ThesisMetric company={}/>*/}
        </>
    );
}
