import React, { useEffect, useState } from 'react';
// import { history } from 'react-router-dom';
import { List } from 'antd';
import * as api from '../api';
import { SessionProp, User, userDelete } from '../api';
import { Link } from 'react-router-dom';

function UserListItemDesc(props: { user: User } & SessionProp) {
    function del() {
        userDelete(props.session, props.user.login);
    }

    return (
        <div>
            role: {props.user.type}
            {props.user.login !== 'admin' &&
            props.user.login !== props.session.user.login ? (
                <span
                    onClick={del}
                    style={{ color: 'red', cursor: 'pointer', float: 'right' }}
                >
                    usuń
                </span>
            ) : (
                ''
            )}
        </div>
    );
}

export default function Users(props: SessionProp) {
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        async function fetchData() {
            const newData = await api.userList(props.session);

            if (newData) {
                setUsers(newData);
            }
        }

        fetchData();
    }, [props.session]);

    return (
        <div>
            <List itemLayout="horizontal">
                <List.Item>
                    <Link to={'/user/new'}>Dodaj użytkownika</Link>
                </List.Item>

                {users.map(user => (
                    <List.Item style={{ alignItems: 'start' }}>
                        <List.Item.Meta
                            title={
                                <Link to={'/user/' + user.login}>
                                    {user.login}
                                </Link>
                            }
                            description={
                                <UserListItemDesc
                                    user={user}
                                    session={props.session}
                                />
                            }
                        />
                    </List.Item>
                ))}
            </List>
        </div>
    );
}
