import { Button, message, Modal, Space, Spin, Table, Typography } from 'antd';
import React, { useContext, useMemo } from 'react';
import { Article, useGetArticlesQuery, useRemoveArticleMutation } from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';
import local from '../helpers/time/local';
import { parseInt } from 'lodash';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { Link } = Typography;

const { confirm } = Modal;


function ArticleDeleteButton(props: { article: Pick<Article, 'id'> }) {
    const [deleteArticle] = useRemoveArticleMutation({
        refetchQueries: ['GetArticles'],
    });
    const company = useContext(CompanyContext);


    const onClick = () => {
        confirm({
            title: 'Czy na pewno chcesz usunąć artkył?',
            icon: <ExclamationCircleOutlined />,
            async onOk() {
                try {
                    const response = await deleteArticle({
                        variables: {
                            articleId: props.article.id,
                            slug: company.companySlug,
                        },
                    });

                    if (response.data?.Company?.article?.remove?.success) {
                        message.info(`Artykuł został usunięty`);
                    } else {
                        message.error('Nie udało się usunąć artykułu');
                    }
                } catch (e) {
                    console.error(e);
                    message.error('Nie udało się usunąć celu');
                }
            },
            onCancel() {
                console.log('Canceled');
            },
        });
    };

    return <Button icon={<DeleteOutlined />} onClick={onClick} title='Usuń artykuł' />;
}

export function ArticlesTable() {
    const companyContext = useContext(CompanyContext);

    const { loading, data } = useGetArticlesQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const articles = useMemo(() => {
        return data?.Company?.articles.map(item => ({
            ...item,
            keywordsCount: item.keywords.filter(item => item!.estimatedReads > 0).length,
            reads: item.keywords.reduce((acc, item) => acc + item!.estimatedReads, 0),
        }));
    }, [data?.Company?.articles]);

    return (<Spin spinning={loading}>
        <Table
            dataSource={articles}
            rowKey={'id'}
            style={{ width: '100%' }}
            columns={[
                {
                    title: 'Tytuł',
                    key: 'title',
                    dataIndex: 'title',
                    render: (title: string, article) => <Link
                        href={`/company/${companyContext.companySlug}/article/${article.id}`}>{title}</Link>,
                },
                {
                    title: 'AVE',
                    key: 'ave',
                    dataIndex: 'ave',
                },
                {
                    title: 'Content Mass',
                    key: 'linksCount',
                    dataIndex: 'linksCount',
                },
                {
                    title: 'Dotarcie',
                    key: 'reach',
                    dataIndex: 'reach',
                },
                {
                    title: 'Ruch',
                    key: 'reads',
                    dataIndex: 'reads',
                    sorter: (a, b) => {
                        return a.reads - b.reads;
                    },
                    // render: (reads, article) => <Spin spinning={stats.loading}>{stats.loading === false ? `${reads}` : ''}</Spin>
                },
                {
                    title: 'Słowa kluczowe',
                    key: 'keywordsCount',
                    dataIndex: 'keywordsCount',
                },
                {
                    title: 'Autor',
                    key: 'author',
                    dataIndex: 'author',
                    // sorter: (a, b) => a.author - b.author
                },
                {
                    title: 'Data',
                    key: 'date',
                    dataIndex: 'date',
                    render: (date: string) => (
                        <div style={{ minWidth: '75px' }}>
                            {local(date, 'yyyy-MM-dd')}
                        </div>
                    ),
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => {
                        return (new Date(parseInt(a.date))).getTime() - (new Date(parseInt(b.date))).getTime();
                    },
                },
                {
                    title: 'Akcja',
                    dataIndex: 'text',
                    key: 'text',
                    render: (_, article) => <Space>
                        <ArticleDeleteButton article={article} />
                        {/*<Button icon={<DeleteOutlined />} onClick={() => {}} title='Usuń artykuł' />*/}
                    </Space>,
                },
            ]}
        />
    </Spin>)
}