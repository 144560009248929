import { Card, Dropdown, Menu, Radio, Spin } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { DateRangeContext } from '../../context/DateRange';
import { useGetTopMediaQuery } from '../../generated/graphql.d';
import { TopDomainsPie } from './TopDomainsPie';
import { CheckOutlined, SettingOutlined } from '@ant-design/icons';
import { TopDomainsTable } from './TopDomainsTable';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CompanyContext } from '../../context/CompanyContext';
import { useFilter } from './useFilter';

export const TopDomainsCard = () => {
    const companyContext = useContext(CompanyContext);
    const dateContext = useContext(DateRangeContext);
    const filter = useFilter();

    const [mode, setMode] = useState<'TABLE' | 'PIE_CHART'>('PIE_CHART');
    const [limit, setLimit] = useState(10);

    const { loading, data } = useGetTopMediaQuery({
        variables: {
            slug: companyContext.companySlug,
            from: dateContext.dateRange.from,
            to: dateContext.dateRange.to,
            filter,
        },
    });

    const topLinks = useMemo(() => {
        const media = data?.Company?.stats.topMedia || [];

        return media.map((medium) => {
            const domain: string = medium?.domain || '';
            const clicks: number = medium?.clicks || 0;
            return {
                ...medium,
                domain: domain.replace(/^www\./, ''),
                clicks,
            };
        });
    }, [data?.Company?.stats.topMedia]);

    const handleLimit = (e: RadioChangeEvent) => {
        setLimit(e.target.value);
    };

    const menu = <Menu>
        <Menu.Item icon={mode === 'PIE_CHART' ? <CheckOutlined /> : null}
                   onClick={() => setMode('PIE_CHART')}>Wykres</Menu.Item>
        <Menu.Item icon={mode === 'TABLE' ? <CheckOutlined /> : null}
                   onClick={() => setMode('TABLE')}>Tabela</Menu.Item>
        <Radio.Group value={limit} onChange={handleLimit}>
            <Radio.Button value='3'>3</Radio.Button>
            <Radio.Button value='5'>5</Radio.Button>
            <Radio.Button value='10'>10</Radio.Button>
        </Radio.Group>
    </Menu>;

    const dropdown = <Dropdown overlay={menu} trigger={['click']}>
        <SettingOutlined />
    </Dropdown>;

    return <Card title='Top Media' extra={dropdown}>
        <Spin spinning={loading}>
            {mode === 'PIE_CHART'
                ? <TopDomainsPie topLinks={topLinks} limit={limit} />
                : <TopDomainsTable topLinks={topLinks} limit={limit} />}
        </Spin>
    </Card>;
};