import { MaybeSession, NoSession, SessionStorage } from './index';

export default class LocalSessionStorage implements SessionStorage {
    async load() {
        const session = localStorage.getItem('session');
        if (session) {
            try {
                return JSON.parse(session);
            } catch {
                console.log('could not read session', session);
                return new NoSession('could not read session');
            }
        } else {
            return new NoSession('No session available');
        }
    }

    async save(session: MaybeSession) {
        if (session instanceof NoSession) {
            localStorage.removeItem('session');
        } else {
            localStorage.setItem('session', JSON.stringify(session, null, 4));
        }
    }
}
