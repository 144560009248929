import React, { useContext } from 'react';
import { useGetArticleAveQuery } from '../../generated/graphql.d';
import { DateRangeContext } from '../../context/DateRange';
import { CompareCard } from '../../company/Cards/CompareCard';
import { useArticleBaseOptions } from './useArticleBaseOptions';
import { useArticleId } from '../useArticleId';

export const ArticleAveCard = () => {
    const context = useContext(DateRangeContext);
    const articleId = useArticleId();

    const baseOptions = useArticleBaseOptions(articleId);
    const periodOptions = useArticleBaseOptions(articleId, context.dateRange.from, context.dateRange.to);

    const stats = useGetArticleAveQuery(baseOptions);
    const compare = useGetArticleAveQuery(periodOptions);

    return (<CompareCard value={stats?.data?.Company?.article.ave || 0}
                         value2={context.compare ? compare?.data?.Company?.article.ave || 0 : null}
                         title={'AVE'}
                         loading={stats.loading || compare.loading} />);
};
