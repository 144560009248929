import { useContext, useMemo } from 'react';
import { DateRangeContext } from '../../context/DateRange';
import { CompanyContext } from '../../context/CompanyContext';
import { useFilter } from '../../company/Cards/useFilter';

export function useArticleBaseOptions(articleId: string, from?: Date, to?: Date) {
    const context = useContext(DateRangeContext);
    const company = useContext(CompanyContext);
    const filter = useFilter();

    return useMemo(() => {
        const variables = {
            slug: company.companySlug,
            articleId,
            filter,
        };

        if (from && to) {
            // @ts-ignore
            variables.from = context.dateRange.from;
            // @ts-ignore
            variables.to = context.dateRange.to;
        }

        return {
            variables
        };
    }, [company.companySlug, context.dateRange.from, context.dateRange.to, filter]);
}
