import { ResponsiveBump } from '@nivo/bump';

import React from 'react';
import { sortBy } from 'lodash';
import { format } from 'date-fns';

function printDate(stamp: string) {
    return new Date(parseInt(stamp));
}

export function range(from: number, to: number, count: number, step: number) {
    const interval = (to - from) / count;
    return Math.round(from + interval * step);
}

export function getColor(dataLength: any, c: number) {
    return `hsl(${255 - range(0, 100, dataLength, c)}, 80%,${range(
        40,
        70,
        dataLength,
        c,
    )}%)`;
}

export default function Plot(props: any) {
    const maxRank = props.maxRank || 20;
    const data = props.data.map((url: string, c: number) => ({
        id: url,
        color: getColor(props.data.length, c),
        data: sortBy(
            props.scans.map((s: any) => {
                const y = s.urls.indexOf(url) + 1;
                const date = printDate(s.date);
                return {
                    y: y === 0 ? null : y,
                    x: format(date, 'MM-dd:HH'),
                };
            }),
            'x',
        ),
    }));

    const leftToFill = maxRank - data.length;

    if (data.length) {
        for (let i = 0; i < leftToFill; i++) {
            data.push({
                id: 'empty ' + i,
                data: data[0].data.map((i: any) => ({ ...i, y: null })),
            });
        }
    }

    return (
        <ResponsiveBump
            margin={{ top: 50, right: 320, bottom: 50, left: 60 }}
            axisTop={null}
            colors={o => {
                return o.color;
            }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 52,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32,
            }}
            data={data}
        />
    );
}
