import React, { useContext } from 'react';
import { useGetAveQuery } from '../../generated/graphql.d';
import { CompareCard } from './CompareCard';
import { DateRangeContext } from '../../context/DateRange';
import { useBaseOptions, usePreviousBaseOptions } from './useBaseOptions';

export const AveCard = () => {
    const context = useContext(DateRangeContext);

    const periodOptions = usePreviousBaseOptions();
    const baseOptions = useBaseOptions();

    const stats = useGetAveQuery(baseOptions);
    const compare = useGetAveQuery(periodOptions);

    return (<CompareCard value={stats?.data?.Company?.stats.ave || 0}
                         value2={context.compare ? compare?.data?.Company?.stats.ave || 0 : null}
                         title={'Łączne AVE'}
                         loading={stats.loading || compare.loading} />);
};
