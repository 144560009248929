import { MaybeDateRange } from './DatePanel';
import graphql from './generated/graphql';

export const API =
    window.location.port === '3001'
        ? 'http://localhost:3000/'
        : 'https://api.experimental.lightscape.pro/';

export type Login = string;

export interface User {
    pass: string;
    login: Login;
    desc?: string;
    type: string;
}

export const example_user: User = {
    login: 'example',
    desc: 'Magda',
    pass: 'asdf',
    type: 'admin',
};

Object.freeze(example_user);

export type Token = string;

export interface Session {
    user: User;
    token: Token;
}

export interface SessionProp {
    session: Session;
}

export interface CompanySlugProp {
    companySlug: string;
}

export type MaybeSession = undefined | Session;

export interface Credentials {
    login: Login;
    pass: string;
}

export async function login(credentials: Credentials) {
    const url = API + 'token/login';

    const result = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export function createUser(session: Session, credentials: Credentials) {
    const url = API + 'user';

    return fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
        body: JSON.stringify(credentials),
    });
}

export interface IPhrase {
    slug: string;
    text: string;
    potential: number;
}

function stopCache() {
    return (
        '?r=' +
        Math.random()
            .toString()
            .substr(2) +
        Math.random()
            .toString()
            .substr(2)
    );
}

export async function phraseList(
    session: Session,
    companySlug?: string,
    maybeDateRange?: MaybeDateRange,
): Promise<IPhrase[]> {
    const query = maybeDateRange
        ? `&from=${maybeDateRange[0].valueOf()}&to=${maybeDateRange[1].valueOf()}`
        : '';
    const url =
        API +
        (companySlug ? `company/${companySlug}/` : '') +
        'phrase' +
        stopCache() +
        query;

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return [];
    }
}

export async function phraseAdd(
    session: Session,
    phrases: string[],
    companySlug?: string,
) {
    const url = API + (companySlug ? `company/${companySlug}/` : '') + 'phrase';

    const result = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
        body: JSON.stringify(phrases),
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export interface PhraseUpdateRequest {
    slug: string;
    potential?: number;
}

export async function phraseUpdate(
    session: Session,
    companySlug: string,
    phrase: PhraseUpdateRequest,
) {
    const url = API + (companySlug ? `company/${companySlug}/` : '') + 'phrase';

    const result = await fetch(url, {
        method: 'PUT', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
        body: JSON.stringify(phrase),
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export interface LinkUpdateRequest {
    active?: boolean;
    link: string;
    editable?: string;
}

export interface LinkSyncRequest {
    link: string;
}

export async function linkUpdate(
    session: Session,
    companySlug: string,
    linkUpdateRequest: LinkUpdateRequest,
) {
    const url = API + `company/${companySlug}/link`;

    const result = await fetch(url, {
        method: 'PUT', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
        body: JSON.stringify(linkUpdateRequest),
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export async function linkSync(
    session: Session,
    companySlug: string,
    link: string,
) {
    const url = API + `company/${companySlug}/link/sync`;
    const body: LinkSyncRequest = {
        link,
    };

    const result = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
        body: JSON.stringify(body),
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export async function phraseScans(
    session: Session,
    slug: string,
    maybeDateRange: MaybeDateRange,
) {
    const query = maybeDateRange
        ? `&from=${maybeDateRange[0].valueOf()}&to=${maybeDateRange[1].valueOf()}`
        : '';
    const url = API + `phrase/${slug}/scan${stopCache()}${query}`;

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return;
    }
}

export async function phrase(session: Session, slug: string) {
    const url = API + 'phrase/' + slug + stopCache();

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return;
    }
}

export async function scan(session: Session, slug: string) {
    const url = API + 'phrase/' + slug + '/scan';

    const result = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return result;
    } else {
        return;
    }
}

export async function immImports(session: Session) {
    const url = API + 'partner/imm/import/ftp' + stopCache();

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return result.json();
    } else {
        return;
    }
}

export async function immImport(session: Session, folder: string) {
    const url = API + 'partner/imm/import/ftp/' + folder + stopCache();

    const result = await fetch(url, {
        method: 'PUT', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return;
    } else {
        return;
    }
}

export class LoggedOut {
}

export async function companies(session: Session) {
    const url = API + 'company' + stopCache();

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return result.json();
    } else if (result.status === 401) {
        throw new LoggedOut();
    } else {
        return;
    }
}

export interface Link extends graphql.Link {

}

export interface CompanyProp {
    company: graphql.Company;
}

export interface NewsProp {
    news: graphql.News[];
}

export async function company(session: Session, companySlug: string) {
    const url = API + 'company/' + companySlug + stopCache();

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return result.json();
    } else {
        return;
    }
}

type TJobStatus = 'created' | 'started' | 'done';
type TJobType = 'scan' | 'immFtpFetch';

export interface IJob {
    out?: string;
    jobType: TJobType;
    createdPlusRandom: string; // id
    created: string;
    jobStatus: TJobStatus;
    extra?: string;
    started?: string;
    finished?: string;
}

export async function jobList(session: Session): Promise<IJob[]> {
    const url = API + 'job' + stopCache();

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return [];
    }
}

export interface INewJob {
    jobType: TJobType;
    extra?: string;
}

export async function newJob(session: Session, job: INewJob) {
    const url = API + 'job' + stopCache();

    const result = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
        body: JSON.stringify(job),
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export async function createScanJob(session: Session) {
    const url = API + 'job/createScanJob' + stopCache();

    const result = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
        body: '',
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export async function userList(session: Session): Promise<User[]> {
    const url = API + 'user' + stopCache();

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return [];
    }
}

export async function user(session: Session, login: string) {
    const url = API + 'user/' + login;

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export async function userDelete(session: Session, login: string) {
    const url = API + 'user/' + login;

    const result = await fetch(url, {
        method: 'DELETE', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export async function potentials(session: Session, phraseSlug: string) {
    const url = API + 'phrase/' + phraseSlug + '/potential';

    const result = await fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}

export async function potentialDelete(
    session: Session,
    phraseSlug: string,
    potentialDate: string,
) {
    const url = API + 'phrase/' + phraseSlug + '/potential/' + potentialDate;

    const result = await fetch(url, {
        method: 'DELETE', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session.token,
        },
    });

    if (result.status === 200) {
        return await result.json();
    } else {
        return false;
    }
}
