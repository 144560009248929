import React, { useContext, useState } from 'react';
import { Button, Modal, Select, Space, Table } from 'antd';
import { IPhrase } from '../api';
import { PhrasePotential } from './PhrasePotential';
import { RefreshProp } from '../RefreshProp';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import lodash, { reverse, sortBy } from 'lodash';
import { MaybeDateRangeProp } from '../DatePanel';
import { getColumnSearchProps } from '../textFilterInTable';
import { IThesisTableProps } from '../thesis/ThesisTable';
import { gql, useMutation } from '@apollo/client';
import { dateToLocal } from '../helpers/time/dateToLocal';
import { DeleteOutlined, DeleteRowOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import {
    useAddPhraseToListMutation,
    useGetPhraseListQuery,
    useRemovePhraseFromListMutation,
    useRemovePhraseMutation,
    useUnassignPhraseMutation,
} from '../generated/graphql.d';
import { CompanyContext } from '../context/CompanyContext';
import { TabListContext } from '../context/ListContext';

const { confirm } = Modal;

export interface PhraseStats {
    positions: number[];
    ctr: number;
    estimatedReads: number;
}

export type Phrase = IPhrase & {
    theses: string[];
    potential: number;
    stats: PhraseStats;
    ranks: string;
    potentials: { value: number; date: string }[];
};

const MUTATE_PHRASE = gql`
    mutation UpdatePhrase(
        $companySlug: String!
        $slug: String!
        $params: PhraseUpdateParams!
    ) {
        Phrase(companySlug: $companySlug, slug: $slug) {
            update(params: $params) {
                success
            }
        }
    }
`;

export interface PhrasesTableProps extends RefreshProp, IThesisTableProps, MaybeDateRangeProp {
    phrases: Phrase[];
}

function PhraseDeleteButton(props: { phrase: IPhrase }) {
    const companyContext = useContext(CompanyContext);

    const [removePhrase] = useRemovePhraseMutation({
        refetchQueries: ['GetPhrasesAndThesis'],
    });

    const onClick = () => {
        confirm({
            title: 'Are you sure you want to delete this phrase?',
            content: 'This action cannot be undone.',
            onOk: () => {
                removePhrase({
                    variables: {
                        slug: companyContext.companySlug,
                        phrase: props.phrase.slug,
                    },
                });
            },
        });
    };

    return <Button icon={<DeleteOutlined />} onClick={onClick} title='Usuń frazę' />;
}

function PhraseUnassignButton(props: { phrase: IPhrase }) {
    const companyContext = useContext(CompanyContext);
    const listContext = useContext(TabListContext);

    const [unassignPhrase] = useUnassignPhraseMutation({
        refetchQueries: ['GetPhrasesAndThesis'],
    });

    const onClick = () => {
        confirm({
            title: 'Are you sure you want to remove phrase from the list?',
            onOk: () => {
                unassignPhrase({
                    variables: {
                        slug: companyContext.companySlug,
                        listSlug: listContext.activeList,
                        phraseSlug: props.phrase.slug,
                    },
                });
            },
        });
    };

    return <Button icon={<DeleteRowOutlined />} onClick={onClick} title='Usuń frazę z listy' />;
}

export default function PhrasesTable(props: PhrasesTableProps) {
    const listContext = useContext(TabListContext);
    const companyContext = useContext(CompanyContext);
    const [updatePhrase] = useMutation(MUTATE_PHRASE);
    const [addPhraseToList] = useAddPhraseToListMutation();
    const [removePhraseFromList] = useRemovePhraseFromListMutation();
    const { data, refetch } = useGetPhraseListQuery({
        variables: {
            slug: companyContext.companySlug,
        },
    });

    const favourite = data?.Company?.favourite || [];

    const thesesSum: any = {};
    const phrases = props.phrases.map(phrase => {
        phrase.theses.forEach(thesis => {
            if (thesis in thesesSum) {
                thesesSum[thesis]++;
            } else {
                thesesSum[thesis] = 1;
            }
        });

        return { ...phrase, theses: sortBy(lodash.uniq(phrase.theses)) };
    });

    const toggleFav = async (event: React.MouseEvent, record: Phrase) => {
        event.stopPropagation();
        const indexOf = favourite.findIndex(i => i.slug === record.slug);
        const variable = {
            variables: {
                slug: companyContext.companySlug,
                phrase: record.slug,
                list: 'favourite',
            },
        };

        if (indexOf === -1) {
            await addPhraseToList(variable);
            refetch();
        } else {
            await removePhraseFromList(variable);
            refetch();
        }
    };

    const isFav = (record: Phrase): boolean => {
        return favourite ? favourite.findIndex(item => item.slug === record.slug) >= 0 : false;
    };

    const columns: ColumnProps<Phrase>[] = [
        {
            // title: 'Ulubione',
            key: 'fav',
            render: (_, record) => {
                return <Button onClick={(event) => toggleFav(event, record)} type='text'
                               icon={isFav(record) ? <StarFilled /> : <StarOutlined />} />;
            },
            sorter: (a: Phrase, b: Phrase) =>
                (isFav(a) ? 0 : 1) - (isFav(b) ? 0 : 1),
            filters: [
                {
                    text: 'Ulubione',
                    value: '1',
                },
                {
                    text: 'Pozostałe',
                    value: '0',
                },
            ],
            onFilter: (value, phrase) =>
                value === '1' && isFav(phrase) || value === '0' && !isFav(phrase),
        },
        {
            title: 'Fraza',
            dataIndex: 'text',
            key: 'text',
            render: (ignore: any, phrase: Phrase) => (
                <Link
                    onClick={e => e.stopPropagation()}
                    to={
                        '/company/' +
                        companyContext.companySlug +
                        '/phrase/' +
                        phrase.slug
                    }
                >
                    {phrase.text}
                </Link>
            ),
            sorter: (a, b) => ('' + a.text).localeCompare(b.text),
            ...getColumnSearchProps('text', ...useState()),
        },
        {
            title: 'Cel',
            dataIndex: 'theses',
            key: 'theses',
            render: (theses, phrase) => (
                <div onClick={event => event.stopPropagation()}>
                    <Select
                        mode='multiple'
                        style={{ width: '100%' }}
                        placeholder='Wybierz cel'
                        defaultValue={theses}
                        onChange={async values => {
                            await updatePhrase({
                                variables: {
                                    companySlug: companyContext.companySlug,
                                    slug: phrase.slug,
                                    params: {
                                        theses: values,
                                    },
                                },
                            });
                            props.refresh();
                        }}
                    >
                        {props.theses.map(
                            (i: { text: string; slug: string }) => (
                                <Select.Option key={i.text} value={i.slug}>
                                    {i.text}
                                </Select.Option>
                            ),
                        )}
                    </Select>
                </div>
            ),

            filters: Object.entries(thesesSum).map(([k, v]) => ({
                text: `${k} (${v})`,
                value: k,
            })),
            onFilter: (value, phrase) =>
                phrase.theses.indexOf(value.toString()) > -1,
        },
        {
            title: 'Potencjał',
            dataIndex: 'potential',
            key: 'potential',
            render: (potential: number, phrase: Phrase) => (
                <PhrasePotential
                    phrase={phrase}
                    refresh={props.refresh}
                />
            ),
            sorter: (a: Phrase, b: Phrase) =>
                (a.potential || 0) - (b.potential || 0),
        },
        {
            title: 'Pozycje',
            dataIndex: 'ranks',
            key: 'ranks',
            sorter: (a: Phrase, b: Phrase) =>
                ((a.ranks && a.ranks.length) || 0) -
                ((b.ranks && b.ranks.length) || 0),
        },
        {
            title: 'CTR',
            dataIndex: ['stats', 'ctr'],
            key: 'stats.ctr',
            defaultSortOrder: 'descend',
            render: (current: number) =>
                current ? `${(current * 100).toFixed(2)}%` : '-',
            sorter: (a: Phrase, b: Phrase) =>
                (a.stats.ctr || 0) - (b.stats.ctr || 0),
        },
        {
            title: 'Zasięg',
            dataIndex: ['stats', 'estimatedReads'],
            key: 'stats.estimatedReads',
            sorter: (a: Phrase, b: Phrase) =>
                (a.stats.estimatedReads || 0) - (b.stats.estimatedReads || 0),
            render: i => (i ? Math.round(i) : '-'),
        },
        {
            title: 'Akcja',
            dataIndex: 'text',
            key: 'text',
            render: (_, phrase) => <Space>
                <PhraseDeleteButton phrase={phrase} />
                {listContext.activeList !== 'all' && <PhraseUnassignButton phrase={phrase} />}
            </Space>,
        },
    ];
    const dataSource = reverse(sortBy(phrases, 'potential'));

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            rowKey={'slug'}
            expandRowByClick={true}
            expandedRowRender={phrase =>
                lodash
                    .reverse(lodash.sortBy(phrase.potentials, 'date'))
                    .map(i => (
                        <div>
                            {dateToLocal(i.date)}: {i.value}
                        </div>
                    ))
            }
            pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '25', '50', '100'],
            }}
            rowSelection={{
                selectedRowKeys: listContext.selectedRowKeys,
                onChange: (selectedRowKeys: (string | number)[], selectedRows) => {
                    listContext.setSelectedRowKeys(selectedRowKeys as string[]);
                },
            }}
        />
    );
}
